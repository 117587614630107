import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buckswood-school',
  templateUrl: './blog-buckswood-school.component.html',
  styleUrls: ['../../blog.component.scss']
})
export class BlogBuckswoodSchool implements OnInit {
  constructor() {}

  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
