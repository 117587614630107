import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { extract } from '@app/core';
import { BlogLeasingComponent } from '@app/blog/blog-item/leasing/blog-leasing.component';
import { BlogPageComponent } from '@app/blog/blog-page/blog-page.component';
import { BlogSolarComponent } from '@app/blog/blog-item/solar/blog-solar.component';
import { BlogBforumComponent } from '@app/blog/blog-item/bforum/blog-bforum.component';
import { BlogUsedComponent } from '@app/blog/blog-item/used/blog-used.component';
import { BlogScootersComponent } from '@app/blog/blog-item/scooters/blog-scooters.component';
import { BlogToyotaEasyComponent } from '@app/blog/blog-item/toyota-easy/blog-toyota-easy.component';
import { BlogEstateLeasingComponent } from '@app/blog/blog-item/estate-leasing/blog-estate-leasing.component';
import { BlogForInfrastructure } from './blog-item/for-infrastructure/blog-for-infrastructure.component';
import { BlogVeli } from './blog-item/veli/blog-veli.component';
import { BlogAuction } from './blog-item/auction/blog-auction.component';
import { BlogUserExperience } from './blog-item/user-experience/blog-user-experience.component';
import { BlogTravelTips } from './blog-item/travel-tips/blog-travel-tips.component';
import { BlogRecycling } from './blog-item/recycling/blog-recycling.component';
import { BlogLexusEasy } from './blog-item/lexus-easy/blog-lexus-easy.component';
import { BlogProBonoComponent } from './blog-item/pro-bono/blog-pro-bono.component';
import { BlogWorkspace } from './blog-item/workspace/blog-workspace.component';
import { BlogNuts } from './blog-item/nuts/blog-nuts.component';
import { BlogDafna } from './blog-item/dafna/blog-dafna.component';
import { BlogGreenLeasing } from './blog-item/green-leasing/blog-green-leasing.component';
import { BlogCoffeeLab } from './blog-item/coffee-lab/blog-coffee-lab.component';
import { BlogBuckswoodSchool } from './blog-item/buckswood-school/blog-buckswood-school.component';
import { BlogSwapCar } from './blog-item/swap-car/blog-swap-car.component';
import { BlogSwapQuestion } from './blog-item/swap-question/blog-swap-question.component';

const routes: Routes = [
  { path: 'blog', component: BlogPageComponent, data: { title: extract('ბლოგი') } },
  { path: 'blog-solar', component: BlogSolarComponent, data: { title: extract('მზის პანელები') } },
  { path: 'blog-used', component: BlogUsedComponent, data: { title: extract('ხელმისაწვდომი ხარისხი') } },
  { path: 'blog-bforum', component: BlogBforumComponent, data: { title: extract('Forum') } },
  { path: 'blog-leasing', component: BlogLeasingComponent, data: { title: extract('ლიზინგი') } },
  {
    path: 'blog-scooters',
    component: BlogScootersComponent,
    data: { title: extract('სკუტერები') }
  },
  {
    path: 'blog-toyota-easy',
    component: BlogToyotaEasyComponent,
    data: { title: extract('ტოიოტა') }
  },
  {
    path: 'blog-estate-leasing',
    component: BlogEstateLeasingComponent,
    data: { title: extract('უძრავი ქონების ლიზინგი') }
  },
  {
    path: 'blog-for-infrastructure',
    component: BlogForInfrastructure,
    data: { title: extract('ინფრასტრუქტურისთვის') }
  },
  {
    path: 'blog-veli',
    component: BlogVeli,
    data: { title: extract('VELI') }
  },
  {
    path: 'blog-auctions',
    component: BlogAuction,
    data: { title: extract('აუქციონი') }
  },
  {
    path: 'blog-user-experience',
    component: BlogUserExperience,
    data: { title: extract('სამომხმარებლო გამოცდილება') }
  },
  {
    path: 'blog-travel-tips',
    component: BlogTravelTips,
    data: { title: extract('როგორ იმოგზაურა ეკამ 30-ზე მეტ ქვეყანაში ბიუჯეტურად?') }
  },
  {
    path: 'blog-recycling',
    component: BlogRecycling,
    data: { title: extract('„დღეს ტენე პლასტმასა?“') }
  },
  {
    path: 'blog-lexus-easy',
    component: BlogLexusEasy,
    data: { title: extract('ლექსუს იზი') }
  },
  {
    path: 'blog-pro-bono',
    component: BlogProBonoComponent,
    data: { title: extract('პრო ბონო') }
  },
  {
    path: 'blog-workspace',
    component: BlogWorkspace,
    data: { title: extract('სამუშაო გარემო') }
  },
  {
    path: 'blog-nuts',
    component: BlogNuts,
    data: { title: extract('ქართული თხილის ევროპული პერსპექტივები') }
  },
  {
    path: 'blog-dafna',
    component: BlogDafna,
    data: { title: extract('დაფნა') }
  },
  {
    path: 'blog-green-leasing',
    component: BlogGreenLeasing,
    data: { title: extract('თიბისი ლიზინგის CSR მიმართულება') }
  },
  {
    path: 'blog-coffee-lab-shop',
    component: BlogCoffeeLab,
    data: { title: extract('Coffee Lab') }
  },
  {
    path: 'blog-buckswood',
    component: BlogBuckswoodSchool,
    data: { title: extract('ბაქსვუდის სკოლა') }
  },
  {
    path: 'blog-swap-car',
    component: BlogSwapCar,
    data: { title: extract('ჩაანაცვლე ძველი ავტომობილი') }
  },
  {
    path: 'blog-swap-question',
    component: BlogSwapQuestion,
    data: { title: extract('ჩაანაცვლე ავტომობილი მარტივად') }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class BlogRoutingModule {}
