import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@app/shared/api.service';

@Component({
  selector: 'app-sso-verify-handle',
  templateUrl: './sso-verify-handle.component.html',
  styleUrls: ['./sso-verify-handle.component.scss']
})
export class SsoVerifyHandleComponent implements OnInit {
  status: 'pending' | 'success' | 'error' = 'pending'; // Default status

  constructor(private activatedRoute: ActivatedRoute, private apiService: ApiService) {}

  ngOnInit(): void {
    // Access the 'code' query parameter from the URL
    this.activatedRoute.queryParams.subscribe(params => {
      const code = params['code']; // Extract code parameter
      if (code) {
        this.handleVerificationCode(code);
      } else {
        this.status = 'error'; // Update status to error
      }
    });
  }

  private handleVerificationCode(code: string): void {
    this.apiService.handleCode(code).subscribe({
      next: response => {
        this.status = 'success'; // Update status to success
        // console.log('Verification successful:', response);
        // Handle successful verification - like navigation or user messaging
      },
      error: error => {
        this.status = 'error'; // Update status to error
        // console.error('Error during verification:', error);
        // Handle error - display message to user if needed
      }
    });
  }
}
