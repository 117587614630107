<div class="project-header-container">
  <div class="project-header mx-auto"><h1>„მეღვინეობა ხარება“</h1></div>
</div>

<div class="project mx-auto">
  <h1>„მეღვინეობა ხარება“</h1>
  <p>
    „Do you know that Georgia is the world’s first wine-producer country?  Look, this is our traditional “kvevri”.
    ქვევრის შემდეგ გიდს ფრანგი წყვილი 100 წლის საწნახელთან მიჰყავს. გოგო და ბიჭი მონდომებით ცდილობენ სიტყვა გაიმეორონ,
    მაგრამ მარტო სიცილი გამოსდით.
  </p>
  <p>
    აქ დღეში ასობით ადგილობრივი და უცხოელი დამთვალიერებლი მოდის. ყვარელში „მეღვინეობა ხარება“ რომ მიგასწავლონ,
    საკმარისია ნებისმიერ გამვლელს ჰკითხოთ - სადაა გვირაბი. ტურისტულ კომპლექსად ქცეულ უზარმაზარ, გამწვანებულ ტერიტორიაზე
    ერთ ჩაბნელებულ შესასვლელს კავკასიონის ქედის ქვეშ შეჰყავხარ, სადაც კლდის მასივში გამოჭრილი თითქმის 8 კილომეტრის
    სიგრძის გვირაბი გამორჩეულად გემრიელი ქართული ღვინის ისტორიას ინახავს.   ერთი შეხედვით იდუმალ გვირაბს სიღრმისკენ
    ეპარება სინათლე და ყვითელი, რაღაცნაირად ჯადოსნური შუქი ნელ-ნელა ხდის ფარდას ამ გვირაბივით და ღვინოებივით დაძველებულ
    ამბებს, გვირაბის ჩაყოლებაზე, საკოლექციო ღვინის მიჯრით ჩაწყობილ  26 000 ბოთლს, რომლებიც ამ თაროებზე თითქმის კიდევ
    ერთი საუკუნე იდება დასაძველებლად, პრემიუმ კლასის ღვინით სავსე ფრანგული მუხის კასრებს, ღვინის დასამზადებელ და შესანახ
    უნიკალურ ატრიბუტებს, სადეგუსტაციოდ გაწყობილ ხის მაგიდებს...
  </p>
  <p>
    გვირაბის ნესტიან კედლებში მოქცეული ღვინის ისტორიის თვალიერებისას და მოსმენისას, ამბებს 1969 წლისკენ მიჰყავხარ,
    მაშინ, როდესაც კომპანია „მეტრომშენმა“ ამიერკავკასიის რეგიონში ყველაზე გრძელი გვირაბის მშენებლობა დაიწყო. დროში
    მოგზაურობისას ვეღარც გრძნობ, რომ გარშემო სულ რაღაც 12-14 გრადუსია, ტენიანობა კი 70%. თიხოვანი ფიქალის ფენებით
    გარშემორტყმული გრანიტი ამ ტემპერატურას წელიწადის ნებისმიერ დროს ინარჩუნებს, რაც იდეალურ, ბუნებირვ პირობებს ქმნის
    ღვინის შენახვისა და დავარგებისათვის.
  </p>
  <p>
    ამ გვირაბში ღვინო მხოლოდ ინახება. ჯამში 1500 ჰექტრის ფართობის ვენახებში დაკრეფილი და შერჩეული საუკეთესო მტევნებიდან
    „მეღვინეობა ხარებას“ 36 სახის ღვინო 2 მარანში - დასავლეთ საქართველოში - თერჯოლაში და კახეთში - სოფელ ვაჩნაძიანში
    8000 წლიანი ტრადიციული მეღვინეობისა და დარგის ინოვაციური ტექნოლოგიების ერთმანეთთან შერწყმით  მზადდება.  „მეღვინეობა
    ხარებას“ ევროპული ტექნოლოგიით დამზადებული მსუბუქი, ნაზი ღვინოები ყვარელში ჩამოსული ტურისტებისათვის მაინც თითქოს
    ნაცნობი და მშობლიურია. თუმცა, უნიკალური, ქართული ტარდიციით დამზადებული ქვევრის ღვინო და კლერტისგან მიღებული ღვინის
    კეთილსურნელება და მდგრადობა - ეს ისაა, რაც სტუმრებს ყველაზე მეტად აოცებთ და ღვინის მშობლიური მიწებიდან ჩამოსულ,
    ყველაზე თავშეკავებულ ტურისტებსაც კი პირველივე ჭიქაზე ყველაზე მთავარს ათქმევინებს - ღირსეული ღვინოა.   ერთდროულად -
    გვირაბიდან და წარსულიდან გამოსული ტურისტების ნაწილი 58 მეტრის სიმაღლეზე, კლდეზე გაშენებულ რესტორანში აგრძელებს იმ
    რეალობით ტკბობას, რომელსაც ხელის გულზე გადაშლილი კახეთის ხედები და ქართული სამზარეულო სთავაზობს. ზოგს კი ურჩევნია
    თვითონ შექმნას ის, რაც ასე გემრიელად და ეგზოტიკურად გამოიყურება. სანამ თოკზე გაბმული, თავის ხელით ამოვლებული,
    უცნაური და სასაცილო ფორმის ჩურჩხელების მზეზე გაშრობას ელოდებიან, საგულდაგულოდ გაცხელებულ თონეში ტურისტები კახელი
    დალი დეიდასგან შოთის პურის ჩაკვრას სწავლობენ.
  </p>
  <p>
    აქ საითაც არ უნდა გაიხედო, მუშაობა არ წყდება. გვირაბის წინ გადაშლილი ვენახებიდან ტრაქტორები წითელ, პატარა სათამაშო
    მანქანებად მოჩანს. ეს და სხვა მრავალი მანქანა-დანადგარი კომპანიას თიბისი ლიზნგის მეშვეობით აქვს შეძენილი. ტექნიკისა
    და აღჭურვილობის გადაიარაღებისა თუ განახლების პროცესს „მეღვინეობა ხარება“ ყოველთვის თიბისი ლიზინგთან ერთად გეგმავს.
    ღვინის კომპანიის თიბისი ბანკთან თანამშრომლობის ისტორია იმდენივე წელს ითვლის, რამდენი წლის წინაც ის ქართული ღვინის
    ბაზარზე გამოჩნდა.   „მეღვინეობა ხარება“ წელიწადში 6 მილიონ ლიტრზე მეტ ღვინოს აწარმოებს. „მეღვინეობა ხარება“ იყო
    ღვინის ერთ-ერთი პირველი კომპანია, რომელმაც ჯერ კიდევ ათწლეულების წინ ადგილობრივი გლეხებისგან გადაჯიშების პირას მყოფი
    ვაზის ჯიშების შესყიდვა და გამრავლება დაიწყო. ამ ყველაფერმა კი საბოლოოდ რეკორდული რაოდენობის - 200-ზე მეტი ჯილდო
    მოიტანა. 2012 წელს „მეღვინეობა ხარება“ ღვი­ნის ერთ-ერთ ყველაზე პრესტიჟულ კონ­კურ­სზე - Mondial de Bruxelles ­ოქ­როს
    მე­დლის, 2015 წელს კი Decanter Asia Wine Awards-ზე ოქროს თასის მფლობელი გახდა.
  </p>
  <div class="author"><h4>ავტორი: მარიამ ყანჩაველი</h4></div>
</div>
