<div class="project-header-container">
  <div class="project-header mx-auto"><h1>„დეგუსტო“ - გემრიელი, სწრაფი და დემოკრატიული</h1></div>
</div>

<div class="project mx-auto">
  <h1>„დეგუსტო“ - გემრიელი, სწრაფი და დემოკრატიული</h1>
  <p>
    დახლის უკან, კედლიდან ფიროსმანის ფერადი პერსონაჟები იყურებიან. მეეზოვეს, ბუშტიან გოგონას, აქტრისა მარგარიტას,
    ორთაჭალის ტურფას სახალისო გამონათქვამები, ანდაზები და ლექსებიდან სტროფები თავზე ისე აწერიათ, თითქოს მათი ფიქრები და
    ოცნებებია.
  </p>
  <p>
    „მარტო კაცი ჭამაშიც კი ცოდოაო...“ „არამკითხე მოამბეო, მიტყიპეს და მიაგდესო...“ „ლამაზი ცოლის პატრონსა უნდა ჰყავდეს
    ძაღლი ფრთხილი, ან თვითონ უნდა ფრთხილობდეს ან იმისი დედამთილი...“ სანამ ამ ფრაზებს კითხულობ და უნებურად გეღიმება,
    ამასობაში შენი რიგიც მოდის და თეფშზე უკვე მზად არის ყველაზე გემრიელი სადილი.
  </p>
  <p>
    სწრაფი კვების ტიპის ობიექტის - „დეგუსტოს“ დამფუძნებლებს - გიგი მუშკუდიანსა და სანდრო ნორაკიძეს არ ახსოვთ ზუსტად
    რატომ, მაგრამ მეგობრებთან ერთად გადაწყვიტეს - მომხმარებლებს ყველა ფილიალის კედელზე ქალაქში ყველაზე უცნაური კატა
    დახვედროდათ. კატას ჩოხა და ტყავის წაღები მოარგეს, ზუსტად ისეთი, როგორიც მეცხრამეტე საუკუნის ერთ-ერთ ცნობილ
    პოლკოვნიკს ეცვა. ფასადებზე თვალშისაცემი, ყვითელი ლიმნის ხეები და კატა, სახელად უჩა, „დეგუსტოს“ განუყოფელ სიმბოლოებად
    იქცა.
  </p>
  <p>
    „კვების სექტორის ანალიზი გავაკეთეთ. ბაზარზე ე.წ „ლანჩის გეპი“ დავინახეთ და ამ ნიშის ათვისება გადავწყვიტეთ.
    თავდაპირველად ცენტრალიზებული საწარმო დავაფუძნეთ და აქედან ავაწყეთ ჩვენი ბიზნესის ხერხემალი. ჩვენი პროდუქტის 60% ამ
    საწარმოში მზადდება, შემდეგ მზა პროდუქტის, ნახევარფაბრიკატისა და ნედლეულის სახით ჩვენს ფილიალებში იგზავნება, საბოლოოდ
    მუშავდება და მომხმარებლამდე მიდის. დასაწყისშივე თიბისი ბანკი დაგვიდგა გვერდში. თიბისი ლიზინგის დაფინანსებით ეს
    ცენტრალიზებული საწარმო ავაწყეთ, აღჭურვილობა შევიძინეთ და პირველი ობიექტი გავხსენით.“
  </p>
  <p>
    2016 წლის დასაწყისში პირველი „დეგუსტო“ ონიაშვილის ქუჩაზე გამოჩნდა, დღეს კი ქალაქში ასეთი 10 ფილიალია, რომელიც 300
    თანამშრომელს აერთიანებს.
  </p>
  <p>
    სახელზე დიდხანს ფიქრობდნენ მეგობრებთან და სპეციალისტებთან ერთად. ვერსია ბევრი იყო, ზოგიც ნახევრად ხუმრობით -
    ჯონჯოლინი, ტაბაკა... საბოლოოდ აირჩიეს სახელწოდება, რომელიც ადვილად დასამახსოვრებელი იქნებოდა და რომელიც
    შინაარსობრივადაც ბაზარზე რაღაც სიახლის გამოჩენას ნიშნავდა, რომლისთვისაც მომხმარებელს გემო უნდა გაესინჯა. მოგვიანებით
    კვლევამ აჩვენა, რომ კლიენტებისათვის „დეგუსტო“ ასოციაციურად იტალიელი კაცია.
  </p>
  <p>
    „“დეგუსტოს“ კონცეფციის შექმნისას ჯერ მხოლოდ ხელით, შავად ნაწერი, სულ ცოტა 150 ფურცელი გვქონდა - მენიუ, დიზაინი,
    მაგიდების განლაგებაც კი. პროფესიონალების გუნდთან ერთად დამფუძნებლები ყველა მიმართულებით ვიყავით ჩართულები. რა
    გამოვიდა საბოლოოდ? - ეს არის ჯანმრთელი და გამორჩეულად გემრიელი საკვები დემოკრატიულ გარემოში. რატომ დემოკრატიული? -
    ჩვენთან ჰალსტუხიანი და კედებიანი მომხმარებლები ერთმანეთის გვერდით სხედან. რატომ გამორჩეულად გემრიელი? - ახალი ცეზარი
    გვაქვს. ჩვენს თეფშზე რომ გასინჯოთ, თქვენი აღქმა იქნება, რომ ეს არის ძალიან კარგი ცეზარი. ჩვენი თეფშები 3 ლარი ღირს,
    ჩანგალი კი - ლარ ნახევარი. იგივე ცეზარი 20 ლარიან თეფშზე ძვირფასი ჩანგლით და ძვირადღირებული რესტორნის ინტერიერში რომ
    მიირთვათ, იტყვით, რომ ეს არის საუკეთესო ცეზარი, რაც კი ოდესმე გაგისინჯავთ.“
  </p>
  <p>
    სანდროს და გიგის თუ დავუჯერებთ, ყველაზე ბანალურ ქართულ კერძს - ხაჭაპურსაც კი სულ სხვა გემო აქვს „დეგუსტოში“. ამიტომ
    სახალისო ფრაზების კედელზე ტურისტებისთვის ასეთი წარწერაცაა: “Khachapuri is the most famous of all Georgian dishes. No
    “supra” is complete without it.”
  </p>
  <p>
    „რესტორნისა და ჩვენი ბიზნესი სრულიად განსხვავებული სპეციფიკისაა. რესტორანში შენ გჭირდება საუკეთესო შეფ-მზარეული და
    ერთი იდეა-კონცეფცია, რომელსაც ხარისხიანად შეასრულებ. "დეგუსტო“ კი საოპერაციო ბიზნესია და ამ ბიზნესში წარმატებას
    სტაბილური ხარისხი, სტაბილური სანიტარია და ოპერაციების მართვის სტაბილური ხარისხი განსაზღვრავს. კარგი ბურგერის გემოს
    დასმა სულაც არ არის განსაკუთრებულად რთული. რთული ასობით ასეთი ბურგერის დამზადებაა - ყოველ დღე და უცვლელი ხარისხით.
    სწორედ ეს ხვდება მომხმარებელს „დეგუსტოში“ - სტაბილური გემო, ხარისხი და ფასი.“
  </p>
  <div class="author"><h4>ავტორი: მარიამ ყანჩაველი</h4></div>
</div>
