import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-item-preview',
  templateUrl: './blog-item-preview.component.html',
  styleUrls: ['../blog.component.scss']
})
export class BlogItemPreviewComponent implements OnInit {
  @Input()
  title: string;
  @Input()
  previewText: string;

  @Input()
  postDate: string;
  @Input()
  previewImage: string;
  @Input()
  link: string;

  constructor() {}

  ngOnInit(): void {}
}
