<div class="container-fluid m-0 p-0">
  <div class="blog-header-container">
    <h1>ბლოგი</h1>
  </div>
</div>

<div class="container blog-item custom">
  <h1>ფოტოდან სურნელს იგრძნობ ანუ გაიცანი კაფე „დაფნა“</h1>
  <h6>დეკემბერი 2023</h6>
  <br />

  <img src="../../../../assets/blog/dafna/img1.jpg" alt="" />

  <p>
    ცნობილი გამონათქვამის მიხედვით, ქართველების მეორე სისხლი - ღვინოა, მესამე - ტყემალი. ჩვენი კვებითი თავისებურებები,
    ჩვევები, ტრადიციები და თვითონ კერძებიც კი, იმდენად ბუნებრივად არის შერწყმული ქვეყნის იდენტობასთან, რომ სტუმრის
    მასპინძლობა თუ ეროვნული სამზარეულო ხელოვნების დონეზე გვაქვს აყვანილი. ამ შემთხვევაში, მოდერნისტული გადაწყვეტილებები
    დიდ სითამამეს მოითხოვს, სწორედ ამიტომ, გიყვებით კაფე „დაფნაზე“ რომელიც არტიზანულ ხინკალს სთავაზობს ვიზიტორებს.
  </p>

  <p>
    უმაღლესი რეიტინგი OTA ტურისტულ გზამკვლევებში ხმამაღლა ამბობს სათქმელს. ეს ნამდვილად არის ის გამორჩეული სივრცე ძველ
    თბილისში, რომლის ხარისხზეც ადგილობრივი თუ უცხოელი სტუმრები თანხმდებიან.
  </p>
</div>

<div class="container-fluid m-0 p-0">
  <div class="full-color background-light-blue p-0">
    <div class="container p-0">
      <img class="p-0" src="../../../../assets/blog/dafna/img2.jpg" alt="" />
    </div>
  </div>
</div>

<div class="container blog-item custom">
  <p class="quote-sign light-blue">“</p>

  <p>
    <b style="font-size: 26px; font-family: 'TBCX-BOLD'">
      თავდაპირველად ვერც წარმოვიდგენდით, რომ ობიექტს, რომელშიც მართლა მთელი სული და გული ჩავდეთ ყველა ასე შეიყვარებდა.
    </b>
  </p>

  <p>
    „დაფნაში“ ინტერიერი, მენიუ, სერვირება, ყველაფერი მინიმალისტურია, რადგან მთავარი აქცენტი კერძების გემოვნურ თვისებებსა
    და მომსახურების ხარისხზე კეთდება.
  </p>

  <p class="quote-sign light-blue">“</p>

  <p>
    <b style="font-size: 26px; font-family: 'TBCX-BOLD'">
      ხინკალი თავისთავად იმდენად დახვეწილი საკვებია ჩემთვის, ისევე როგორც ნებისმიერი ქართველისთვის, რომ არ სჭირდება
      რაიმეს დამატება. ჩვენ ვცდილობთ არტიზანული მიდგომით მაქსიმალურად შევუნარჩუნოთ მას ტრადიციული გემო
    </b>
  </p>

  <p>
    2022 წელს ფორბსის „The 12 Best Georgian Restaurants In Tbilisi“ -ის სიაში მოხვედრილი ლოკაცია სხვადასხვა საინტერესო
    კერძს სთავაზობს სტუმრებს. ქართული სამზარეულოს ახლებური ინტერპრეტაცია და საავტორო კერძები რომ მათ მაგიდაზე მოხვდეს,
    კომპანიას შესაბამისი სამზარეულო დანადგარები სჭირდება, რომელთა ნაწილიც თიბისი ლიზინგით დაიფინანსეს.
  </p>
</div>

<div class="container-fluid m-0 p-0">
  <div class="full-color background-light-blue p-0">
    <div class="container p-0">
      <img class="p-0" src="../../../../assets/blog/dafna/img3.jpg" alt="" />
    </div>
  </div>
</div>

<div class="container blog-item custom">
  <p class="quote-sign light-blue">“</p>

  <p>
    <b style="font-size: 26px; font-family: 'TBCX-BOLD'"
      >სამომავლო გეგმებზე საუბრისგან ახლა თავს შევიკავებ, თუმცა პირობას ვდებთ, რომ ქართველი თუ უცხოელი ვიზიტორებისთვის
      ერთ-ერთი საყვარელი ლოკაცია, კაფე „დაფნა“ მაქსიმალურს გააკეთებს იმისთვის, რომ ეს სტატუსი ღირსეულად შეინარჩუნოს.
    </b>
  </p>
</div>
