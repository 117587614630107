import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scooters',
  templateUrl: './blog-scooters.component.html',
  styleUrls: ['../../blog.component.scss']
})
export class BlogScootersComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
