import { Component, OnInit } from '@angular/core';
import { Offer, tbclOffers } from '../offers.data';

@Component({
  selector: 'app-offers-page',
  templateUrl: './offers-page.component.html',
  styleUrls: ['./offers-page.component.scss']
})
export class OffersPageComponent implements OnInit {
  offers: Offer[] = tbclOffers;

  constructor() {}

  ngOnInit(): void {}
}
