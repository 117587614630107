import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextBlogListComponent } from './blog/text-blog-list.component';
import { BlogRoutingModule } from '@app/blog/blog-routing.module';
import { BlogItemPreviewComponent } from './blog-item-preview/blog-item-preview.component';
import { BlogLeasingComponent } from '@app/blog/blog-item/leasing/blog-leasing.component';
import { BlogPageComponent } from './blog-page/blog-page.component';
import { ProjectItem1Component } from '@app/blog/video/items/project-item1.component';
import { ProjectItem2Component } from '@app/blog/video/items/project-item2.component';
import { ProjectItem3Component } from '@app/blog/video/items/project-item3.component';
import { BlogVideoListComponent } from '@app/blog/video/blog-video-list.component';
import { VideoComponent } from '@app/blog/video/video-item/video.component';
import { BlogBforumComponent } from '@app/blog/blog-item/bforum/blog-bforum.component';
import { BlogSolarComponent } from '@app/blog/blog-item/solar/blog-solar.component';
import { BlogUsedComponent } from '@app/blog/blog-item/used/blog-used.component';
import { BlogScootersComponent } from './blog-item/scooters/blog-scooters.component';
import { BlogToyotaEasyComponent } from './blog-item/toyota-easy/blog-toyota-easy.component';
import { BlogEstateLeasingComponent } from '@app/blog/blog-item/estate-leasing/blog-estate-leasing.component';
import { BlogForInfrastructure } from './blog-item/for-infrastructure/blog-for-infrastructure.component';
import { BlogVeli } from './blog-item/veli/blog-veli.component';
import { BlogAuction } from './blog-item/auction/blog-auction.component';
import { CustomCarouselModule } from '@app/shared/components/custom-carousel/custom-carousel.module';
import { BlogUserExperience } from './blog-item/user-experience/blog-user-experience.component';
import { BlogTravelTips } from './blog-item/travel-tips/blog-travel-tips.component';
import { BlogRecycling } from './blog-item/recycling/blog-recycling.component';
import { BlogLexusEasy } from './blog-item/lexus-easy/blog-lexus-easy.component';
import { BlogProBonoComponent } from './blog-item/pro-bono/blog-pro-bono.component';
import { BlogWorkspace } from './blog-item/workspace/blog-workspace.component';
import { BlogNuts } from './blog-item/nuts/blog-nuts.component';
import { BlogDafna } from './blog-item/dafna/blog-dafna.component';
import { BlogGreenLeasing } from './blog-item/green-leasing/blog-green-leasing.component';
import { BlogBuckswoodSchool } from './blog-item/buckswood-school/blog-buckswood-school.component';
import { BlogCoffeeLab } from './blog-item/coffee-lab/blog-coffee-lab.component';
import { BlogSwapCar } from './blog-item/swap-car/blog-swap-car.component';
import { BlogService } from './blog.service';
import { BlogSwapQuestion } from './blog-item/swap-question/blog-swap-question.component';

@NgModule({
  imports: [CommonModule, BlogRoutingModule, CustomCarouselModule],
  declarations: [
    TextBlogListComponent,
    BlogItemPreviewComponent,
    BlogLeasingComponent,
    BlogPageComponent,
    ProjectItem1Component,
    ProjectItem2Component,
    ProjectItem3Component,
    BlogVideoListComponent,
    VideoComponent,
    BlogBforumComponent,
    BlogSolarComponent,
    BlogUsedComponent,
    BlogScootersComponent,
    BlogToyotaEasyComponent,
    BlogEstateLeasingComponent,
    BlogForInfrastructure,
    BlogVeli,
    BlogAuction,
    BlogUserExperience,
    BlogTravelTips,
    BlogRecycling,
    BlogLexusEasy,
    BlogProBonoComponent,
    BlogWorkspace,
    BlogNuts,
    BlogDafna,
    BlogGreenLeasing,
    BlogCoffeeLab,
    BlogBuckswoodSchool,
    BlogSwapCar,
    BlogSwapQuestion
  ],
  providers: [BlogService]
})
export class BlogModule {}
