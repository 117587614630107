import { Component, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { I18nService } from '@app/core';
import { ApiService } from '@app/shared/api.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { VerifyConfirmationComponent } from '../verify-customer/verify-confirmation/verify-confirmation.component';

@Component({
  selector: 'app-sso-verify',
  templateUrl: './sso-verify.component.html',
  styleUrls: ['./sso-verify.component.scss']
})
export class SsoVerifyComponent {
  fg: UntypedFormGroup;

  submitted = false;

  termsRead = false;
  consentRead = false;

  showErrorMessage = false;

  constructor(
    private fb: UntypedFormBuilder,
    private api: ApiService,
    private modalService: NgbModal,
    public i18nService: I18nService
  ) {}

  ngOnInit() {
    this.fg = this.fb.group({
      customer: ['person'],
      ch1: [{ value: false, disabled: !this.consentRead }, [Validators.requiredTrue]],
      ch2: [{ value: false, disabled: !this.termsRead }, [Validators.requiredTrue]]
    });
  }

  get f() {
    return this.fg.controls;
  }

  verify() {
    this.api.verifyCustomer(this.f.sn.value).subscribe(
      value => {
        if (value.status === 200) {
          this.openDialog();
        } else {
          this.showError();
        }
      },
      error => {
        this.showError();
      }
    );
  }

  openDialog() {
    const config: NgbModalOptions = {
      centered: true,
      windowClass: 'modal-rounded'
    };
    const modalRef = this.modalService.open(VerifyConfirmationComponent, config);

    modalRef.componentInstance.name = 'World';

    modalRef.result.then(
      () => {
        this.submitted = true;
      },
      () => {
        this.submitted = true;
      }
    );
  }

  openModal(longContent: TemplateRef<any>, type: 'terms' | 'consent') {
    const modalReference = this.modalService.open(longContent, { scrollable: true, size: 'xl' });
    const sub: Subscription = modalReference.shown.subscribe(() => {
      const div = document.getElementById('personal-data');
      const hasVerticalScrollbar = div.scrollHeight > div.clientHeight;
      if (!hasVerticalScrollbar) {
        this.markTermsRead(type);
      }
      sub.unsubscribe();
    });
  }

  onScroll(event: any, type: 'terms' | 'consent') {
    let tracker = event.srcElement;
    let limit = tracker.scrollHeight - tracker.clientHeight;
    let position = tracker.scrollTop;
    if (limit - position < 10) {
      this.markTermsRead(type);
    }
  }

  markTermsRead(type: 'terms' | 'consent' = 'terms') {
    if (type === 'terms') {
      this.termsRead = true;
      this.fg.controls.ch2.enable();
    }
    if (type === 'consent') {
      this.consentRead = true;
      this.fg.controls.ch1.enable();
    }
  }

  showError() {
    alert('წარმოიშვა გაუთვალისწინებელი შეცდომა.');
  }

  startVerification() {
    const value = this.fg.value;
    if (!value.ch1 || !value.ch2) {
      this.showErrorMessage = true;
      return;
    }
    this.api.authorizationUrl(value.customer === 'company').subscribe({
      next: (response: any) => {
        console.log('Authorization sURL:', response);
        if (response) {
          window.location.href = response;
        } else {
          console.error('Authorization URL is missing in the response.');
        }
      },
      error: error => {
        console.error('Error retrieving authorization URL:', error);
      }
    });
  }
}
