// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// `.env.ts` is generated by the `npm run env` command
// TODO: temporary comment
// import env from './.env';

export const environment = {
  production: false,
  version: '1.0.0' + '-dev',
  serverUrl: '/api',
  GA_TRACKING_ID: 'G-JNSX6M2H5K',
  defaultLanguage: 'GE',
  supportedLanguages: ['GE', 'EN'],
  SITE_KEY: '6LcwkRMqAAAAAI3Q-HJ5FGMNvV2SBMaIDN6WNAb_'
};
