<div class="blog-preview">
  <a [routerLink]="['/' + link]">
    <div class="blog-preview-img">
      <img [src]="previewImage" alt="" />
    </div>
    <span>{{ postDate }}</span>
    <h2>{{ title }}</h2>
    <p>
      {{ previewText }}
    </p>
  </a>
</div>
