<div class="verify-container">
  <form [formGroup]="formType">
    <label>
      <input type="radio" [value]="true" formControlName="person" [(ngModel)]="isIndividual" [checked]="true" />
      <span>ფიზიკური პირი</span>
    </label>
    <label>
      <input type="radio" [value]="false" formControlName="company" [(ngModel)]="isIndividual" />
      <span>იურ. პირი</span>
    </label>
  </form>

  <p>
    მე, (შემდგომში „კლიენტი“) გამოვხატავ თანხმობას და უფლებას ვაძლევ სს „თიბისი ლიზინგს“ (ს/ნ205016560) (შემდგომში
    სალიზინგო კომპანია), საქართველოს კანონმდებლობით დადგენილი წესით და პირობებით, დაამუშაოს წინამდებარე განაცხადში,
    სალიზინგო კომპანიაში დაცული, ასევე, ჩემ შესახებ საკრედიტო ბიუროებში (შემდგომში „ბიურო“) დაცული და მათ მიერ
    მოწოდებული ინფორმაცია ჩემი, როგორც მომავალი ლიზინგის მიმღების/თავდების/უზრუნველყოფის მიმწოდებლის გადახდისუნარიანობის
    ანალიზის მიზნით. გაცნობიერებული მაქვს საქართველოს კანონმდებლობით გათვალისწინებული უფლებები, რომ ჩემი მოთხოვნის
    შემთხვევაში, მონაცემთა დამმუშავებელი ვალდებულია გაასწოროს, განაახლოს, დაამატოს, დაბლოკოს, წაშალოს ან გაანადგუროს
    მონაცემები, თუ ისინი არასრულია, არაზუსტია, არ არის განახლებული, ან თუ მათი შეგროვება და დამუშავება განხორციელდა
    კანონის საწინააღმდეგოდ. აღნიშნული თანხმობაარის ერთჯერადი და მოქმედია ხელმოწერიდან 30 სამუშაო დღის განმავლობაში.
    კრედიტ ინფოს ახალი მოთხოვნის თანახმად, მომხმარებლის მიერ სალიზინგო მომსახურების განაცხადის ონლაინ შევსებისას, უნდა
    მოხდეს კლიენტის ვერიფიკაცია. ვერიფიკაციისთვის ონლაინ განაცხადში უნდა მიეთითოს მოთხოვნილი ინფორმაცია და განხორციელდეს
    მომხმარებლის საბანკო ანგარიშიდან ლიზინგის საბანკო ანგარიშზე.
  </p>

  <ng-container *ngIf="isIndividual === true">
    <form [formGroup]="fg">
      <div class="row">
        <div class="col-12">
          <div class="sn-container">
            <div class="row">
              <div class="col-12">
                <input type="text" placeholder="საიდენტიფიკაციო" formControlName="sn" maxlength="11" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div *ngIf="f.sn.touched && f.sn.invalid" class="sn-error">
            <div *ngIf="f.sn.errors.required">შეიუვანეთ პ.ნ. ან საიდენტიფიკაციო</div>
            <div *ngIf="f.sn.errors.minlength">პ.ნ. / საიდენტიფიკაციო შედგება 9-11 ციფრისგან</div>
            <div *ngIf="f.sn.errors.maxlength">პ.ნ. / საიდენტიფიკაციო შედგება 9-11 ციფრისგან</div>
            <div *ngIf="f.sn.errors.pattern">პ.ნ. / საიდენტიფიკაციო ნომერი შედგება მხოლოდ ციფრებისგან</div>
          </div>
        </div>
        <div class="col-12">
          <div class="button-verify-container">
            <button class="button-verify" [disabled]="!fg.valid" (click)="verify()">ვეთანხმები</button>
          </div>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="isIndividual === false">
    <form [formGroup]="fg2">
      <div class="row">
        <div class="col-12">
          <div class="sn-container">
            <div class="row">
              <div class="col-12">
                <input type="text" placeholder="პ.ნ" formControlName="sn" maxlength="11" />
              </div>

              <div class="col-12">
                <input type="text" placeholder="სახელი" formControlName="responsibleFirstName" />
              </div>
              <div class="col-12">
                <input type="text" placeholder="გვარი" formControlName="responsibleLastName" />
              </div>
              <div class="col-12">
                <input type="text" placeholder="პასუხისმგებლის პ.ნ" formControlName="responsibleSn" maxlength="11" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div *ngIf="f.sn.touched && f.sn.invalid" class="sn-error">
            <div *ngIf="f.sn.errors.required">შეიუვანეთ პ.ნ. ან საიდენტიფიკაციო</div>
            <div *ngIf="f.sn.errors.minlength">პ.ნ. / საიდენტიფიკაციო შედგება 9-11 ციფრისგან</div>
            <div *ngIf="f.sn.errors.maxlength">პ.ნ. / საიდენტიფიკაციო შედგება 9-11 ციფრისგან</div>
            <div *ngIf="f.sn.errors.pattern">პ.ნ. / საიდენტიფიკაციო ნომერი შედგება მხოლოდ ციფრებისგან</div>
          </div>
        </div>
        <div class="col-12">
          <div class="button-verify-container">
            <button class="button-verify" [disabled]="!fg2.valid" (click)="verify()">ვეთანხმები</button>
          </div>
        </div>
      </div>
    </form>
  </ng-container>

  <div *ngIf="submitted">
    <div class="row">
      <div class="col-12">
        <div class="iban-number">
          GE26TB7913136020100017
        </div>
      </div>
      <div class="col-12">
        <p class="iban-banner">
          <br />
          0.01 ლარის გადარიცხვა ან სხვა სიმბოლური თანხა. ლიზინგის სისტემა მოახდენს მომხმარებლის მიერ განაცხადში და
          საგადახდო დავალებაში მითითებული პირადი ინფორმაციის შედარებას და მონაცემების თანხვედრის შემთხვევაში, ინფორმაცია
          დასამუშავებლად კრედიტ ინფოში გაიგზავნება.
        </p>
      </div>
    </div>
  </div>
</div>
