import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-text-list',
  templateUrl: './text-blog-list.component.html',
  styleUrls: ['../blog.component.scss']
})
export class TextBlogListComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
