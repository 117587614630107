import { Component } from '@angular/core';

@Component({
  selector: 'app-blog-auction',
  templateUrl: './blog-auction.component.html',
  styleUrls: ['../../blog.component.scss']
})
export class BlogAuction {
  constructor() {}

  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
