import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'agreements', loadChildren: () => import('./agreements/agreements.module').then(m => m.AgreementsModule) },
  { path: 'cms', loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule) },
  { path: 'cookie-policy', loadChildren: () => import('./cookies/cookies.module').then(m => m.CookiesModule) },
  { path: 'products', loadChildren: () => import('./products/products.module').then(m => m.ProductsModule) },
  { path: 'faq', loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule) },
  { path: 'about', loadChildren: () => import('./about/about.module').then(m => m.AboutModule) },
  { path: 'contact', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) },
  { path: 'vacancy', loadChildren: () => import('./vacancy/vacancy.module').then(m => m.VacancyModule) },
  { path: 'news', loadChildren: () => import('./news/news.module').then(m => m.NewsModule) },
  { path: 'meet', loadChildren: () => import('./meet/meet.module').then(m => m.MeetModule) },
  { path: 'for-investors', loadChildren: () => import('./investor/investor.module').then(m => m.InvestorModule) },
  { path: 'schedule', loadChildren: () => import('./schedule/schedule.module').then(m => m.ScheduleModule) },
  { path: 'auto', loadChildren: () => import('./starto/starto.module').then(m => m.StartoModule) },

  // Fallback when no prior route is matched
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
