import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-leasing',
  templateUrl: './blog-leasing.component.html',
  styleUrls: ['../../blog.component.scss']
})
export class BlogLeasingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
