<div class="container-fluid m-0 p-0">
  <div class="blog-header-container">
    <h1>ბლოგი</h1>
  </div>
</div>

<div class="container blog-item custom">
  <h1>
    ბაქსვუდის სკოლა <br />
    Ad Vitam Paramus - ვამზადებთ ცხოვრებისთვის
  </h1>
  <h6>დეკემბერი 2023</h6>
  <br />

  <img src="../../../../assets/blog/buckswood-school/img1.jpg" alt="" />

  <p>
    ბაქსვუდის საერთაშორისო სკოლა „გაეროს ასოციაციის საერთაშორისო სკოლის“ სახელით თბილისში 2000 წელს დაარსდა. ეს არის
    საქართველოში პირველი ტრადიციულ ბრიტანულ საგანმანათლებლო მოდელზე აგებული სასწავლო სივრცე. განვითარების პერიოდში
    ბაქსვუდის ყოველი ახალი სასწავლო წელი ადასტურებს დამფუძნებლების პროფესიონალურ ხედვასა და სწორ ღირებულებებს.
  </p>

  <p>
    ეს ხედვა ემყარება უმაღლესი სტანდარტის ბრიტანულ და ქართულ სააღმზრდელო პრინციპებს. ყოველი აკადემიური, ინფრასტრუქტურული
    თუ შიდა კულტურული ინოვაცია ემსახურება მხოლოდ და მხოლოდ მომავალი თაობების აღზრდას საზოგადობის სრულფასოვან და ღირსეულ
    წევრებად, შრომისმოყვარე, მიზანდასახულ, პასუხისმგებლიან, სამართლიან და წარმატებულ მოქალაქეებად.
  </p>

  <p class="quote-sign green">“</p>

  <p>
    <b style="font-size: 26px; font-family: 'TBCX-BOLD'">
      ჩვენი წარმატების განმაპირობებელი, მეტწილად ის ხედვაა, რომელსაც ბაქსვუდის სკოლა დაარსების დღიდან ეფუძნება. სკოლის
      გუნდს მიაჩნია, რომ თითოეული თანამშრომელი ემსახურება მოსწავლის პიროვნულ და ფსიქოემოციურ განვითარებას შემდეგი
      პრიორიტეტებით: ბავშვი, როგორც პიროვნება - (ბედნიერი და რეალიზებული) ბავშვი, როგორც მოქალაქე - (ღირსეული და
      ერუდირებული) ბავშვი, როგორც პოტენციური ლიდერი - (მაძიებელი და შემქმნელი).
    </b>
  </p>
</div>

<div class="container-fluid m-0 p-0">
  <div class="full-color background-green p-0">
    <div class="container p-0">
      <img class="p-0" src="../../../../assets/blog/buckswood-school/img2.jpg" alt="" />
    </div>
  </div>
</div>

<div class="container blog-item custom">
  <p>
    მომავალი თაობისთვის სწორი ღირებულებების მისაცემად სკოლა ზრუნავს არა მხოლოდ უსაფრთხოებაზე, არამედ -
    ეკომეგობრულობაზეც, რასაც მისი გადაწყვეტილებები მოწმობს. წყნეთის კამპუსი ფაქტობრივად სრულად ენერგოეფექტურია. თიბისი
    ლიზინგის დაფინანსებით მზის ელექტროსადგური მოეწყო. ტერიტორიაზე განთავსებული მზის ფოტოელექტრონული მოდულების
    მომწოდებელი პარტნიორი - ენოვუსია.
  </p>

  <p class="quote-sign green">“</p>

  <p>
    <b style="font-size: 26px; font-family: 'TBCX-BOLD'">
      ის რასაც მოსწავლე ადგილზე ხედავს, ხდება მისი ღირებულებების განმსაზღვრელი. სწორედ ამიტომ, მნიშვნელოვანია ჩვენი
      სკოლის ეკომეგობრული პოლიტიკა, რომელიც სამაგალითოა მომავალი თაობისთვისაც.
    </b>
  </p>

  <p>
    ასევე, სკოლა წელს ახალ ლოკაციაზეც გაიხსნა. გელოვანზე არსებული კამპუსი საგანმანათლებლო, სპორტულ და გასართობ
    ინფრასტრუქტურას მოიცავს და დაპროექტებულია საერთაშორისო და ქართველ არქიტექტორთა პროფესიონალი გუნდის მიერ. შენობის
    ერთ-ერთი უპირატესობა ამ შემთხვევაშიც მისი ენერგოეფექტურობაა.
  </p>

  <p>
    ეს არ გახლავთ თიბისი ლიზინგის და ბაქსვუდის სკოლის თანამშრომლობის პირველი შემთხვევა, რამდენიმე წლის წინ, მოსწავლეთა
    უსაფრთხო და კომფორტული გადაადგილებისთვის საჭირო ავტობუსები დავაფინანსეთ.
  </p>
</div>

<div class="container-fluid m-0 p-0">
  <div class="full-color background-green p-0">
    <div class="container p-0">
      <img class="p-0" src="../../../../assets/blog/buckswood-school/img3.jpg" alt="" />
    </div>
  </div>
</div>

<div class="container blog-item custom">
  <p class="quote-sign green">“</p>

  <p>
    <b style="font-size: 26px; font-family: 'TBCX-BOLD'">
      თიბისი ლიზინგთან უკვე რამდენიმეწლიანი მეგობრობა გვაკავშირებს, რაც სხვადასხვა დროს, სხვადასხვა პროდუქტის ფარგლებში
      თანამშრომლობას მოიაზრებს. ამ ურთიერთობის კომფორტულობას კი მიმართვიდან რამდენიმე საათში დაკმაყოფილება და მოქნილი
      საოპერაციო ნაწილი განაპირობებს. და რადგან ბაქსვუდის საერთაშორისო სკოლა ინოვაციების გზაზე გაჩერებას არ აპირებს,
      იმედი გვაქვს კიდევ უფრო მეტ სიახლეს შევთავაზებთ ქართულ საზოგადოებას.
    </b>
  </p>
</div>
