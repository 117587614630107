<div class="container-fluid m-0 p-0">
  <div class="blog-header-container">
    <h1>ბლოგი</h1>
  </div>
</div>

<div class="container blog-item custom">
  <h1>
    თიბისი ლიზინგის CSR მიმართულება <br />
    <span style="font-family: TBCX-Regular;">Vol.2</span>
  </h1>
  <h6>დეკემბერი 2023</h6>
  <br />

  <img src="../../../../assets/blog/green-leasing/img1.jpg" alt="" />

  <p>
    წინა ბლოგში ხელოვნურ ინტელექტს ვთხოვეთ აღეწერა იდეალური სამუშაო კვირა კომპანიაში, რომელშიც თანამშრომლებზე ზრუნვაა
    პრიორიტეტული. ChatGPT-მ და ჩვენ გადავწყვიტეთ, რომ საუკეთესო ვარიანტი თიბისი ლიზინგში შემოხედვაა და არა, საერთოდ არ
    ვართ სუბიექტურები.
  </p>

  <p>
    როგორც უკვე აღვნიშნეთ, ჯანსაღი ცხოვრების წესის, ფიზიკური თუ გონებრივი აქტივობების პოპულარიზაციისთვის მრავალფეროვანი
    პროექტები განვახორციელეთ, თუმცა არანაკლებ მნიშვნელობას ვანიჭებთ თანამშრომლების ბედნიერებისა თუ კმაყოფილების
    კვლევებიდან ამოსულ უკუკავშირს, რომელზე დაყრდნობითაც სხვადასხვა აქტივობებს ვგეგმავთ,
  </p>

  <p>
    როგორც აღმოჩნდა, ჩვენი, როგორც ალბათ ნებისმიერი დაკავებული, საქმიანი, თანამედროვე ადამიანისთვის, ერთ-ერთი „ტკივილი“
    სასარგებლო სამოქალაქო აქტივიზმისთვის არასაკმარისი დროა. ზუსტად ვიცით, რომ კარგი ადამიანები ვართ, ოღონდ არ ვიცით რა
    გზით შეიძლება ნებაყოფლობით ვემსახუროთ მათ, ვისაც ეს სჭირდება.
  </p>

  <img src="../../../../assets/blog/green-leasing/img2.jpg" alt="" />

  <p>
    BrandHR-ის დახურული კარი იმის ნიშანია, რომ განსაკუთრებული იდეები იხარშება, ოღონდ ჯერ პრივატულად, თანამშრომლების
    თვალსა და ყურს მიღმა. სწორედ ასეთი ერთ-ერთი შეხვედრისას გადაწყდა, რომ „პრო ბონო“ საქმიანობა უნდა დაგვეწყო, რაც
    გულისხმობს. სრულიად უსასყიდლო, ექსპერტულ, კომპეტენციის მიმართულებით დახმარებას მცირე ბიზნესისთვის, რომელსაც მსგავსი
    რესურსი არ აქვს. რადგან ეს თემა ვრცლად უკვე მიმოვიხილეთ, გიზიარებთ
    <a href="https://tbcleasing.ge/blog/blog-pro-bono" target="_blank">ბლოგს</a>, რომელსაც უნდა გაეცნო თუკი გამოგრჩა.
  </p>

  <img src="../../../../assets/blog/green-leasing/img3.jpg" alt="" />

  <p>
    აქვე, არ დაგვავიწყდეს, რომ კარგი მოქალაქეობა ეთიკურობის გარკვეულ ზღვარზეც გადის, მაგალითად - გარემოზე ზრუნვაზე.
    სწორედ ამიტომ, თითქმის 1 წელია, რაც მთელი თიბისი ლიზინგის მასშტაბით „ტენეს“ ყუთებში ვაგროვებთ პლასტმასას, ქაღალდს და
    ელემენტებს, რაც მუშავდება და პირველ ქართულ ეკომეგობრულ USB კაბელად გარდაიქმნება.
  </p>

  <p>
    მხოლოდ სეპარაცია არ კმარა, მნიშვნელოვანია ცნობიერების ამაღლებაც, რისთვისაც დახმარება „ტენეს“ დამფუძნებელს, სანდრო
    ლილუაშვილს ვთხოვეთ. ჩვენი მეგობარი სტარტაპის ხელმძღვანელმა სიამოვნებით ჩაგვიტარა ტრენინგი, რომლის მიხედვითაც მარტივი
    კითხვები ჩამოვაყალიბეთ. აღნიშნული კითხვები ყოველ კვირა თავსდება ჩვენს შიდა ჯგუფში, პირველი სწორი პასუხის ავტორი კი,
    აღიარებით და „ტენეს“ USB კაბელით ჯილდოვდება.
  </p>

  <img src="../../../../assets/blog/green-leasing/img4.jpg" alt="" />

  <p>
    SpaceRefinery-ის ოქროს რჩევების მიხედვით, საოფისე გარემოში პროდუქტიულობისა და კონცენტრაციის გაზრდისთვის საუკეთესო
    გზა - მცენარეების დამატებაა. კვლევის მიხედვით, თანამშრომლებში, რომელთა სამუშაო სივრცეშიც დიდი დოზით სიმწვანეა,
    15%-ით უკეთ გრძნობენ თავს, ვიდრე ისინი, რომელთა ორგანიზაციებშიც აღნიშნულ საკითხს არ აქცევენ ყურადღებას. მეტიც,
    სიდნეის უნივერსიტეტის მოწოდებული ციფრები შემდეგზე მეტყველებს, მცენარეები ოფისში ამცირებს სტრესს, შფოთვას, ნეგატიურ
    ემოციებს. მნიშვნელოვანია, რომ აღნიშნული ფაქტორი არა მხოლოდ მენტალურ, არამედ ფიზიკურ ჯანმრთელობაზეც მოქმედებს, რადგან
    გამწვანებულ სივრცეში მეტია ჟანგბადი და ტენიანობა.
  </p>
</div>

<div class="container-fluid m-0 p-0">
  <div class="full-color background-green">
    <div class="container">
      <p class="bold-text">
        ჩვენს თანამშრომლებს აქვთ არაჩვეულებრივი შესაძლებლობა, ყოველდღიურად შესვენება თბილისის ყველაზე მწვანე ტერიტორიაზე
        ვაკის პარკში გაატარონ, ამავდროულად, იმუშაონ საოფისე სივრცეში, რომლის ყველა სართულზე, ყველა კუთხეში, ფაქტობრივად
        ყოველ 1 მეტრში სხვადასხვა ტიპის მცენარეა განთავსებული.
      </p>
    </div>
  </div>
</div>

<div class="container blog-item custom">
  <p>
    Vol.2-ს აქ დავასრულებთ, თუმცა ბლოგების სერია აუცილებლად გაგრძელდება კიდევ მეტი საინტერესო ფაქტით ჩვენს შესახებ.
  </p>
</div>
