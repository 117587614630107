<div class="container-fluid m-0 p-0">
  <div class="blog-header-container">
    <h1>ბლოგი</h1>
  </div>
</div>

<div class="container blog-item custom">
  <h1>
    ქართული თხილის ევროპული პერსპექტივები<br />
    Nuts Incorporated
  </h1>
  <h6>დეკემბერი 2023</h6>
  <br />

  <p>
    იცოდი, რომ ყველასთვის საყვარელი იტალიური შოკოლადის „ფერერო როშეს“ ნაწილში, შუაგულში მოთავსებული თხილი ქართულია?!
    სანამ მოგიყვებით თუ როგორ აღმოჩნდა ჩვენთან მოყვანილი პროდუქტი ევროპის ცენტრში, ერთ-ერთი წამყვანი ბრენდის საწარმოში,
    საინტერესო დეტალებს გაგაცნობთ.
  </p>

  <img src="../../../../assets/blog/nuts/img1.jpg" alt="" />

  <p>
    კაკლოვანი კულტურების პროდუქცია მიეკუთვნება იმ იშვიათ სახეობას, რომლის ფასები მსოფლიო ბაზარზე ზრდის სტაბილური
    ტენდენციით ხასიათდება. კერძოდ, ევროკავშირის ქვეყნებში აღნიშნული მიმართულებით მოთხოვნა და პროდუქტის ფასი სულ უფრო
    იზრდება, შესაბამისად, ქართველი მეწარმეების დაინტერესებაც მზარდია.
  </p>

  <p>
    2021 წლის 1 აგვისტოდან 2022 წლის 13 თებერვლის ჩათვლით საქართველოდან 19.7 ათასი ტონა თხილის ექსპორტი განხორციელდა,
    რომლის ღირებულებამ 90.4 მლნ აშშ დოლარი შეადგინა. გასული წლის ანალოგიურ პერიოდთან შედარებით, ექსპორტირებული თხილის
    მოცულობა 4.1 ათასი ტონით (26%), ხოლო ღირებულება 7.4 მლნ აშშ დოლარით (9%) გაიზარდა. მნიშვნელოვანია ის ფაქტიც, რომ
    ექსპორტის ქვეყნები დივერსიფიცირებულია.
  </p>

  <p>
    Nuts Incorporated ნუშისა და თხილის ბაღების სიდიდით პირველი კომპანიაა ქვეყანაში. ამ ეტაპზე ორგანიზაცია 3000 ჰა-ზე
    გაშენებულ ბაღებს მართავს, საიდანაც 700 ჰა-ზე ნუშის პლანტაციები მცხეთა-მთიანეთისა და ქვემო ქართლში მდებარეობს, ხოლო 2
    300 ჰა თხილის ბაღები სამეგრელოშია.
  </p>

  <img src="../../../../assets/blog/nuts/img2.jpg" alt="" />
</div>

<div class="container-fluid m-0 p-0">
  <div class="full-color background-green" style="padding-top: 20px">
    <div class="container" style="max-width: 800px;">
      <p class="quote quote-sign left-align">“</p>

      <p class="quote left-align">
        „ჩვენ მუდმივად ვახორციელებთ ინვესტიციებს უახლეს ტექნოლოგიებსა და კვალიფიციურ კადრებში, რომლებიც ზედმიწევნით
        ამუშავებენ თითოეულ პროდუქტს კულტივაციიდან - წარმოებამდე. სწორედ ეს უზრუნველყოფს ჩვენი თხილის უმაღლეს ხარისხსს.“
      </p>
    </div>
  </div>
</div>

<div class="container blog-item custom">
  <p>
    Nuts Incorporated-ი ბაზარზე თხილის ერთ-ერთი უმსხვილესი მიმწოდებელია, რომელიც მაღალი ხარისხის თხილის ექსპორტს როგორც
    ევროპის ასევე გალფის და სხვა ქვეყნებში ახორციელებს.
  </p>
  <p>
    კომპანიის მრავალწლოვანი თხილის ბაღები მაქსიმალურ მოსავლიანობას 2025 წლისთვის მიაღწევს. სუპერნოვას, ლორენის, გუარას
    ჯიშების წლიური და ორწლიური ბაღები კი ნაყოფის მომტანი 2023 წელს, მაქსიმალურ მოსავალის კი - 2025 წელს გახდება.
  </p>
  <img src="../../../../assets/blog/nuts/img3.jpg" alt="" />
</div>

<div class="container-fluid m-0 p-0">
  <div class="full-color background-green" style="padding-top: 20px">
    <div class="container" style="max-width: 800px;">
      <p class="quote quote-sign left-align">“</p>

      <p class="quote left-align">
        „პირველადი წარმოებიდან გადამუშავებამდე, ჩვენი ოპერაციები იცავს გლობალურად აღიარებული სერტიფიკატების მკაცრ
        მოთხოვნებს, მათ შორის Global Gap პირველადი წარმოებისთვის და ISO9000 და BRC გადამუშავებისთვის. ეს სერტიფიკატები
        ადასტურებს ჩვენს ვალდებულებას მივაწოდოთ განსაკუთრებული პროდუქტები, რომლებიც არა მხოლოდ აკმაყოფილებს, არამედ
        აღემატება ჩვენი ძვირფასი მომხმარებლების მოლოდინს.“
      </p>
    </div>
  </div>
</div>

<div class="container blog-item custom">
  <p>
    თიბისი ლიზინგისთვის განსაკუთრებით მნიშვნელოვანია ისეთი ბიზნესის მხარდაჭერა, რომელიც როგორც არსებული, ასევე სამომავლო
    პერსპექტივით მნიშვნელოვანი კეთილდღეობის შემქმნელია, ამიტომ, Nuts Incorporated-ს ეფექტური აგრარული საქმიანობისთვის
    მძიმე ტექნიკა - ტრაქტორები და მოსავლის ამღებები 2 ინვესტიციით დავაფინანსეთ.
  </p>
</div>
