import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface AutoForm {
  userName: string;
  userLastName: string;
  userSn: string;
  userMobile: string;
  financeAmount: number;
  paymentNumber: number;
  producerId: string;
  model: string;
  year: number;
  carNumber: string;
  oilType: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private httpClient: HttpClient) {}

  findSchedule(scheduleId: string): Observable<Blob> {
    return this.httpClient.get('/api/portal/schedule/' + scheduleId, { responseType: 'blob' });
  }

  rejectSchedule(sn: string): Observable<HttpResponse<any>> {
    return this.httpClient.post<HttpResponse<any>>(
      '/api/portal/covid/reject/schedule',
      { sn: sn },
      { observe: 'response' }
    );
  }

  approveSchedule(sn: string): Observable<HttpResponse<any>> {
    return this.httpClient.post<HttpResponse<any>>(
      '/api/portal/covid/approve/schedule',
      { sn: sn },
      { observe: 'response' }
    );
  }

  verifyCustomer(sn: string): Observable<HttpResponse<any>> {
    return this.httpClient.post<HttpResponse<any>>('/api/verify/sn', { sn: sn }, { observe: 'response' });
  }

  verifyCustomerV2(
    sn: string,
    isIndividual: boolean,
    responsibleFirstName: string,
    responsibleLastName: string,
    responsibleSn: string
  ): Observable<HttpResponse<any>> {
    return this.httpClient.post<HttpResponse<any>>(
      '/api/verify/snv2',
      {
        sn: sn,
        isIndividual: isIndividual,
        responsibleFirstName: responsibleFirstName,
        responsibleLastName: responsibleLastName,
        responsibleSn: responsibleSn
      },
      { observe: 'response' }
    );
  }

  sendForm(form: AutoForm): Observable<any> {
    return this.httpClient.post<HttpResponse<any>>('/api/starto/form', form, { observe: 'response' });
  }

  authorizationUrl(company: boolean): Observable<string> {
    return this.httpClient.get(`/api/sso/authorization-url${company ? '?company=true' : ''}`, {
      responseType: 'text',
      observe: 'body'
    });
  }

  handleCode(code: string): Observable<HttpResponse<any>> {
    return this.httpClient.get<HttpResponse<any>>('/api/sso/handle?code=' + code, { observe: 'response' });
  }
}
