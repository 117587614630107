<div class="container-fluid m-0 p-0">
  <div class="blog-header-container">
    <h1>ბლოგი</h1>
  </div>
</div>
<div class="container blog-item custom">
  <h1>
    „დღეს ტენე პლასტმასა?“ – კითხვა რომელიც თანამშრომლების ყოველდღიურობის ნაწილი გახდა
  </h1>
  <h6>ივლისი 2023</h6>
  <br />

  <p>
    დაახლოებით 3 თვის წინ თიბისი ლიზინგის შენობაში მწვანე ყუთები „აქ ტენე პლასტმასა“ - მოწოდებით გამოჩნდა. კი, სწორად
    მიხვდით, ზუსტად ეს დროა გასული მას შემდეგ, რაც სიხარულით ვამცნეთ თანამშრომლებს და შემდეგ უკვე საზოგადოებას, რომ
    გარემოზე ზრუნვის მოტივით „ტენეს“ დავუმეგობრდით. არანაკლები ენთუზიაზმით ჩავწერეთ მოკლე საინფორმაციო ვიდეოები
    ერთჯერადი ჭიქების კოლექტორის და მწვანე ყუთების დატვირთვის შესახებ და ზარ-ზეიმით აღვნიშნეთ პირველი მისიის შესრულებაც.
    გადავწყვიტეთ, რომ კარგ ამბებს გაზიარება უხდება და ჩვენი თანამშრომლები საკუთარ გამოცდილებაზე მოგიყვებიან, თუმცა
    იქამდე, რამდენიმე ფაქტზე გავამახვილებთ თქვენს ყურადღებას.
  </p>

  <img src="../../../../assets/blog/recycling/img1.jpg" alt="" />

  <p>
    დიდი ხანია, რაც გარემოს დაცვა არჩევანი არ არის, ეს ერთგვარი ვალდებულებაა, რომელსაც დედამიწის ნებისმიერ კუთხეში
    მცხოვრები ადამიანები ვიზიარებთ. ყველაზე დიდი საფრთხე კი, მაშინ დგება, როცა ვფიქრობთ რომ ჩვენს მაგივრად სხვა იზრუნებს
    ამ საკითხზე. თავი რომ არ შეგაწყინოთ, გეტყვით, რომ:
  </p>
</div>

<div class="container-fluid m-0 p-0">
  <div class="full-color background-middle-green">
    <div class="container">
      <ul class="pointed-list">
        <li>ყოველწლიურად დაახლოებით 380 მილიონი ტონა პლასტმასა იწარმოება;</li>
        <li>
          Plasticoceans.org-ის მიხედვით, ამ რიცხვის 50%-მდე ერთჯერადი მოხმარების ნივთების დასამზადებლადაა განკუთვნილი;
        </li>
        <li>
          ადამიანები ერთხელ ვსვამთ წყალს პლასტმასას ჭიქიდან, ვიყენებთ ფერად-ფერად საწრუპებს და ერთჯერად თეფშებს, თუმცა
          მათ დაშლას შესაძლოა 20-დან 500 წლამდე დასჭირდეს;
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="container blog-item custom">
  <p>
    კვლევების მიხედვით, აღნიშნული ციფრები ასე გამოიყურება: ერთჯერადი ცელოფანი - 20 წელი, საწრუპი - 200 წელი, ბოთლი - 450
    წელი, პლასტმასას კბილის ჯაგრისი - 500 წელი.
  </p>

  <p>
    პლასტმასას ნარჩენები უარყოფით გავლენას ახდენს ადამიანებზე, ეკოსისტემასა და კლიმატის ცვლილებაზე, რა საკითხებზეც
    მოგვიანებით, კიდევ უფრო დეტალურად მოგიყვებით. იქამდე კი, ტენეს კიდევ ერთხელ გაგაცნობთ:
  </p>

  <p>
    ტენე არის პირველი ქართული და ამავდროულად მსოფლიოში პირველი ეკომეგობრული USB კაბელი, რომლის მიზანიც კლიმატის
    ცვლილების წინააღმდეგ ბრძოლაა.
  </p>

  <p>
    აღნიშნული მიზნის მისაღწევად ტენე მოსახლეობისგან სხვადასხვა სახის წახალისების გზით, იბარებს საყოფაცხოვრებო მყარ
    ნარჩენს, რომელსაც მოგვიანებით გადაამუშავებს და კაბელებს ამზადებს.
  </p>

  <p style="font-size: 24px">
    <b class="middle-green"> ჩვენი თანამშრომლების #გამოცდილება</b>
  </p>

  <img src="../../../../assets/blog/recycling/img2.jpg" alt="" />

  <p class="quote-sign middle-green">“</p>

  <p>
    <b>
      მას შემდეგ, რაც მწვანე ყუთები გამოჩნდა ჩვენს შენობაში, ყოველდღიურობას ახალი აქტივობა და „როგორ ხარ“-ის შემდეგ
      საკითხავი „დღეს ტენე პლასტმასა?!“ დაემატა. იმდენად გადამდები მუხტი აქვს ამ მნიშვნელოვანი იდეის გარშემო ჩვენს
      გაერთიანებას, რომ ყოველ ავლა-ჩავლაზე ვაკვირდები, რომელი დეპარტამენტი ყოჩაღობს და აქტიურად ავსებს „ტენეს“
      კოლექტორებს.
    </b>
  </p>

  <p class="right-align">თაკო, ოპერაციების სპეციალისტი</p>

  <p class="quote-sign middle-green">“</p>

  <p>
    <b>
      თავდაპირველად მეტ-ნაკლებად სკეპტიკურად ვიყავი განწყობილი ახალი წამოწყებისადმი, რადგან ჩვენდა სამწუხაროდ,
      სეპარაციის კულტურა თუ შესაძლებლობა ამ ეტაპზე საქართველოში ნაკლებად გვაქვს, თუმცა ის რაც ამ სამი თვის მანძილზე
      ვნახე, რადიკალურად განსხვავებულს მოწმობს. დღიდან-დღემდე ვაკვირდებით ყუთებში შეგროვებული პლასტმასას რაოდენობრივ
      პროგრესს და ვფიქრობთ ახალ ინიციატივებზე, რომელიც კიდევ უფრო გააძლიერებს გარემოზე ზრუნვის ინიციატივას
    </b>
  </p>

  <p class="right-align">მარიამი, მომხმარებლებთან ურთიერთობის სპეციალისტი</p>

  <img src="../../../../assets/blog/recycling/img3.jpg" alt="" />

  <p class="quote-sign middle-green">“</p>

  <p>
    <b>
      შესაძლოა მკითხველისთვის უცნაური იყოს ის, რასაც ვიტყვი, მაგრამ ლიზინგი ერთ-ერთი ყველაზე მწვანე პროდუქტია, რადგან
      ნივთების მოკლე პერიოდით, ერთი მეპატრონის მიერ ფლობას კი არ ახალისებს, მდგრადობის პრინციპით მოქმედებს. ის, რაც აღარ
      გჭირდება შენ - შესაძლოა სარგებლის მომტანი იყოს სხვისთვის. სწორედ ამიტომ, განსაკუთრებული ენთუზიაზმით ვერთვებით
      ინიციატივებში, რაც გარემოს დაცვას და ადამიანური ზიანის შემცირებას მოიაზრებს.
    </b>
  </p>

  <p class="right-align">ია, სამშენებლო და ჯანდაცვის სექტორის უფროსი</p>
</div>

<div class="container-fluid m-0 p-0">
  <div class="full-color background-middle-green">
    <div class="container">
      <p class="quote">
        წარმატებული დამეგობრება მხოლოდ დასაწყისია იმ პროექტების და აქტივობების, რომლის შთაგონებაც ჩვენი თანამშრომლების
        უკუკავშირმა მოგვცა. სწორედ ამიტომ, გვადევნეთ თვალი და გაეცანით სიახლეებს, რომელსაც მწვანე ინიციატივების
        მიმართულებით დავგეგმავთ.
      </p>
    </div>
  </div>
</div>
