import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-travel-tips',
  templateUrl: './blog-travel-tips.component.html',
  styleUrls: ['../../blog.component.scss']
})
export class BlogTravelTips implements OnInit {
  images: string[] = [
    '/assets/blog/travel-tips/img2.jpg',
    '/assets/blog/travel-tips/img3.jpg',
    '/assets/blog/travel-tips/img4.jpg',
    '/assets/blog/travel-tips/img5.jpg',
    '/assets/blog/travel-tips/img6.jpg',
    '/assets/blog/travel-tips/img7.jpg',
    '/assets/blog/travel-tips/img8.jpg',
    '/assets/blog/travel-tips/img9.jpg'
  ];

  customOptions: OwlOptions = {
    dots: true,
    loop: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 2
      },
      1300: {
        items: 3
      },
      1600: {
        items: 3
      }
    }
  };
  constructor() {}

  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
