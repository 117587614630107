<div class="container-fluid m-0 p-0">
  <div class="blog-header-container">
    <h1>ბლოგი</h1>
  </div>
</div>

<div class="container blog-item custom">
  <h1>
    <span class="light-header">პასუხები ხშირად დასმულ შეკითხვებზე:</span><br />როგორ შეგიძლიათ ჩაანაცვლოთ ავტომობილი
    მარტივად?
  </h1>
  <h6>ივნისი 2024</h6>
  <br />

  <img class="preview-image" src="/assets/blog/swap-question/img1.jpg" alt="" />

  <p>
    რამდენიმე დღის წინ მომხმარებლებისთვის ახალი პროდუქტი გავააქტიურეთ, რომლის ფარგლებშიც ძველი ავტომობილის ახლით
    ჩანაცვლების გამარტივებას დაგპირდით. მზარდი ინტერესის გათვალისწინებით თავი მოვუყარეთ შეკითხვებს, რომელზე პასუხებიც
    დაგარწმუნებთ, რომ ე.წ ავტო თრეიდ-ინი კარგი იდეაა.
  </p>

  <p><span class="round-background background-purple-blue">რას გულისხმობს პროდუქტი?</span></p>

  <p>
    კიდევ უფრო ნათლად რომ წარმოვიდგინოთ პროცესი, მსგავსი საოპერაციო მოდელი ხშირად გვხვდება ტექნიკის მაღაზიებში, სადაც
    თქვენ მიერ წლების მანძილზე გამოყენებული მობილური ტელეფონის ჩაბარებას და ახლის შემცირებულ ფასად ყიდვას გთავაზობენ.
    ანალოგიური პრინციპი მოქმედებს ავტომობილებზეც, რომლის დასურათების, გაყიდვების ვებ-გვერდზე ან ფიზიკურ პუნქტში
    განთავსების, გაყიდვის და შემდეგ ახლის ყიდვის პროცესს ერთი ფანჯრის სისტემით ვხურავთ.
  </p>

  <p><span class="round-background background-cyan">ნაბიჯ-ნაბიჯ, როგორია პროცესი?</span></p>

  <p>
    მომხმარებელი მოდის თიბისი ლიზინგის ფილიალში თავის ძველი ავტომობილით, რომელსაც შევაფასებთ. აღნიშნული ღირებულების
    თანამონაწილებად ჩათვლას შევთავაზებთ და ახლის ღირებულებას გამოვაკლებთ. ახალი ავტომობილის ღირებულების დარჩენილ ნაწილს
    კი ლიზინგის ხელშეკრულების პრინციპით, შესაბამის პერიოდზე გადავანაწილებთ.
  </p>

  <p><span class="round-background background-middle-green">რა საბუთები დამჭირდება?</span></p>

  <p>
    რა თქმა უნდა, მართვის და ტექ.დათვალიერების მოწმობები.
  </p>

  <p><span class="round-background background-dark-yellow">რა პირობებს უნდა აკმაყოფილებდეს ჩემი ავტომობილი?</span></p>

  <p>
    აქტივის ღირებულება MIN 2,000$ - MAX 60,000$ (ექვივალენტი ლარში) ჩასაბარებელი ავტომობილის ასაკი არ უნდა აღემატებოდეს
    15 წელს; მაქსიმალური გარბენი: 300,000 კმ;
  </p>

  <p>
    <span class="round-background background-pink">შემიძლია აღნიშნული პირობით რამდენიმე ავტომობილზე ვისარგებლო?</span>
  </p>

  <p>
    ფიზიკური პირების შემთხვევაში აღნიშნული პროდუქტი მაქსიმუმ 2 ავტომობილზე ვრცელდება.
  </p>

  <p><span class="round-background background-light-blue">რატომ არ უნდა ვისარგებლო ახალი პროდუქტით?</span></p>

  <p>
    ჩვენც ეს კითხვა გვაქვს, რადგან გვჯერა, რომ საკმაოდ მოქნილი და მომხმარებლის ინტერესებს მორგებული პროდუქტი შევქმენით,
    თუმცა თუ რაიმე შეკითხვა პასუხგაუცემელი დარჩა, ჩვენი წარმომადგენლები ამომწურავად დაფარავენ. დაგვიკავშირდი ნებისმიერ
    დღეს, ან გვეწვიე ფილიალში: აღმაშენებლის ხეივანი, მე-10 კილომეტრი.
  </p>
</div>

<div class="container-fluid m-0 p-0">
  <img class="full-width-image" src="/assets/blog/swap-question/img2.jpg" alt="" />
</div>
