import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-custom-carousel',
  templateUrl: './custom-carousel.component.html',
  styleUrls: ['./custom-carousel.component.scss']
})
export class CustomCarouselComponent implements OnInit {
  @Input() images: string[] = [];

  @Input() customOptions: OwlOptions | undefined;

  options: OwlOptions = {
    center: true,
    loop: false,
    margin: 10,
    items: 6,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      1000: {
        items: 5
      }
    }
  };

  constructor() {}

  ngOnInit() {
    if (this.customOptions) {
      this.options = {
        ...this.options,
        ...this.customOptions
      };
    }
  }
}
