import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-verify-confirmation',
  templateUrl: './verify-confirmation.component.html',
  styleUrls: ['./verify-confirmation.component.scss']
})
export class VerifyConfirmationComponent {
  @Input() name: any;

  imgSrc: string = '../../../../assets/icons/circle_right_outline.png';

  constructor(public activeModal: NgbActiveModal) {}
}
