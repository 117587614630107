import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { SsoVerifyHandleComponent } from '@app/offers/sso-verify-handle/sso-verify-handle.component';
import { SsoVerifyComponent } from '@app/offers/sso-verify/sso-verify.component';
import { VerifyCustomer2Component } from '@app/offers/verify-customer2/verify-customer2.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { OfferPreviewComponent } from './offer-item/offer-preview.component';
import { OffersPageComponent } from './offers-page/offers-page.component';
import { OffersRoutingModule } from './offers-routing.module';
import { VerifyConfirmationComponent } from './verify-customer/verify-confirmation/verify-confirmation.component';
import { VerifyCustomerComponent } from './verify-customer/verify-customer.component';

@NgModule({
  imports: [CommonModule, OffersRoutingModule, ReactiveFormsModule, NgbModalModule, TranslateModule],
  declarations: [
    OffersPageComponent,
    OfferPreviewComponent,
    VerifyCustomerComponent,
    VerifyCustomer2Component,
    VerifyConfirmationComponent,
    SsoVerifyComponent,
    SsoVerifyHandleComponent
  ]
})
export class OffersModule {}
