import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-offer-preview',
  templateUrl: './offer-preview.component.html',
  styleUrls: ['../offers.scss']
})
export class OfferPreviewComponent implements OnInit {
  @Input()
  offerImg: string;
  @Input()
  brand: string;
  @Input()
  detail: string;

  @Input()
  offerDetailDocument: string;

  constructor() {}

  ngOnInit() {}
}
