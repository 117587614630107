import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-page',
  templateUrl: './blog-page.component.html',
  styleUrls: ['../blog.component.scss']
})
export class BlogPageComponent implements OnInit {
  isBlogSelected = true;

  constructor() {}

  ngOnInit() {}

  showTextBlog() {
    this.isBlogSelected = true;
  }

  showVideoBlog() {
    this.isBlogSelected = false;
  }
}
