import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-item1',
  templateUrl: './project-item1.component.html',
  styleUrls: ['../../blog.component.scss']
})
export class ProjectItem1Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
