<div class="container">
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'პასუხები ხშირად დასმულ შეკითხვებზე: როგორ შეგიძლიათ ჩაანაცვლოთ ავტომობილი მარტივად?'"
        [previewImage]="'../../assets/blog/swap-question/preview.jpg'"
        [previewText]="''"
        [postDate]="'ივნისი 2024'"
        [link]="'blog-swap-question'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'ჩაანაცვლე ძველი ავტომობილი ახლით - მარტივად'"
        [previewImage]="'../../assets/blog/swap-car/preview.jpg'"
        [previewText]="''"
        [postDate]="'მაისი 2024'"
        [link]="'blog-swap-car'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'ბაქსვუდის სკოლა \n Ad Vitam Paramus - ვამზადებთ ცხოვრებისთვის'"
        [previewImage]="'../../assets/blog/buckswood-school/preview.jpg'"
        [previewText]="''"
        [postDate]="'დეკემბერი 2023'"
        [link]="'blog-buckswood'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'Coffee Lab ანუ ყავა ხილია?'"
        [previewImage]="'../../assets/blog/coffee-lab/preview.jpg'"
        [previewText]="''"
        [postDate]="'დეკემბერი 2023'"
        [link]="'blog-coffee-lab-shop'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'თიბისი ლიზინგის CSR მიმართულება \n Vol.2'"
        [previewImage]="'../../assets/blog/green-leasing/preview.jpg'"
        [previewText]="''"
        [postDate]="'დეკემბერი 2023'"
        [link]="'blog-green-leasing'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'ფოტოდან სურნელს იგრძნობ ანუ გაიცანი კაფე „დაფნა“'"
        [previewImage]="'../../assets/blog/dafna/preview.jpg'"
        [previewText]="''"
        [postDate]="'დეკემბერი 2023'"
        [link]="'blog-dafna'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'ქართული თხილის ევროპული პერსპექტივები \n Nuts Incorporated'"
        [previewImage]="'../../assets/blog/nuts/preview.jpg'"
        [previewText]="''"
        [postDate]="'დეკემბერი 2023'"
        [link]="'blog-nuts'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'თიბისი ლიზინგის სამუშაო გარემო \n Vol.1'"
        [previewImage]="'../../assets/blog/workspace/preview.jpg'"
        [previewText]="''"
        [postDate]="'ნოემბერი 2023'"
        [link]="'blog-workspace'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'„პრო ბონო“ - ანუ საზოგადოებისთვის სასარგებლო საქმიანობა'"
        [previewImage]="'../../assets/blog/pro-bono/preview.jpg'"
        [previewText]="''"
        [postDate]="'სექტემბერი 2023'"
        [link]="'blog-pro-bono'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'ლექსუს იზი - ლექსუსის მართვის ახალი გზა'"
        [previewImage]="'../../assets/blog/lexus-easy/preview.jpg'"
        [previewText]="''"
        [postDate]="'ივლისი 2023'"
        [link]="'blog-lexus-easy'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'„დღეს ტენე პლასტმასა?“ – კითხვა რომელიც თანამშრომლების ყოველდღიურობის ნაწილი გახდა'"
        [previewImage]="'../../assets/blog/recycling/preview.jpg'"
        [previewText]="''"
        [postDate]="'ივლისი 2023'"
        [link]="'blog-recycling'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'როგორ იმოგზაურა ეკამ 30-ზე მეტ ქვეყანაში ბიუჯეტურად?'"
        [previewImage]="'../../assets/blog/travel-tips/preview.jpg'"
        [previewText]="''"
        [postDate]="'აპრილი 2023'"
        [link]="'blog-travel-tips'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'თიბისი ლიზინგი - შენი საუკეთესო სამომხმარებლო გამოცდილებისთვის'"
        [previewImage]="'../../assets/blog/user-experience/preview.jpg'"
        [previewText]="''"
        [postDate]="'აპრილი 2023'"
        [link]="'blog-user-experience'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'რატომ უნდა შეიძინო ავტომობილი ამერიკის აუქციონზე?'"
        [previewImage]="'../../assets/blog/auction/preview.jpg'"
        [previewText]="''"
        [postDate]="'თებერვალი 2023'"
        [link]="'blog-auctions'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'VELI.STORE შენ რას ელი, ამ ახალ წელს?'"
        [previewImage]="'../../assets/blog/veli/preview.jpg'"
        [previewText]="''"
        [postDate]="'დეკემბერი 2022'"
        [link]="'blog-veli'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'აქ. ახლა თიბისი ლიზინგი ინფრასტრუქტურის სექტორის მხარდასაჭერად'"
        [previewImage]="'../../assets/blog/for-infrastructure/preview.jpg'"
        [previewText]="''"
        [postDate]="'მაისი 2022'"
        [link]="'blog-for-infrastructure'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'აქ. ახლა პირველად ქართულ ბაზარზე კომერციული უძრავი ქონების ლიზინგი'"
        [previewImage]="'../../assets/blog/estate-leasing/preview.jpg'"
        [previewText]="''"
        [postDate]="'დეკემბერი 2021'"
        [link]="'blog-estate-leasing'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'როგორ შეგიძლია მართო ტოიოტა მარტივად?'"
        [previewImage]="'../../assets/blog/toyota-easy/preview.jpg'"
        [previewText]="''"
        [postDate]="'აგვისტო 2021'"
        [link]="'blog-toyota-easy'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'პორდუქტი ქართულ ბაზარზე - ლიზინგი სკუტერებისთვის'"
        [previewImage]="'../../assets/blog/scooters/preview.jpg'"
        [previewText]="''"
        [postDate]="'ივნისი 2021'"
        [link]="'blog-scooters'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'ხელმისაწვდომი ხარისხი, მეორადი ავტომობილების პროდუქტი'"
        [previewImage]="'../../assets/blog/used/preview.jpg'"
        [previewText]="''"
        [postDate]="'მაისი 2021'"
        [link]="'blog-used'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'მზის პანელების პრიორიტეტულობა ბიზნესისა და ქვეყნისთვის'"
        [previewImage]="'../../assets/blog/solar/preview.jpg'"
        [previewText]="''"
        [postDate]="'თებერვალი 2021'"
        [link]="'blog-solar'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'როგორ შეგვიძლია ლიზინგის გამოყენება აგროსფეროში? - მომენტები აგროექსპორტის ფორუმიდან'"
        [previewImage]="'../../assets/blog/bforum/preview.jpg'"
        [previewText]="''"
        [postDate]="'დეკემბერი 2020'"
        [link]="'blog-bforum'"
      ></app-blog-item-preview>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-blog-item-preview
        [title]="'რა არის ლიზინგი'"
        [previewImage]="'../../assets/blog/leasing/preview.jpg'"
        [previewText]="
          'ანტიკური დრო, რომელიც ხშირად შორეულ წარსულად გვეჩვენება, ხანდახან ძალიან ახლობელი აღმოჩნდება ხოლმე. როცა ვუყურებთ ' +
          'ეგვიპტის უნიკალურ არქიტექტურულ ნაგებობებს ...'
        "
        [postDate]="'ნოემბერი 2019'"
        [link]="'blog-leasing'"
      ></app-blog-item-preview>
    </div>
  </div>
</div>
