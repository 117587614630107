<div class="product-header">
  <div class="product-text">
    <h2 translate>ინფორმაცია პერსონალური მონაცემების დამუშავების შესახებ</h2>

    <p translate>
      გთხოვთ, გახსნათ თითოეული განაცხადის ფანჯარა და ყურადღებით გაეცნოთ მოცემულ ინფორმაციას. <br />
      დასტურის შემთხვევაში მონიშნოთ შესაბამისი ველი და განაგრძოთ ოპერაცია.
    </p>
  </div>
</div>
<div class="verify-container">
  <div class="buttons-wrapper">
    <button class="info" (click)="openModal(judicialUsage)" translate>იურიდიული პირი</button>
    <button class="info" (click)="openModal(physicalUsage)" translate>ფიზიკური პირი</button>
  </div>

  <h3 [ngClass]="{ 'must-read': !termsRead }" translate>
    გთხოვთ გაეცნოთ პოლიტიკას
  </h3>
  <button class="info" (click)="openModal(dataUsage)" translate>პერსონალური მონაცემების დამუშავება</button>

  <form [formGroup]="fg">
    <div class="row">
      <div class="col-12">
        <div class="sn-container">
          <div class="row">
            <div class="col-12 checkbox-wrapper">
              <input type="checkbox" formControlName="ch1" />
              <p [ngClass]="{ disabled: !termsRead }" translate>წავიკითხე და ვეთანხმები - თანხმობის ტექსტს</p>
            </div>
            <div class="col-12 checkbox-wrapper">
              <input type="checkbox" formControlName="ch2" />
              <p [ngClass]="{ disabled: !termsRead }" translate>
                წავიკითხე და ვეთანხმები -
                <a [routerLink]="['/about/privacy']" target="_blank" translate>კონფიდენციალურობის პოლიტიკას</a>
              </p>
            </div>
            <div class="col-12"></div>
            <h3 style="text-align: left; margin-bottom: 16px;" translate>გაიარეთ იდენტიფიკაცია</h3>
            <div class="col-12">
              <input
                class="main-input"
                type="text"
                [placeholder]="'პ.ნ. ან საიდენტიფიკაციო' | translate"
                formControlName="sn"
                maxlength="11"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div *ngIf="f.sn.touched && f.sn.invalid" class="sn-error">
          <div *ngIf="f.sn.errors.required" translate>შეიყვანეთ პ.ნ. ან საიდენტიფიკაციო</div>
          <div *ngIf="f.sn.errors.minlength" translate>პ.ნ. / საიდენტიფიკაციო შედგება 9-11 ციფრისგან</div>
          <div *ngIf="f.sn.errors.maxlength" translate>პ.ნ. / საიდენტიფიკაციო შედგება 9-11 ციფრისგან</div>
          <div *ngIf="f.sn.errors.pattern" translate>პ.ნ. / საიდენტიფიკაციო ნომერი შედგება მხოლოდ ციფრებისგან</div>
        </div>
      </div>
      <div class="col-12">
        <div class="button-verify-container">
          <button class="info" [disabled]="!fg.valid || !termsRead" (click)="verify()" translate>ვეთანხმები</button>
        </div>
      </div>
    </div>
  </form>
  <div *ngIf="submitted">
    <div class="row">
      <div class="col-12">
        <div class="iban-number">
          GE26TB7913136020100017
        </div>
      </div>
      <div class="col-12">
        <p class="iban-banner">
          <br />
          საკრედიტო ისტორიის გადასამოწმებლად საჭიროა 0.01 ლარის ან სხვა სიმბოლური თანხის გადარიცხვა მითითებულ ანგარიშის
          ნომერზე. ლიზინგის სისტემა მოახდენს მომხმარებლის მიერ განაცხადში და საგადახდო დავალებაში მითითებული პირადი
          ინფორმაციის შედარებას და მონაცემების თანხვედრის შემთხვევაში, ინფორმაცია დასამუშავებლად კრედიტ ინფოში
          გაიგზავნება.
        </p>
      </div>
    </div>
  </div>
</div>

<ng-template #dataUsage let-modal>
  <div class="modal-header">
    <h4 class="modal-title" translate>პერსონალური მონაცემების დამუშავება</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body" id="personal-data" (scroll)="onScroll($event)">
    <p class="verify-text" *ngIf="i18nService.language !== 'EN'">
      მე, (შემდგომში „კლიენტი“) წინამდებარე დოკუმენტზე თანხმობის გამოხატვით ვადასტურებ, რომ სს „თიბისი ლიზინგი“
      (შემდგომში - სალიზინგო კომპანია) ჩემი განცხადების განხილვისა და/ან მომსახურების გაწევის მიზნით, უფლებამოსილია
      მიიღოს და დაამუშავოს ჩემი პერსონალური მონაცემები, შემდგომში საქმიანი ურთიერთობის დამყარებისა და სალიზინგო
      მომსახურების გაწევის მიზნით. ამასთანავე ჩემთვის ცნობილია, რომ მონაცემებს, მათ შორის პერსონალური მონაცემებს
      სალიზინგო კომპანია ინახავს კანონმდებლობით დადგენილი ვადით, ხოლო ჩემს მიერ მოწოდებული ყველა პერსონალური მონაცემი
      არის უტყუარი და ზუსტი.
      <br /><br />
      გაცნობიერებული მაქვს საქართველოს კანომდებლობით გათვალისწინებული უფლებები, რომ უფლება მაქვს მოვითხოვო ჩემი
      მონაცემების დამუშავების შესახებ ინფორმაცია, ასევე მონაცემთა გაცნობა და ასლის მიღება, ისევე როგორც მონაცემების
      გასწორება, განახლება, შევსება, დამუშავების შეწყვეტა, წაშლა, განადგურება, გადატანა (თუ ეს ტექნიკურად შესაძლებელია),
      დაბლოკვა, გასაჩივრება ან/და თანხმობის უკან გამოხმობა, შესატყვისი მოთხოვნის გამოგზავნით ელექტრონული ფოსტის
      მეშვეობით მისამართზე <a href="mailto: info&#64;tbcleasing.ge">info&#64;tbcleasing.ge</a>. მოთხოვნის მიღების შემდეგ
      სს „თიბისი ლიზინგი“ შესაბამის ვადებში (3-იდან 10 სამუშაო დღემდე) მოახდენს რეაგირებას განცხადებაზე და საჭიროების
      შემთვევაში დააკმაყოფილებს მოთხოვნას, ხოლო იმ შემთვევაში, თუ სალიზინგო კომპანია ჩათვლის მოთხვნას არალეგიტიმურად მე
      (მონაცემთა სუბიექტს) გამომეგზავნება დასაბუთებული უარი განცხადებაში მითითებულ ელ. ფოსტაზე. <br /><br />
      თანხობის უკან გამოხმობის შემთხვევაში, ჩემი მოთხოვნის შესაბამისად, მონაცემთა დამუშავება შეწყდება ან/და დამუშავებული
      მონაცემები წაიშალება ან/და განადგურდება მოთხოვნიდან არაუგვიანეს 10 სამუშაო დღისა, თუ მონაცემთა დამუშავების სხვა
      საფუძველი არ არსებობს.
      <br /><br />
      <i>
        * წინამდებარე დოკუმენტზე აქტიური მოქმედებით თანხმობის გამოხატვისას, თქვენ ასევე ეთანხმებით ქვემოთ მოცემულ
        ვებ-გვერდზე განთავსებულ კონფიდენციალურობის პოლიტიკას, რა მიზნითაც გთხოვთ გასხნათ აღნიშნული დოკუმენტები გაეცნოთ
        მათ და მხოლოდ მათი გაცნობის შემდეგ მონიშნოთ კონკრეტულ ღილაკზე დაკლიკებით ე.წ. Check Box-(ებ)ის საშუალებით -
        „წავიკითხე და ვეთანხნები“. წინააღმდეგ შემთხვევაში, თქვენ ვერ შეძლებთ სს „თიბისი ლიზინგის“ მომსახურებით
        სარგებლობას და თქვენს მიერ შევსებული ინფომაცია ავტომატურად წაიშლება.
      </i>
    </p>
    <p class="verify-text" *ngIf="i18nService.language === 'EN'">
      By indicating my consent to this document, I, hereinafter referred to as „the Client,“ hereby affirm that JSC „TBS
      Leasing,“ hereinafter referred to as „the Leasing Company,“ is duly authorized to collect and process my personal
      data for the purposes of evaluating my application and/or providing services, as well as for establishing ongoing
      business relations and facilitating leasing arrangements to deliver said services.
      <br /><br />

      Furthermore, I acknowledge that the data, including personal data, will be retained by the Leasing Company for a
      duration mandated by applicable law. I also affirm that all personal data I have provided is accurate and
      reliable.
      <br /><br />

      I am aware of my rights under Georgian legislation, which grants me the authority to request information regarding
      the processing of my data. This includes the right to access my data and receive a copy, as well as to rectify,
      update, supplement, cease processing, delete, destroy, transfer (when technically feasible), block, appeal, or
      withdraw consent by submitting a corresponding request via email to
      <a href="mailto: info&#64;tbcleasing.ge">info&#64;tbcleasing.ge</a>. Upon receipt of such a request, JSC TBC
      Leasing will respond within the established timeframe of three to ten working days. If the request be deemed
      illegitimate, I (the data subject) will receive a reasoned refusal sent to the email address specified in the
      application. <br /><br />

      In the event of a withdrawal of consent, the processing of my data will be halted and/or the data will be deleted
      and/or destroyed no later than ten working days following my request, unless there is a legitimate reason to
      continue processing the data.
      <br /><br />
      <i>
        *By actively indicating my consent to this document, I also agree to the privacy policy available on the website
        referenced below. I am encouraged to review these documents thoroughly. Only after such review should I mark the
        appropriate checkbox labeled „I have read and agree.“ Failure to do so will result in my inability to utilize
        the services provided by JSC „TBS Leasing,“ and the information I have submitted will be automatically deleted.
      </i>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Close click')" translate>დახურვა</button>
  </div>
</ng-template>

<ng-template #physicalUsage let-modal>
  <div class="modal-header">
    <h4 class="modal-title" translate>ფიზიკური პირი</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="verify-text" *ngIf="i18nService.language !== 'EN'">
      <b>თანხმობა მონაცემების დამუშავების თაობაზე</b>
      <br />
      <br />
      მე, (სახელი, გვარი) გამოვხატავ თანხმობას და უფლებას ვაძლევ სს „თიბისი ლიზინგს“ (ს/ნ205016560), საქართველოს
      კანონმდებლობით დადგენილი წესით და პირობებით, დაამუშაოს წინამდებარე განაცხადში, კრედიტორთან დაცული, ასევე, ჩემ
      შესახებ საკრედიტო ბიუროებში დაცული და მათ მიერ მოწოდებული ინფორმაცია ჩემი, როგორც მომავალი ლიზინგის
      მიმღების/თავდების/უზრუნველყოფის მიმწოდებლის გადახდისუნარიანობის ანალიზის მიზნით.
      <br />
      <br />
      გაცნობიერებული მაქვს საქართველოს კანონმდებლობით გათვალისწინებული უფლებები, რომ მონაცემთა სუბიექტის/მომხმარებლის
      მოთხოვნის შემთხვევაში, მონაცემთა დამმუშავებელი ვალდებულია გაასწოროს, განაახლოს, დაამატოს, დაბლოკოს, წაშალოს ან
      გაანადგუროს მონაცემები, თუ ისინი არასრულია, არაზუსტია, არ არის განახლებული, ან თუ მათი შეგროვება და დამუშავება
      განხორციელდა კანონის საწინააღმდეგოდ.
      <br />
      <br />
      აღნიშნული თანხმობა მოქმედია ხელმოწერიდან/დადასტურებიდან 30 სამუშაო დღის განმავლობაში.
      <br />
      <br />
      <b>მომხმარებლის თანხმობა მონაცემების საკრედიტო საინფორმაციო ბიუროსთვის მიწოდების შესახებ</b>
      <br />
      <br />
      გამოვხატავ თანხმობას და უფლებას ვაძლევ სს „თიბისი ლიზინგს“ (ს/ნ 205016560, მის: ქ. თბილისი, ი. ჭავჭავაძის გამზ.
      #76 „მ“), საქართველოს კანონმდებლობით დადგენილი წესით და პირობებით დაამუშაოს ჩემს შესახებ საკრედიტო/არასაკრედიტო და
      სხვა რელევანტური ინფორმაცია, რომელიც მიეწოდება საკრედიტო საინფორმაციო ბიუროს. ხსენებული ინფორმაცია მუშავდება
      მომხმარებლის გადახდისუნარიანობის ანალიზის მიზნისთვის და იგი კანონმდებლობით დადგენილი წესით ხელმისაწვდომი იქნება
      საკრედიტო საინფორმაციო ბიუროში ჩართული სესხის გამცემი ორგანიზაციებისთვის და ინფორმაციის მიმღები/მიმწოდებელი
      პირებისათვის.
    </p>
    <p class="verify-text" *ngIf="i18nService.language === 'EN'">
      <b>Consent to Data Processing</b>
      <br />
      <br />
      I, (Name, Surname), hereby express my consent and authorize JSC „TBS Leasing“ (S/N 205016560) express my consent
      and authorize JSC „TBS Leasing“ (Identification Number 205016560) to process the information contained in this
      application, as well as information stored by creditor and any other relevant information about me held by credit
      bureaus, in accordance with the regulations and conditions established by the legislation of Georgia. This
      processing is for the purpose of assessing the creditworthiness of the prospective lessee, guarantor, or security
      provider.
      <br />
      <br />
      I acknowledge my rights under Georgian legislation, which stipulates that upon request from the data subject or
      user, the data processor is obliged to correct, update, supplement, block, delete, or destroy any data that is
      incomplete, inaccurate, outdated, or collected and processed unlawfully.
      <br />
      <br />
      This consent is valid for a period of 30 working days from the date of signature or confirmation.
      <br />
      <br />
      <b>User Consent to Provide Data to Credit Bureaus</b>
      <br />
      <br />
      I hereby express my consent and authorize JSC „TBS Leasing“ (S/N 205016560, Address: Tbilisi, I. Chavchavadze Ave.
      #76 „M“) to process credit, non-credit, and other relevant information about me in accordance with the rules and
      conditions established by Georgian legislation. This information will be provided to the credit information bureau
      and is processed for the purpose of analyzing my creditworthiness. It will be accessible to lending organizations
      participating in the credit information bureau and to individuals receiving or providing information in accordance
      with applicable laws.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Close click')" translate>დახურვა</button>
  </div>
</ng-template>

<ng-template #judicialUsage let-modal>
  <div class="modal-header">
    <h4 class="modal-title" translate>იურიდიული პირი</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="verify-text" *ngIf="i18nService.language !== 'EN'">
      <b>თანხმობა მონაცემების დამუშავების თაობაზე</b>
      <br />
      <br />
      მე, (სახელი, გვარი) (კომპანიის დასახელება და ს/ნ ----------) წარმომადგენელი გამოვხატავ თანხმობას და უფლებას ვაძლევ
      სს „თიბისი ლიზინგს“ (ს/ნ 205016560), საქართველოს კანონმდებლობით დადგენილი წესით და პირობებით, დაამუშაოს
      წინამდებარე განაცხადში, კრედიტორთან დაცული, ასევე, ჩემ შესახებ საკრედიტო ბიუროებში დაცული და მათ მიერ მოწოდებული
      ინფორმაცია ჩემი, როგორც მომავალი ლიზინგის მიმღების/თავდების/უზრუნველყოფის მიმწოდებლის გადახდისუნარიანობის ანალიზის
      მიზნით.
      <br />
      <br />
      გაცნობიერებული მაქვს საქართველოს კანონმდებლობით გათვალისწინებული უფლებები, რომ მონაცემთა სუბიექტის/მომხმარებლის
      მოთხოვნის შემთხვევაში, მონაცემთა დამმუშავებელი ვალდებულია გაასწოროს, განაახლოს, დაამატოს, დაბლოკოს, წაშალოს ან
      გაანადგუროს მონაცემები, თუ ისინი არასრულია, არაზუსტია, არ არის განახლებული, ან თუ მათი შეგროვება და დამუშავება
      განხორციელდა კანონის საწინააღმდეგოდ.
      <br />
      <br />
      აღნიშნული თანხმობა მოქმედია ხელმოწერიდან/დადასტურებიდან 60 სამუშაო დღის განმავლობაში.
      <br />
      <br />
      <b>მომხმარებლის თანხმობა მონაცემების საკრედიტო საინფორმაციო ბიუროსთვის მიწოდების შესახებ</b>
      <br />
      <br />
      გამოვხატავ თანხმობას და უფლებას ვაძლევ სს „თიბისი ლიზინგს“ (ს/ნ205016560, მის: ქ. თბილისი, ი. ჭავჭავაძის გამზ. #76
      „მ’), საქართველოს კანონმდებლობით დადგენილი წესით და პირობებით დაამუშაოს ჩემს შესახებ საკრედიტო/არასაკრედიტო და
      სხვა რელევანტური ინფორმაცია, რომელიც მიეწოდება საკრედიტო საინფორმაციო ბიუროს. ხსენებული ინფორმაცია მუშავდება
      მომხმარებლის გადახდისუნარიანობის ანალიზის მიზნისთვის და იგი კანონმდებლობით დადგენილი წესით ხელმისაწვდომი იქნება
      საკრედიტო საინფორმაციო ბიუროში ჩართული სესხის გამცემი ორგანიზაციებისთვის და ინფორმაციის მიმღები/მიმწოდებელი
      პირებისათვის.
    </p>
    <p class="verify-text" *ngIf="i18nService.language === 'EN'">
      <b>Consent to Data Processing</b>
      <br />
      <br />
      I, (Name, Surname), acting as a representative of (Company Name, Company Number ----------), hereby express my
      consent and authorize JSC „TBS Leasing“ (Identification Number 205016560) to process the information contained in
      this application, as well as information stored by creditor and any other relevant information about me held by
      credit bureaus, in accordance with the regulations and conditions established by the legislation of Georgia. This
      processing is conducted for the purpose of assessing my creditworthiness as a prospective lessee, guarantor, or
      security provider.
      <br />
      <br />
      I acknowledge my rights under Georgian legislation, which stipulates that upon request from the data subject or
      user, the data processor is obliged to correct, update, supplement, block, delete, or destroy any data that is
      incomplete, inaccurate, outdated, or collected and processed unlawfully.
      <br />
      <br />
      This consent is valid for a period of 60 working days following the date of signature or confirmation.
      <br />
      <br />
      <b>User's Consent to Provide Data to Credit Bureaus</b>
      <br />
      <br />
      I hereby express my consent and authorize JSC „TBS Leasing“ (Tax Identification Number 205016560, Address:
      Tbilisi, I. Chavchavadze Ave. #76 „M“ ) to process credit, non-credit, and other relevant information about me in
      accordance with the regulations and conditions established by Georgian legislation. This information will be
      provided to the credit information bureau and is intended for the purpose of analyzing my creditworthiness. It
      will be accessible to lending organizations participating in the credit information bureau and to individuals who
      receive or provide information in accordance with applicable laws.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Close click')" translate>დახურვა</button>
  </div>
</ng-template>
