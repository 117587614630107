<div class="container-fluid m-0 p-0">
  <div class="blog-header-container">
    <h1>ბლოგი</h1>
  </div>
</div>
<div class="container blog-item custom">
  <h1>
    „პრო ბონო“ - ანუ საზოგადოებისთვის სასარგებლო საქმიანობა
  </h1>
  <h6>სექტემბერი 2023</h6>
  <br />

  <p>
    ადამიანს უნდა და სჭირდება იყოს საზოგადოების სრულფასოვანი წევრი, რაც ხშირად სოციალური ცხოვრებისა თუ პასუხისმგებლობის
    ფარგლებს სცდება და საკუთარ წილ „სასარგებლო საქმიანობაში“ ითარგმნება. რა შეედრება კმაყოფილების გრძნობას, რომელსაც
    სხვებზე ზრუნვისას განვიცდით? იქნება ეს მოხუცის დახმარება გზის გადაკვეთისას, ორსულისთვის ადგილის დათმობა
    საზოგადოებრივ ტრანსპორტში თუ ისეთი მარტივი, მაგრამ მნიშვნელოვანი აქტი, როგორიც უცხოსთვის კარის გაღებაა? სხვათა
    შორის, წლების წინ აშშ-ში მცირე ბიზნესის მხარდასაჭერი საინტერესო სარეკლამო ბანერები გამოჩნდა, წარწერებით
  </p>

  <p>
    <b class="blue" style="font-size: 26px;">
      „როცა ბაზარში ფერმერისგან ყიდულობ, მისი შვილის ბედნიერების ცეკვას, საყვარელი გუნდის მაისურის შეძენასა და სწავლის
      გადასახადს აფინანსებ.“
    </b>
  </p>

  <img src="../../../../assets/blog/pro-bono/img1.jpg" alt="" />

  <p>
    თანამედროვე ცხოვრების რიტმი ისეთ გამოწვევებს გვთავაზობს, რომ რთულია დრო სამსახურს, ოჯახს, მეგობრებს,
    თვითგანვითარებას და სოციალურ აქტიურობას თანაბრად გადაუნაწილო. დღის ბოლოს ჩნდება კითხვა „აბა მე დღეს ვის რა ვარგე?!“
    და მთელი სიმძაფრით გვახსენებს იმას, რომ „მეტი შეგიძლია, მაგრამ რესურსი არ გყოფნის“.
  </p>
</div>

<div class="container-fluid m-0 p-0">
  <div class="full-color background-blue">
    <div class="container">
      <p class="bold-text capitalized-text">
        პრო-ბონო, რაც ლათინურიდან „ საზოგადოებისთვის სასარგებლო საქმიანობად“ ითარგმნება, სწორედ ამ პრობლემას აგვარებს.
        შენ შეგიძლია საკუთარი უნარებით, პროფესიონალიზმით, ინტელექტუალური შესაძლებლობებით დაეხმარო მას, ვისაც ეს ასე
        სჭირდება.
      </p>
    </div>
  </div>
</div>

<div class="container blog-item custom">
  <p>
    სტარტაპი, მცირე ბიზნესი თუ ინდივიდუალური მეწარმე, განვითარების გარკვეულ საფეხურზე ექსპერტულ მიდგომებს საჭიროებს, რაც
    რამდენი ადამიანისგან შემდგარ გუნდში დიდი გამოწვევაა. შეზღუდული მატერიალური რესურსებიდან გამომდინარე, სხვადასხვა
    დარგის სპეციალისტისთვის მიმართვა ნამდვილი „ფუფუნებაა“, თუმცა არა „პრო-ბონო“ ჩართულობით. ინიციატივის გარშემო ისედაც
    არიან გაერთიანებული ადამიანები, რომელთაც აქვთ სურვილი გაგიწიონ პროფესიონალური დახმარება.
  </p>

  <p>
    და რადგან კომპანიებისათვის პრო ბონო სერვისები მათი სოციალური პასუხისმგებლობის განხორციელების ერთ-ერთ ფორმას, კერძოდ
    კი, თანამშრომელთა მოხალისეობის (კორპორაციული მოხალისეობის) ერთ-ერთ ფორმას წარმოადგენს, CSRDG მედიაციით ჩვენც
    აქტიურად ჩავერთეთ ინიციატივაში.
  </p>

  <p>
    თანამშრომლებს შორის ინფორმაციის გავრცელებიდან რამდენიმე საათში უკუკავშირი მივიღეთ, ამ ეტაპზე შეთავაზებულ ორ პროექტში
    ჩართვის სურვილი 60%-მა გამოთქვა, რის შემდეგაც სოციალურ საწარმო „პოტერიასთან“, ისევე როგორც სათემო კავშირ
    „ანა-ბანასთან“ შეხვედრები ჩავნიშნეთ. პირველ ეტაპზე, მოვისმინეთ საჭიროებები, განვსაზღვრეთ სამოქმედო გეგმა და საქმეს
    შევუდექით.
  </p>

  <img src="../../../../assets/blog/pro-bono/img2.jpg" alt="" />

  <p>
    <b class="blue" style="font-size: 26px;">
      სათემო კავშირი „ანა-ბანა“ ლაგოდეხში სამკითხველო სივრცის და ბიბლიოთეკის გაკეთებაში საჭიროებდა დახმარებას, რისთვისაც
      თიბისი ლიზინგის მასშტაბით წიგნების აგროვება დავიწყეთ, დამატებით ათეულობით წიგნი შევისყიდეთ და თანამშრომლებთან
      ერთად გადავეცით.
    </b>
  </p>

  <img src="../../../../assets/blog/pro-bono/img3.jpg" alt="" />

  <p>
    რაც შეეხება
    <b class="blue" style="font-size: 26px;">
      „პოტერიას“ - საწარმოს სოციალური მისია კანონთან კონფლიქტში მყოფი და ძალადობა გამოვლილი ქალების საზოგადოებაში
      რეინტეგრაციის ხელშეწყობა, ახალი პროფესიის ათვისებითა და შემოქმედებით სახელოსნოში დასაქმების მეშვეობით.
    </b>
  </p>

  <p>
    ამ ეტაპზე მათთვის პრიორიტეტული კორპორაციული შეთავაზებების დამზადება და გაყიდვების ზრდაა, რაშიც აქტიურად ჩავერთეთ და
    მასტერკლასში მონაწილეობის მისაღებად თავადაც ვესტუმრეთ.
  </p>

  <p>
    დღეისთვის სულ ეს იყო, სამომავლოდ ვეცდებით ამ საინტერესო ინიციატივებსა და კომპანიებზე კიდევ უფრო მეტი მოგიყვეთ.
  </p>
</div>
