<div class="wrapper">
  <div class="sign-wrapper">
    <img class="sign" src="../../../../assets/icons/circle_checkmark.png" />
  </div>
  <hr />
  <div class="action-wrapper">
    <p>თქვენი განაცხადი მიღებულია, გთხოვთ მიჰყვეთ ინსტრუქციას</p>
    <img
      [src]="imgSrc"
      (mouseover)="imgSrc = '../../../../assets/icons/circle_right.png'"
      (mouseout)="imgSrc = '../../../../assets/icons/circle_right_outline.png'"
      (click)="activeModal.close('button_close')"
    />
  </div>
</div>
