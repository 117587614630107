import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-solar',
  templateUrl: './blog-solar.component.html',
  styleUrls: ['../../blog.component.scss']
})
export class BlogSolarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
