<div class="container-fluid m-0 p-0">
  <div class="blog-header-container">
    <h1>ბლოგი</h1>
  </div>
</div>

<div class="container blog-item custom">
  <h1>
    თიბისი ლიზინგის სამუშაო გარემო<br />
    <span style="font-family: TBCX-Regular;">Vol.1</span>
  </h1>
  <h6>ნოემბერი 2023</h6>
  <br />

  <p>
    “ChatGPT, მაჩვენე როგორი უნდა იყოს სამუშაო კვირა კომპანიაში, რომელშიც თანამშრომლებზე ზრუნვაა პრიორიტეტული“ ..
  </p>

  <p>
    ChatGPT is typing..
  </p>

  <p>
    ვაკის პარკში სეირნობას თუ მიხეილ მესხის სტადიონამდე შეჰყევით, შეამჩნევდით, რომ ტერიტორიაზე სულ 2 შენობა დგას.
    ფრანგული სკოლის მიმდებარედ, სიმწვანეში - თიბისი ლიზინგის ცენტრალური ოფისია განთავსებული, სადაც მუშაობა 09:30-წუთზე
    იწყება.
  </p>

  <p>
    თითოეულმა თანამშრომელმა კარგად იცის, რომ ამ სივრცეში პრიორიტეტული არა მხოლოდ კოლეგიალობა, საქმის ეფექტური შესრულება
    და მეტრიკებთან სწორებაა, არამედ მათი მენტალური თუ ფიზიკური ჯანმრთელობაც ზრუნვის მნიშვნელოვანი საგანია.
  </p>

  <p>
    მაგალითად, ჯანსაღი ცხოვრების წესის პოპულარიზაციისთვის „ორშაბათი-ხილის დღეა!“. 2023 წელს განხორციელებული ინიციატივის
    ფარგლებში, კორპორაციული ნუტრიციონოლოგების რჩევა გავითვალისწინეთ და სეზონური ხილით ვუმასპინძლდებით ჩვენს გუნდს.
  </p>
</div>

<div class="container-fluid m-0 p-0">
  <div class="full-color background-blue" style="background-color: #428aed; padding: 0;">
    <div class="container" style="max-width: 800px;">
      <img src="../../../../assets/blog/workspace/img1.jpg" alt="" />
    </div>
  </div>
</div>

<div class="container blog-item custom">
  <p class="quote-sign dark-blue">“</p>

  <p>
    <b style="font-size: 24px; font-family: 'TBCX-BOLD'">
      მთელი დღის მანძილზე საქმეში ჩაფლულმა შესაძლოა საერთოდ არ იფიქრო საკუთარი რაციონის ვიტამინებით გამდიდრებაზე, სწორედ
      ამიტომ, გადავწყვიტეთ აღნიშნული ჩვენს თავზე აგვეღო და ორშაბათი ხილის დღედ გამოვაცხადეთ.
    </b>
  </p>

  <p class="right-align">ეკა ჩიკვილაძე, HR ბიზნეს პარტნიორი <br /><br /></p>

  <p>
    თუმცა „ვარჯიში და სწორი კვება“ ერთმანეთის გარეშე წარმოუდგენელი, სწორედ ამიტომ, უკვე ორი წელია, რაც ჩვენი კომპანიის
    ბიჭები ფიზიკურადაც იხარჯებიან და „თიბისი ლიზინგის“ სახელით მინი-ფეხბურთის ჩემპიონატში მონაწილეობენ. ინიციატივას
    სერიოზულად მოვეკიდეთ და ყოველ კვირეულმა ვარჯიშმაც შედეგი გამოიღო, 2023 წლის სეზონი მე-10 ადგილზე დავასრულეთ.
  </p>
</div>

<div class="container-fluid m-0 p-0">
  <div class="full-color background-blue" style="background-color: #428aed; padding: 0;">
    <div class="container" style="max-width: 800px;">
      <img src="../../../../assets/blog/workspace/img2.jpg" alt="" />
    </div>
  </div>
</div>

<div class="container blog-item custom">
  <p class="quote-sign dark-blue">“</p>

  <p>
    <b style="font-size: 24px; font-family: 'TBCX-BOLD'">
      საქმე ისედაც არის ის ფუნდამენტი, რაც გვაკავშირებს, მაგრამ როცა ყოველ კვირა, არაფორმალურ გარემოში, საერთო ჰობის
      გამო იკრიბებით, ვარჯიშობთ, იხარჯებით, გუნდურ გადაწყვეტილებებს იღებთ და საერთო მიზნისკენ ისწრაფვით, ის საქმეც
      გიმარტივდება, რასაც ხანდახან რუტინულად ასრულებ. მოკლედ, ფეხბურთი მართლა მეტია ვიდრე სპორტი და არაჩვეულებრივად
      ავითარებს ურთიერთობებს
    </b>
  </p>

  <p class="right-align">ნიკუშა მესხია, კორპორაციული პროექტების მენეჯერი<br /><br /></p>

  <p>
    სპორტზე საუბრისას არც ის უნდა დავივიწყოთ, რომ განსაკუთრებით საინტერესო მატჩების ყურება თანამშრომლებს ჩვენს ოფისში,
    დიდ ეკრანზე, გემრიელი სნექების თანხლებით არაერთხელ შევთავაზეთ. ორგანიზებულმა საფეხბურთო საღამოებმა წარმატებით
    ჩაიარა, ნუ, სულ მცირე იმათთვის მაინც, ვისმა საყვარელმა გუნდმაც მოიგო.
  </p>

  <p>
    რაც არ უნდა უცნაურად ჟღერდეს, კომპანიაში ჩვენი ირინა ფრუიძის თუ დავით რაფავას ექვსეულებიც გვყავს, რადგან
    ინტელექტუალური თამაში „რა, სად, როდის?“ ჩავატარეთ და მინი, თუმცა ბროლის ბუ გამორჩეულ მოაზროვნეს, ხოლო წარმატების
    სერტიფიკატები - მთელ გუნდს გადავეცით.
  </p>

  <p>
    დღეისათვის სულ ეს იყო, თუმცა მოსაყოლი იმდენად დიდია, რომ Vol.2-ით ძალიან მალე დაგიბრუნდებით.
  </p>
</div>
