import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { I18nService } from '@app/core';
import { ApiService } from '@app/shared/api.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { VerifyConfirmationComponent } from './verify-confirmation/verify-confirmation.component';

@Component({
  selector: 'app-verify-customer',
  templateUrl: './verify-customer.component.html',
  styleUrls: ['./verify-customer.component.scss']
})
export class VerifyCustomerComponent implements OnInit {
  fg: UntypedFormGroup;
  submitted = false;

  constructor(
    private fb: UntypedFormBuilder,
    private api: ApiService,
    private modalService: NgbModal,
    public i18nService: I18nService
  ) {}

  ngOnInit() {
    this.fg = this.fb.group({
      sn: [
        '',
        [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9), Validators.maxLength(11)]
      ],
      ch1: [false, [Validators.requiredTrue]],
      ch2: [false, [Validators.requiredTrue]]
    });
  }

  get f() {
    return this.fg.controls;
  }

  verify() {
    this.api.verifyCustomer(this.f.sn.value).subscribe(
      value => {
        if (value.status === 200) {
          this.openDialog();
        } else {
          this.showError();
        }
      },
      error => {
        this.showError();
      }
    );
  }

  openDialog() {
    const config: NgbModalOptions = {
      centered: true,
      windowClass: 'modal-rounded'
    };
    const modalRef = this.modalService.open(VerifyConfirmationComponent, config);

    modalRef.componentInstance.name = 'World';

    modalRef.result.then(
      () => {
        this.submitted = true;
      },
      () => {
        this.submitted = true;
      }
    );
  }

  openModal(longContent: TemplateRef<any>) {
    this.modalService.open(longContent, { scrollable: true });
  }

  showError() {
    alert('წარმოიშვა გაუთვალისწინებელი შეცდომა.');
  }
}
