import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
  @Input() videoUrl: string;
  displayURL: SafeHtml;

  @Input() description: string;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.displayURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
  }
}
