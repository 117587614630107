import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-used',
  templateUrl: './blog-used.component.html',
  styleUrls: ['../../blog.component.scss']
})
export class BlogUsedComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
