import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { extract } from '@app/core';
import { OffersPageComponent } from './offers-page/offers-page.component';
import { VerifyCustomerComponent } from '@app/offers/verify-customer/verify-customer.component';
import { VerifyCustomer2Component } from '@app/offers/verify-customer2/verify-customer2.component';

const routes: Routes = [
  { path: 'offers', component: OffersPageComponent, data: { title: extract('Offers') } },
  { path: 'verify', component: VerifyCustomerComponent, data: { title: extract('Verify') } },
  { path: 'verify2', component: VerifyCustomer2Component, data: { title: extract('Verify') } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class OffersRoutingModule {}
