import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-for-infrastructure',
  templateUrl: './blog-for-infrastructure.component.html',
  styleUrls: ['../../blog.component.scss']
})
export class BlogForInfrastructure implements OnInit {
  constructor() {}

  ngOnInit() {}
}
