<div class="container-fluid m-0 p-0">
  <div class="blog-header-container">
    <h1>ბლოგი</h1>
  </div>
</div>
<div class="container blog-item custom">
  <h1>
    როგორ იმოგზაურა ეკამ 30-ზე მეტ ქვეყანაში ბიუჯეტურად?
  </h1>
  <h6>აპრილი 2023</h6>
  <br />

  <p>
    ყველა ვთანხმდებით, რომ მოგზაურობა ის ინტერესია, რომელიც თითოეულ ჩვენგანს აერთიანებს. განსაკუთრებით მიმზიდველია,
    როდესაც შესაძლებლობა გაქვს ახალი ქვეყნები, თუ ქალაქები ბიუჯეტურად დაათვალიერო. ჩვენს გუნდშიც ბევრი ისეთი ადამიანია,
    ვისთვისაც მუდმივი სიახლეების ძიება მთავარი ინტერესია. გვინდა გამოვარჩიოთ ერთ-ერთი მათგანი, რომლისთვისაც მოგზაურობის
    დაგეგმვის პროცესი დროდადრო უფრო მარტივდება. ამ ბლოგში ჩვენი ბუღალტერიის გუნდის წევრი - ეკა ვეშაგური გიზიარებთ
    რჩევებს, თუ როგორ შეგიძლიათ მინიმალური რესურსებით დაათვალიეროთ რამდენიმე ქვეყანა.
  </p>

  <img src="../../../../assets/blog/travel-tips/img1.jpg" alt="" />

  <p><b class="blue">პირველ რიგში, რა ასაკში დაიწყე მოგზაურობა და რომელ ქვეყანაზე შეაჩერე არჩევანი? </b></p>

  <p>
    პირველი ქვეყანა, რომელიც ხელმისაწვდომობის გამო 22 ან 23 წლის ასაკში დამოუკიდებლად მოვინახულე - თურქეთი იყო.
  </p>

  <p><b class="blue">ამ ეტაპზე რამდენ ქვეყანაში ხარ ნამყოფი და რომელს გამოარჩევდი?</b></p>

  <p>
    დღესდღეობით 30-ზე მეტი ქვეყანა მაქვს ნანახი. გამოვარჩევდი ჰოლანდიას, ფინეთსა და ნორვეგიას.
  </p>

  <p><b class="blue">რა კრიტერიუმების მიხედვით არჩევ ქვეყანას?</b></p>

  <p>
    როდესაც მოგზაურობა დავიწყე მივდიოდი იქ, რა მიმართულებითაც ბილეთები ყველაზე იაფი იყო. ახლა ვცდილობ ისეთი ქვეყნები
    ავარჩიო, სადაც არ ვარ ნამყოფი, ან ნამყოფი ვარ და სხვა ქალაქები თუ სოფლები მაინტერესებს.
  </p>

  <p><b class="blue">გაგვიზიარე რჩევები, როგორ ვიმოგზაუროთ ეკონომიურად.</b></p>

  <p>
    ჩემთვის პრიორიტეტული ევროპის ნებისმიერ ქვეყანაში ბიუჯეტურად მოხვედრაა. სწორედ ამიტომ, არჩევანს ბილეთის ფასის გამო
    Wizzair-ზე ვაჩერებ, მართალია, ამიტომ ყოველი ფრენის წინ ქუთაისში მიწევს ჩასვლა, თუმცა უკვე შევეჩვიე. ევროპაში შიდა
    ფრენები იაფია. იქიდან გამომდინარე რომ, ბილეთის ფასები ყოველდღიურად იცვლება, მას მუდმივად ვათვალიერებ.
  </p>

  <p>
    საძიებო სისტემის გამოყენების ჩემი ხრიკებიც მაქვს, რომელსაც სიამოვნებით გაგიზიარებთ. მოგზაურობის დაგეგმვის პირველ
    ეტაპზე, ბილეთების მაქსიმალურად მისაღები ფასის სანახავად, საძიებო სისტემას თვეებად ან ქვეყნებად ვახარისხებ,
    შესაბამისად, იმ ქვეყნის ბილეთებს ვყიდულობ, რომელიც ჩემთვის იმ მომენტში ყველაზე ხელმისაწვდომია. ასევე, რადგან ხშირად
    ვმოგზაურობ, მაქვს კლუბის წევრობა და წლის განმავლობაში ყველა ფრენაზე, თუ ბარგის შეძენაზე დამატებითი ფასდაკლებით
    ვსარგებლობ.
  </p>

  <p><b class="blue">უახლოეს მომავალში რომელ ქვეყანაში გეგმავ მოგზაურობას?</b></p>

  <p>
    წელს ჯერ რაც ზუსტად ვიცი და ნაყიდი მაქვს არის მალდივები, ემირატები, ლუქსემბურგი, საფრანგეთი, შვედეთი, პოლონეთი,
    თურქეთი, უზბეკეთი. ზუსტად არ ვიცი კიდევ რა შემომეყიდება.
  </p>
</div>

<div class="carousel">
  <app-custom-carousel [images]="images" [customOptions]="customOptions"></app-custom-carousel>
</div>

<div class="container blog-item custom">
  <p><b class="blue">ქვეყანა საუკეთესო კულტურით</b> - იტალია</p>
  <p><b class="blue">საუკეთესო სამზარეულოთი</b> - ჩემთვის იტალია</p>
  <p><b class="blue">ხალხი, რომელიც გამორჩეულად დამამახსოვრდა</b> - ხორვატიელები, ნორვეგიელები</p>
  <p><b class="blue">მეტს ველოდი</b> - კატანია (სიცილია)</p>
  <p><b class="blue">გადააჭარბა მოლოდინს</b> - პორტუგალია</p>
  <p><b class="blue">კიდევ ვეწვეოდი</b> - ნებისმიერ ქვეყანას, ესე არ მაქვს რომ რომელიმეში აღარ წავალ</p>
  <p><b class="blue">ყველაზე არაბიუჯეტური</b> - ემირატები</p>
  <p><b class="blue">ვიცხოვრებდი</b> - ჰოლანდია, ასევე სიცივის მიუხედავად სკანდინავიაში</p>
  <p><b class="blue">საუკეთესო გართობა</b> - მიკონოსი, ამსტერდამი</p>
  <p><b class="blue">კულტურული დასვენებისთვის</b> - იტალია</p>
</div>
