import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-lexus-easy',
  templateUrl: './blog-lexus-easy.component.html',
  styleUrls: ['../../blog.component.scss', './blog-lexus-easy.component.scss']
})
export class BlogLexusEasy implements OnInit {
  constructor() {}

  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
