<div class="container-fluid m-0 p-0">
  <div class="blog-header-container">
    <h1>ბლოგი</h1>
  </div>
</div>
<div class="container blog-item custom">
  <h1>აქ. ახლა თიბისი ლიზინგი ინფრასტრუქტურის სექტორის მხარდასაჭერად</h1>
  <h6>მაისი 2022</h6>
  <br />
  <p>
    თიბისი ლიზინგის პორტფელის მესამედი ინფრასტრუქტურის სეგმენტზე მოდის. გასაკვირი არც არის, დაფინანსების მარტივი და
    ეფექტური მეთოდი ხომ განსაკუთრებით მნიშვნელოვანია მაშინ, როცა ძვირადღირებულ აქტივებზე ვსაუბრობთ?
  </p>

  <p>
    სამშენებლო-დეველოპერული საქმიანობა თუ მსხვილი ინფრასტრუქტურული პროექტების განხორციელება მრავალი გამოწვევითაა სავსე
    რომლის დროსაც თანამედროვე მანქანა-დანადგარებს გადამწყვეტი მნიშვნელობა ენიჭებათ. სწორად შერჩეული და გამოყენებული
    აქტივი ამცირებს სამუშაო დროს და ხარჯს, შესაბამისად ზრდის საქმმიანობის ეფექტს (ანუ მოგებას), ხარისხსს და მეტწილად
    განაპირობებს დასაქმებულთა კმაყოფილებასაც.
  </p>

  <img src="../../../../assets/blog/for-infrastructure/img1.jpg" alt="" />

  <p>
    როგორც თიბისი ლიზინგის ინფრასტრუქტურის სეგმენტის ექსპერტები გვირჩევენ, მძიმე ტექნიკის შერჩევამდე რამდენიმე კარგად
    ნაცნობი ფაქტორი უნდა გავითვალისწინოთ:
  </p>

  <p>
    <i class="subhead capital light-blue"><b>1. მომწოდებელი</b></i>
  </p>

  <p>
    როგორც ნებისმიერი სხვა აქტივის შერჩევისას სანდრო, მაღალი რეპუტაციის მქონე მომწოდებლებთან მუშაობა აუცილებლობას
    წარმოადგენს. მათთან კომუნიკაცია გამჭირვალე და კომფორტულია, შემოთავაზებული ტექნიკის ხარისხი და მოწოდების დრო, სრულად
    შეესაბამება მოლოდინებს, ასევე ჯეროვნად სრულდება საგარანტიო პირობები და არ ფერხდებით ბიზნესის წარმოების პროცესში.
  </p>

  <p>
    <i class="subhead capital light-blue"><b>2. ხარისხი</b></i>
  </p>

  <p>
    ბუტაფორულად რომ არ ჟღერდეს გეტყვით, რომ მძიმე ტექნიკის შემთხვევაში ხარისხი შეკეთებისა და მოვლა-პატრონობასთან
    დაკავშირებული ხარჯების შემცირების პროპორციული ცნებაა, შესაბამისად, გაიზრდება თქვენი მოგება.
  </p>

  <p>
    <i class="subhead capital light-blue"><b>3. დაფინანსება</b></i>
  </p>

  <p>
    რა თქმა უნდა, გვერდს ვერც ამ მნიშვნელოვან საკითხს ავუვლით. განსაკუთრებით საფრთხილო, საყურადღებო, ძვირადღირებული
    აქტივის შეძენისთვის მსოფლიოში აპრობირებული მეთოდი - ლიზინგია.
  </p>

  <p>
    დაფინანსების ეს მექანიზმი გაძლევთ საშუალებას ისარგებლოთ აქტივით ყოველთვიური ანგარიშსწორების სანაცვლოდ, თან თქვენზე
    მორგებული პირობებით.
  </p>
</div>

<div class="container-fluid m-0 p-0">
  <div class="full-color background-light-blue">
    <div class="container">
      <div class="row image-quote-wrapper">
        <div class="col-12 col-md-5 mb-4 mb-md-0 quote-image">
          <img src="../../../../assets/blog/for-infrastructure/img2.jpg" alt="" />
        </div>
        <div class="col-12 col-md-7 quote-text-wrapper">
          <p class="image-quote">
            „გარდა იმისა, რომ ლიზინგის დამტკიცების პროცესი არის მარტივი და დროში ეფექტური, მომხარებლის ინტერესებს სხვა
            ასპექტებშიც გადამწყვეტ მნიშვნელობას ვანიჭებთ. სურვილის შემთხვევაში, ჩვენ თავად ვუკავშირდებით ადგილობრივ თუ
            საზღვარგარეთ არსებულ მომწოდებელს, ვეხმარებით ბიზნესს სასურველი აქტივის შერჩევაში, უზრუნველვყოფთ მის
            დაფინანსებას, მიწოდებას და შემდგოში მოვლა-პატრონობასთან დაკავშირებულ პროცესებს.”
          </p>
          <p class="quote-author">ნოდარ ბარბაქაძე</p>
          <p class="quote-author-title">საკრედიტო დეპარტამენტის უფროსი</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container blog-item custom">
  <p class="solid-header" style="color: #1a1a1a; font-size: 28px">მთავარი უპირატესობა</p>

  <p>
    <i class="subhead capital light-blue"><b>ექსკლუზიური პარტნიორობა ოფიციალურ წარმომადგენლებთან</b></i>
  </p>

  <p>
    თიბისი ლიზინგი რიგი წამყვანი ვენდორების ექსკლუზიური პარტნიორია, რაც შემოთავაზებულ პირობებში მოქნილობას განაპირობებს.
  </p>

  <p>
    <i class="subhead capital light-blue"><b>სპეციალური შეთავაზებები </b></i>
  </p>

  <p>
    სხვადასხვა პერიოდში სპეციალური შეთავაზებები მუშავდება პარტნიორ მომწოდებლებთან, რაც 0%-იანი თანამონაწილეობის,
    შემცირებული საპროცენტო განაკვეთისა თუ საშეღავათო პირობების სახით არის ფორმულირებული.
  </p>

  <p>
    <i class="subhead capital light-blue"><b>ერთი ინვოისის პრინციპი</b></i>
  </p>

  <p>
    შერჩეული ტიპის ლიზინგის მიხედვით აქტივის ფლობასთან დაკავშირებული ყველა ხარჯი გაერთიანებულია ერთ ინვოისში.
  </p>

  <p>
    <i class="subhead capital light-blue"><b>ქონების ნაკლები გადასახადი</b></i>
  </p>

  <p>
    რადგან ლიზინგისას აქტივი შენი კომპანიის სახელზე არ ირიცხება, არც ბუღალტრულ ბალანსზე იქონიებს გავლენას, შესაბამისად,
    ნაკლებია ქონების გადასახადი. ლიზინგის შემთხვევაში ქონების გადასახადი 1%-ის ნაცვლად, 0.6%-ით განისაზღვრება.
  </p>

  <img src="../../../../assets/blog/for-infrastructure/img3.jpg" alt="" />
</div>

<div class="container-fluid m-0 p-0">
  <div class="full-color background-light-blue">
    <div class="container">
      <p class="quote">
        “რეალურად, თუ აქტივის ტრანსპორტირება უცხოეთიდან ხდება, ჩვენ საკუთარ თავზე ვიღებთ მოწოდებისა თუ აქტივის
        შესაბამისობის რისკებს, შესაბამისად, თავისუფლად შეიძლება ითქვას, რომ ჩვენ გვაქვს საშუალება შევთავაზოთ ბიზნესს
        მომსახურების სრული ციკლი“
      </p>
    </div>
  </div>
</div>

<div class="container blog-item custom">
  <p>
    თუ კიდევ ფიქრობ, ღირს თუ არა ისარგებლო ლიზინგით მძიმე ტექნიკის შესაძენად, მოგვმართე აქ. ახლა და სპეციალისტებისგან
    მიიღე ამოწურავი კონსულტაცია შენთვის საინტერესო მიმართულებით.
  </p>

  <p style="color: #999999">ავტორი: ლალიკო თუმანიშვილი</p>
</div>
