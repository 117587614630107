<div class="container-fluid m-0 p-0">
  <div class="blog-header-container">
    <h1>ბლოგი</h1>
  </div>
</div>
<div class="container blog-item custom">
  <h1>
    VELI.STORE შენ რას ელი, ამ ახალ წელს?
  </h1>
  <h6>დეკემბერი 2022</h6>
  <br />

  <p>
    ონლაინ შოპინგი ერთგვარი თერაპიაა, რომელიც add to cart და checkout ფუნქციონალებს მიღმა, შენი კომფორტისთვის ათობით და
    ასობით ადამიანს აერთიანებს. დღეს veli.store-ზე, საქართველოში შექმნილ ელ.კომერციის კომპანიაზე, გიყვებით და მის
    საწყობში ვიზიტით მიღებულ შთაბეჭდილებებს გიზიარებთ. წარმოიდგინეთ ნამდვილი ელფების სამუშაო გარემო, სადაც კონკრეტული
    პიროვნების სურვილი წერილობითი სახით მიდის, მის დასამუშავებლად 14 კატეგორიას, ათასობით ბრენდს და ამდენივე პროდუქტს
    უნდა გადახედონ, კონკრეტული ნივთი შეარჩიონ და ელვის, ან უკვე „ველის“ სისწრაფით დაამუშაონ შეკვეთა. ღილაკზე თითის
    დაჭერიდან 2 საათში ან შენს მიერ შერჩეულ დროს ზღურბლთან მწვანე წებოვანი ლენტით საგულდაგულოდ შეფუთული ყუთი გაჩნდება,
    რომელშიც სწორედ ის ნივთია, რომელსაც ელი.
  </p>

  <img src="../../../../assets/blog/veli/img1.jpg" alt="" />
</div>

<div class="container-fluid m-0 p-0">
  <div class="full-color background-lime-green">
    <div class="container">
      <p class="quote">
        veli.store საყიდლების რუტინას ცვლის და ახალ კულტურას აყალიბებს. ციფრული პლატფორმა ინოვაციურ ტექნოლოგიურ
        გადაწყვეტებსა და სამომხმარებლო გამოცდილების დიზაინის პრინციპებს ეყრდნობა.
      </p>
    </div>
  </div>
</div>

<div class="container blog-item custom">
  <p>
    <i class="subhead capital">
      <b>როგორ ქმნის ველი მომხმარებლის საუკეთესო გამოცდილებას:</b>
    </i>
  </p>

  <p>
    კომპანიის ყველა ინიციატივა და აქტივობა დაფუძნებულია მომხმარებლისთვის ცხოვრების გამარტივებაზე, უმტკივნეულო და
    კომფორტული სერვისის შექმნისას, რაც ითარგმნება:
  </p>

  <p>
    <i class="subhead capital lime-green">
      <b>მარტივი და კომფორტული UX </b>
    </i>
  </p>

  <p>
    მომხმარებელი სულ რამდენიმე ღილაკის დაჭერით რეგისტრირდება პლატფორმაზე და ყიდულობს პროდუქტს. მას არ უწევს არასაჭირო
    ინფორმაციის შეყვანა. განმეორებითი შეძენა სხვადასხვა მისამართზე ძალიან მარტივია, რადგან მომხმარებელს შეუძლია
    სხვადასხვა მისამართის და ამავდროულად საკუთარი მონაცემების & საბანკო ბარათების დამახსოვრება.
  </p>

  <p>
    <i class="subhead capital lime-green">
      <b>მარტივი საძიებო სისტემა, რომელიც რამდენიმე წამს წაგართმევთ </b>
    </i>
  </p>

  <p>
    ყველა პროდუქტს სისტემაში გაწერილი აქვს სხვადასხვა საძიებო თაგი, რომლითაც მომხმარებელი შესაძლოა ეძებდეს სასურველ
    პროდუქტს. ამ მექანიზმის დახმარებით, Veli.store-ზე მომხმარებელი მარტივად პოულობს სასურველ ნივთს, ნებისმიერი მასთან
    დაკავშირებული სიტყვის ჩაწერისას.
  </p>

  <p>
    <i class="subhead capital lime-green">
      <b>სწრაფი და კომფორტული მიტანის სერვისი </b>
    </i>
  </p>

  <p>
    მომხმარებელი თავად ირჩევ დროის მონაკვეთს, რომელშიც სურს რომ მიიღოს შეკვეთა. მსგავსი ფუნქციონალი ინოვაციურია
    საქართველოს ბაზარზე. აღნიშნულის დახმარებით მომხმარებელს შესაძლებლობა აქვს კომფორტულად სასურველ დროს შეუკვეთოს
    პროდუქტი და არ იღელვოს იმაზე, იქნება თუ არა ადგილზე მაშინ, როდესაც კურიერი ამანათს მოიტანს.
  </p>

  <p>
    <i class="subhead capital lime-green">
      <b>მოტანის პროცესის გამჭვირვალობა </b>
    </i>
  </p>

  <p>
    შეკვეთის მომენტიდან შეკვეთის ჩაბარებამდე მომხმარებელი ყველა ეტაპზე იღებს SMS & email-ით ინფორმაციას მისი შეკვეთის
    სტატუსის შესახებ. ეს ნოტიფიკაციებია: შეკვეთის გაფორმება, შეკვეთა როდესაც მომხმარებლისკენ დაიძრა და, შეკვეთის
    ჩაბარების ნოთიფიკაცია.
  </p>

  <p>
    უფასო მიტანა მთელი საქართველოს მასშტაბით - რაც დამატებით კომფორტს ქმნის ყველა მომხმარებლისთვის.
  </p>

  <p>
    მარტივი და უმტკივნეულო დაბრუნების პროცესი - თუ პროდუქტი ის არ აღმოჩნდა, რასაც მომხმარებელი ელოდა, მას მარტივად
    შეუძლია დააბრუნოს პროდუქტი და დაიბრუნოს თანხა.
  </p>

  <p>
    <i class="subhead capital lime-green">
      <b>საჩუქრების შეფუთვა</b>
    </i>
  </p>

  <p>
    ველიზე ათასობით იდეალური საჩუქარი ნებისმიერი ასაკისა თუ ინტერესის ადამიანისთვის ერთ კატეგორიაში გააერთიანეს, რათა
    მათ შერჩევაზეც დაგეზოგათ დრო. შერჩეული ნივთის განსაკუთრებულ საჩუქრად გადაქცევა ღილაკებზე სულ რამდენიმე დაწკაპუნებით
    შეგიძლიათ. გადახდის გვერდზე, მონიშნავთ “შეფუთვა”, დაწერთ მისალოც ტექსტს და სულ ესაა. თქვენი ადრესატი საჩუქარს
    სასურველ დროს უკვე შეფუთულს, მისალოც ბარათთან ერთად, მიიღებს.
  </p>

  <img src="../../../../assets/blog/veli/img2.jpg" alt="" />

  <p>
    <i class="subhead capital">
      <b>ძლიერი საოპერაციო სისტემა, რომელიც შემდეგი ღერძებისგან შედგება: </b>
    </i>
  </p>

  <p>
    ყველა პროდუქტი, რომელსაც ველი ყიდის მოთავსებულია ველის დიდ საწყობში.
  </p>
  <p>
    საოპერაციო სოფტის დახმარებით, პროდუქტის აღებას და მომხმარებლისთვის გასაგზავნად მომზადებას სულ რაღაც 1 წუთი სჭირდება.
    საკუთარი საკურიერო სერვისი - რომელიც სხვადასხვა უბნებზე არის დანიშნული, რაც უზრუნველყოფს შენაძენის 3 საათში
    მომხმარებელთან მიწოდებას.
  </p>
  <p>
    მომხმარებელზე ფოკუსირებული გუნდი - კომპანიაში ყველა თანამშრომელი, განსაკუთრებით ისინი, ვისაც მომხმარებელთან
    პირდაპირი შეხება აქვთ გადიან ინტენსიურ გადასამზადებელ კურსებს კვარტალში ერთხელ. იმ გუნდებს, რომლებსაც მომხმარებელთან
    უშუალო შეხება აქვთ მუდმივად უტარდებათ პიროვნული უნარების ტრენინგებიც.
  </p>

  <img src="../../../../assets/blog/veli/img3.jpg" alt="" />

  <p>
    VELI.STORE-ში სჯერათ, რომ განსაკუთრებით ძვირფასს - დროს გაზოგინებენ, იმ დროს, რომელიც სადღესასწაულო საჩუქრის
    შესარჩევად გასულს საცობში, არჩევაში, ან სულაც რიგში უნდა დაგეხარჯა. გაითავისუფლე დრო და დახარჯე ის ახალი
    გამოცდილების მიღებაში - შენ რას ელი ამ ახალ წელს?
  </p>
</div>
