<div class="container">
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <app-video
        videoUrl="https://www.youtube.com/embed/KgPnMp6sB4g"
        description="კენ ვოლკერის კლინიკა - თიბისი ლიზინგის პარტნიორი"
      ></app-video>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-video
        videoUrl="https://www.youtube.com/embed/NpKcuHFE2x8"
        description="საოპერაციო ლიზინგი ბიზნესისთვის"
      ></app-video>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-video
        videoUrl="https://www.youtube.com/embed/EfAOL4fM0cA"
        description="რატომ ჯობს ავტომობილის შეძენა ლიზინგით"
      ></app-video>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-video
        videoUrl="https://www.youtube.com/embed/Bq6h-UeBy8E"
        description="ლიზინგის უპირატესობები"
      ></app-video>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-video
        videoUrl="https://www.youtube.com/embed/6aIRHsB7FRU"
        description="რა არის ლიზინგი"
      ></app-video>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-video
        videoUrl="https://www.youtube.com/embed/sRcul2H5v2Q"
        description=" ბრავო რექორდსის ბიზნეს ისტორია"
      ></app-video>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-video
        videoUrl="https://www.youtube.com/embed/-WbioInuZGw"
        description="კოკა-კოლას ბიზნეს ისტორია"
      ></app-video>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-video
        videoUrl="https://www.youtube.com/embed/Q-FHxmdaXuI"
        description="ილუზიების მუზეუმის ბიზნეს ისტორია"
      ></app-video>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-video
        videoUrl="https://www.youtube.com/embed/TPFGRpOOBp4"
        description="„ბორუსან CAT საქართველოს“ ღორისძიება ბათუმში"
      ></app-video>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <app-video
        videoUrl="https://www.youtube.com/embed/RUhIXXc0Uxw"
        description="საქართველოში წარმოებული მზის პანელები AE SOLAR-ისგან"
      ></app-video>
    </div>

    <div class="col-12 col-md-6 col-lg-4">
      <app-video
        videoUrl="https://www.youtube.com/embed/WXJgSICrsIQ"
        description="ეკომეგობრული საწვავმზიდები SOCAR GEORGIA-სთვის"
      ></app-video>
    </div>

    <div class="col-12 col-md-6 col-lg-4">
      <app-video
        videoUrl="https://www.youtube.com/embed/BGl4aa0HxLE"
        description="მაღალი ხარისხის ატრაქციონები შუმბალენდისთვის"
      ></app-video>
    </div>
  </div>
</div>
