export const tbclOffers: Offer[] = [
  {
    imageUrl: 'mitsubishi.jpg',
    title: 'სპეციალური ფასდაკლება',
    subheader: 'MITSUBISHI',
    documentUrl: 'mitsubishi.pdf'
  },
  {
    imageUrl: 'land_rover.jpg',
    title: 'შეთავაზება Land Rover-ის ან Jaguar-ის ავტომობილებზე',
    subheader: 'GT Motors',
    documentUrl: 'land_rover.pdf'
  },
  {
    imageUrl: 'kia.jpg',
    title: 'სპეციალური შეთავაზება',
    subheader: 'KIA',
    documentUrl: 'kia.pdf'
  },
  {
    imageUrl: 'zeekr.jpg',
    title: 'სპეციალური ფასდაკლება',
    subheader: 'Geely Sakartvelo, Zeekr Sakartvelo',
    documentUrl: 'zeekr.pdf'
  },
  {
    imageUrl: 'volvo.jpg',
    title: 'მინიმალური ყოველთვიური შენატანი',
    subheader: 'Volvo Car Sakartvelo',
    documentUrl: 'volvo.pdf'
  },
  {
    imageUrl: 'mazda.jpg',
    title: '1 წლიანი 0%-იანი ლიზინგი',
    subheader: 'Mazda Center Tegeta',
    documentUrl: 'mazda.pdf'
  },
  {
    imageUrl: 'land_cruiser.jpg',
    title: 'შეთავაზება Land Cruiser 300 და Camry-ზე',
    subheader: 'Toyota Center Tegeta',
    documentUrl: 'land_cruiser.pdf'
  },
  {
    imageUrl: 'new_holland.jpg',
    title: 'New Holland-ის ტრაქტორი 1 წლიანი 0%-იანი ლიზინგით',
    subheader: 'GT Group',
    documentUrl: 'new_holland.pdf'
  },
  {
    imageUrl: 'bmw.jpg',
    title: 'შეთავაზება BMW-ზე',
    subheader: 'GT Motors',
    documentUrl: 'bmw.pdf'
  },
  {
    imageUrl: 'mini.jpg',
    title: 'შეთავაზება Mini-ზე',
    subheader: 'GT Motors',
    documentUrl: 'mini.pdf'
  },
  {
    imageUrl: 'gt_motors.jpg',
    title: '6 თვეში დაიბრუნე საგარანტიო თანხა 20%',
    subheader: 'GT Motors',
    documentUrl: 'gt_motors.pdf'
  },
  {
    imageUrl: 'hyundai2.jpg',
    title: 'HYUNDAI-ს ექსკავატორი 1 წლიანი 0%-იანი ლიზინგით',
    subheader: 'Caucasus Machinery',
    documentUrl: 'hyundai2.pdf'
  },
  {
    imageUrl: 'changan.jpg',
    title: 'შეიძინე Changan Eado EV 460 ლიზინგით სპეციალურ ფასად',
    subheader: 'Changan Georgia',
    documentUrl: 'changan.pdf'
  },
  {
    imageUrl: 'byd_seagull.jpg',
    title: 'შეიძინე BYD Seagull 44,795 ლარად',
    subheader: 'Solar Auto',
    documentUrl: 'byd_seagull.pdf'
  }
];

export interface Offer {
  imageUrl: string;
  title: string;
  subheader: string;
  documentUrl: string;
}
