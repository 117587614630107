import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable()
export class BlogService {
  constructor(private metaTagService: Meta) {}

  addMetaTagsForBlog(title: string, description: string, image: string) {
    const baseUrl = window.location.protocol + '//' + window.location.hostname;
    const imageUrl = baseUrl + image;

    [
      {
        name: 'title',
        content: title
      },
      {
        name: 'description',
        content: description
      },
      {
        property: 'og:title',
        content: title
      },
      {
        property: 'og:description',
        content: description
      },
      {
        property: 'og:image',
        content: imageUrl
      }
    ].forEach(tag => {
      this.metaTagService.updateTag(tag);
    });
  }
}
