<div class="project-header-container">
  <div class="project-header mx-auto"><h1>Pineo Luxury</h1></div>
</div>

<div class="project mx-auto">
  <h1>საქართველოში პირველი სახლის ტიპის სამშობიარო - Pineo Luxury</h1>
  <p>
    ფოტოგრაფი და მე ინტერვიუზე დათქმულ დროს მოვედით. მოსაცდელში ასისტენტი 2 წუთით გაჩერდა და მგონი დღეს არ გამოვა
    ჩაწერა, მშობიარე შემოიყვანესო - გვითხრა. ამ დროს ოთახიდან მთავარი ექიმი - ზაზა სინაურიძე გამოვიდა. ჩვენთან ისეთი
    სიმშვიდეა, ყველაფერს მოვასწრებთ, ინტერვიუსაც და მშობიარობასაცო - ღიმილით დაგვპირდა. ნიუ-იორკის ურბანული გარემო,
    ტოკიოს აყვავილებული საკურას ბაღები, თუ მწვანეში ჩაფლული ჟენევა - სად გინდათ დაიბადოს თქვენი პატარა?
  </p>
  <p>
    ინოვაციური კონცეფციის სამშობიარო სახლი „პინეო ლაქშერი“ ვერაფრით მოგაგონებთ საავადმყოფოს. სახლის ტიპის გარემოში
    ვერცხლისფერი ხელსაწყოები და სამედიცინო აღჭურვილობა მომავალი დედების თვალთახედვის მიღმა, შეუმჩნევლად, საგულდაგულოდაა
    შენახული.
  </p>
  <p>
    „ჩვენთვის ფეხმძიმე ქალი არის ადამიანი, რომელიც ელოდება საოცრებას, სასწაულს. რომელსაც ზრუნვა და არა მკურნალობა
    სჭირდება. აქაური გარემო იმისთვის შევქმენით, რომ მშობიარე არ დაითრგუნოს. პირიქით, არაფრის შეეშინდეს, სიხარული
    განიცადოს და კიდევ ბევრი ბავშვის ყოლის სურვილი გაუჩნდეს.“
  </p>
  <p>
    80-იან წლებში ჩატარებულმა კვლევებმა აჩვენა, რომ მშობიარობა ნაკლებად სტრესული და შესაბამისად ნაკლებად სარისკო
    პროცესია, როდესაც მშობიარე თავს მისთვის ნაცნობ გარემოში გრძნობს. ამიტომ, 90-იან წლებში სკანდინავიის ქვეყნებში,
    სახლში მშობიარობის მეთოდიც კი დაბრუნდა. მაგრამ არსებული მცირე რისკის გამო, გადაწყდა, რომ ქალებს მაინც სტაციონარში
    უნდა ემშობიარათ, თუმცა, საავადმყოფოსგან განსხვავებულ გარემოში. ასე გაჩნდა პირველად home-like-situation
    სამშობიაროები. სკანდინავიის ქვეყნების შემდეგ ტენდენცია ბრიტანეთსა და აშშ-შიც დაინერგა.
  </p>
  <p>
    „მშობიარობა ბუნებრივი, ფიზოლოგიური პროცესია, თუმცა ამ დროს ყველაფერი უნდა იყოს გათვალისწინებული და ხელმისაწვდომი -
    რეანიმაცია, ნევროლოგია, ქირურგია, კარდიოლოგია. კარგი სამშობიარო დიდ საავადმყოფოსთან უნდა იყოს ინტეგრირებული. ამიტომ,
    სამშობიაროს არჩევისას გახსოვდეთ, რომ 95 პროცენტი ყველაფერი კარგად იქნება, მაგრამ გახსოვდეთ ის 5 პროცენტიც,
    რომლისთვისაც სამშობიარო მზად უნდა იყოს.“
  </p>
  <p>
    ფერადი და სასიამოვნო პანოების გარემოში, შესაძლოა არასოდეს მიაქციოთ ყურადღება თანამედროვე დანადგარებსა და
    აღჭურვილობას, რომელიც აქაურობას უსაფრთხო და საიმედო ადგილად აქცევს. მსურველებს აქ წყალში მშობიარობაც შეუძლიათ.
    „თიბისი ლიზინგის მეშვეობით ძალიან მნიშვნელოვანი - უნიკალური ტრანსფორმერი ლოგინები შევიძინეთ, რომლებიც ხელის ერთი
    მოძრაობით სამშობიარო ლოგინებად გადაიქცევა. ეს არის ინოვაცია, რომელიც საქართველოში მხოლოდ რამდენიმე ადგილას
    შეგხვდებათ და რომელიც მშობიარობის პროცესს ბევრად უფრო კომფორტულს ხდის.“
  </p>
  <p>
    აქ მშობიარობაზე დასწრების მსურველების რაოდენობას არავინ ზღუდავს. პირიქით... ზაზა სინაურიძე ერთ-ერთი პირველი იყო,
    ვინც ქმრებს მშობიარობაზე დასწრებას ურჩევდა.
  </p>
  <p>
    „ფსიქოლოგების კვლევები ძალიან დაგვეხმარა. აღმოჩნდა, რომ ყველაფერი ბევრად უფრო მარტივია, ვიდრე აქამდე გვეგონა:
    არავითარი თეთრი ხალათები და მბრწყინავი, მეტალის ხელსაწყოები გარშემო. ყველაზე მთავარი ის ადამიანები არიან, რომლებიც
    მშობიარე გოგონას გვერდით დგანან. დედა, მეუღლე, ოჯახის სხვა წევრები - რეალურად სწორედ ყველა ეს ადამიანი არის ჩვენთვის
    სახლი. ამ დროს კი ექიმი ხშირად არა, მხოლოდ საჭირო რაოდენობით შემოდის და ამოწმებს ვითარებას.“
  </p>
  <p>
    სახლის ტიპის სამშობიარო მხოლოდ ვიზუალურ მხარეს არ გულისხმობს. აქ პერსონალის დამოკიდებულება და მიდგომაც
    განსხვავებულია. „კოლეგებს ხშირად ვეუბნები ხოლმე: წარმოიდგინეთ, რომ თქვენი ქალიშვილი, ცოლი ან და მშობიარობს. როგორ
    მოექცეოდით მას? ჯანდაცვაც მომსახურების სფეროა და სამედიცინო პერსონალმა ფსიქოლოგიური კომფორტი უნდა შეუქმნას
    მშობიარეს, დაამშვიდოს და გაუჩინოს შეგრძნება, რომ ყველაფერი კარგად იქნება. ეს კომფორტი კი მხოლოდ მოფერებითა და
    სიყვარულით მოდის. სამედიცინო პერსონალი ღიმილის გარეშე არ უნდა შევიდეს მშობიარე გოგონასთან. თუ არ ეღიმება, მაშინ
    სხვაგან იმუშაოს.“
  </p>
  <p>
    აქ ყველა ფანჯარა ერთ ჰექტარზე გაშენებულ ფიჭვის ტყეში გადის. „პინეო ლაქშერი“ ქალაქის ეკოლოგიურად სუფთა ნაწილში
    მდებარეობს. სახელიც ფიჭვის ინგლისური დასახელებიდან - Pine-დან გაჩნდა.   როგორც ყველა მომავალ დედას, მშობიარობასთან
    დაკავშირებით აქამდე მეც ბევრი ჩემებური წარმოდგენა და შიში მქონდა. ეხლა კი ვგრძნობ - მშვიდ, ფერად, მზრუნველ და ოჯახურ
    გარემოში ნელ-ნელა როგორ ქრება ეს წარმოდგენები. და სანამ ჩვენ აქ ვართ, გვერდით, სულ ახლოს, ჟენევაში, ახალი სიცოცხლეც
    დაიბადა. იაკობი. 51 სანტიმეტრი. 3 კილო და 500 გრამი. კეთილი იყოს შენი მობრძანება ამ სამყაროში...
  </p>
  <div class="author"><h4>ავტორი: მარიამ ყანჩაველი</h4></div>
</div>
