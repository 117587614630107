<div class="container-fluid m-0 p-0">
  <div class="blog-header-container">
    <h1>ბლოგი</h1>
  </div>
</div>
<div class="container blog-item custom">
  <h1>
    თიბისი ლიზინგი - შენი საუკეთესო სამომხმარებლო გამოცდილებისთვის
  </h1>
  <h6>აპრილი 2023</h6>
  <br />

  <p>
    გიფიქრია რატომ ირჩევ კონკრეტულ პროდუქტს ან ბრენდს სამეგობროდ? რა შეგრძნებებს აღძრავს შენში? პრაქტიკულობის თუ
    ეფექტურობის გარდა, რას ელი მისგან? დაგვეთანხმები, რომ გუმანით გრძნობ, როცა კომპანია, რომელთანაც შეხება გაქვს -
    გულწრფელად ზრუნავს შენზე. ამასთანავე, თანამედროვე ორგანიზაციებსაც გააზრებული აქვთ, რომ თუ მომხმარებლის გამოცდილების
    გაუმჯობესებას საკუთარი კორპორაციული კულტურის ამომავალ წერტილად აქცევენ - იდეალური ურთიერთობა შედგება, მეტიც,
    Qualtrics XM Institute-ის კვლევის თანახმად, ასეთი კომპანიების 89% საუკეთესო ფინანსურ შედეგებს დებს.
  </p>

  <p>
    თიბისი ლიზინგში წლების წინ დავფიქრდით, როგორ შეიძლება სიტყვა - საქმედ ვაქციოთ და საოპერაციო პროცესების
    განვითარებისას სწორება თქვენი გამოცდილების გაუმჯობესებაზე გვქონდეს. სწორედ ამიტომ, ადამიანებზე ზრუნვის დეპარტამენტი
    ჩამოვაყალიბეთ და როგორც მომხმარებლების, ასევე დასაქმებულების ინტერესებზე ზრუნვა დავაპრიორიტეტეთ. ურთიერთობები
    ორმხრივია და მუდმივ ყურადღებას საჭიროებს, შესაბამისად, კმაყოფილების არა თეორიული, არამედ გაზომვადი განსაზღვრებები
    ჩამოვაყალიბეთ და მიზნად „ადამიანების პულსზე ხელის ჭერა“ დავისახეთ. მოკლედ, ყოველდღიური, თვიური თუ წლიური კვლევებით,
    უკუკავშირების მიღებითა და გადააზრებით საერთოდ ახალი, მომხმარებელზე ორიენტირებული კომუნიკაცია ჩამოვაყალიბეთ, რომლის
    მცირე ნაწილსაც ამ კამპანიის ფარგლებში გაგაცნობთ.
  </p>

  <img src="../../../../assets/blog/user-experience/img1.jpg" alt="" />
</div>

<div class="container-fluid m-0 p-0">
  <div class="full-color background-dark-blue">
    <div class="container">
      <p class="quote">
        “ბრენდი დაპირებაა, რომელსაც მომხმარებელს ვაძლევთ და შესაბამისად არასდროს უნდა ვუღალატოთ. რა თქმა უნდა,
        ყოველდღიურობაში აღნიშნული არც ისე მარტივია, თუმცა მიზანი როგორც მინიმუმ - მცდელობა უნდა იყოს. ჩვენს გუნდს სჯერა,
        რომ უმნიშვნელოვანესია გვესმოდეს მომხმარებლის ხმა, ვესაუბრებოდეთ მათ, ემპათია გამოვხატოთ მაშინ, როდესაც ვერაფერს
        ვცვლით და შევცვალოთ მაშინ, როდესაც შეგვიძლია. მუდმივობა არაჩვეულებრივი სიტყვაა და ნებისმიერ პროცესს უნდა
        ახასიათებდეს, ამიტომ ამ სამი წლის მანძილზე ზუსტად ეს თვისება გამოვიმუშავეთ“.
      </p>
      <p class="quote" style="font-feature-settings: 'case' on; margin-top: 42px;">
        თეო ლეჟავა
      </p>
      <p class="quote" style="font-family: 'TBCX-LIGHT'; font-size: 20px;">
        ბრენდის და ადამიანებზე ზრუნვის დეპარტამენტის უფროსი.
      </p>
    </div>
  </div>
</div>

<div class="container blog-item custom">
  <p>
    ჩვენი გუნდი თქვენს უკუკავშირს ნებისმიერ პლატფორმაზე აგროვებს, ეცნობა და ყოველკვირეულ შეკრებაზე უზიარებს იმ
    დაკავშირებულ მხარეებს, რომელთა კომპეტენციასაც აღნიშნული საკითხის დახვეწა თუ მოგვარება წარმოადგენს.
    <b class="dark-blue">
      „შესაძლოა ერთი შეხედვით მარტივმა კომენტარმა რადიკალურად სხვა თვალით დაგვანახოს მიმდინარე პროცესი და მოგვცეს
      გაუმჯობესებისთვის საჭირო მინიშნება“</b
    >. არანაკლებ ფასეულია უკვე არსებული მომხმარებლების მიერ საკუთარ მენეჯერებსა თუ ოპერატორებთან გაზიარებული
    შეხედულებები, რომელთა მიხედვითაც საოპერაციო ნაწილშიც კი შეგვიტანია ცვლილებები.
    <b class="dark-blue">
      „ვაფასებთ იმ ჩართულობას, რომელიც მუდმივი კვლევის პროცესში გვაქვს, ამიტომ უკუკავშირის მიღების შემდგომ გონივრულ
      ვადაში ვუბრუნდებით ადამიანებს და ვატყობინებთ, შევძელით თუ არა მათი მოსაზრების მიხედვით ცვლილების შეტანა“ </b
    >.
  </p>
</div>

<div class="carousel">
  <app-custom-carousel [images]="images" [customOptions]="customOptions"></app-custom-carousel>
</div>

<div class="container blog-item custom">
  <p>
    მომხმარებელთა კმაყოფილების საუკეთესო მაჩვენებელი შესაძლოა ისევ და ისევ მათ მიერ ჩვენი კომპანიის არჩევა იყოს, თუმცა
    ჩვენთვის ეს საკმარისი არ არის და უშუალო გამოკითხვით ვცდილობთ გავიგოთ, თუ როგორია მათი გამოცდილება ჩვენი პროდუქტით
    სარგებლობის ნებისმიერ ეტაპზე. სიტყვა ძალიან რომ არ გაგვიგრძელდეს, ეტაპობრივად გაგაცნობთ თხოვნებს რომელიც
    გავითვალისწინეთ და უკუკავშირს, რომელიც თქვენგან მივიღეთ.
  </p>
</div>
