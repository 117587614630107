<div class="container-fluid m-0 p-0">
  <div class="blog-header-container">
    <h1>ბლოგი</h1>
  </div>
</div>
<div class="container blog-item ">

  <h1>მზის პანელების პრიორიტეტულობა ბიზნესისა და ქვეყნისთვის</h1>
  <h6>თებერვალი 2021</h6>
  <br>
  <h1 style="text-align: center"></h1>


  <p>
    ეკომეგობრულია, დამოუკიდებელს გხდის, ეკონომიურობაში გეხმარება და შენზე გრძელვადიანად ზრუნავს. თუ ამ აღწერის დროს
    სამაგალითო ადამიანი წარმოიდგინე, გეტყვით, რომ საუბარი მზის პანელებზეა და კი, მისი უპირატესობების ჩამოთვლა
    დაუსრულებლად შეგვიძლია.
  </p>
  <p>კაცობრიობისთვის მზის პანელების მნიშვნელობა რომ ცხადად დავინახოთ, საკმარისია ერთმანეთს ტრადიციული რესურსები და მზის
    ენერგია შევადაროთ. ენერგიის ეს სახე იმ წყაროებისგან განსხვავებით, რომლებსაც ჩვენ მოვიხმართ, ამოუწურავია. მზის სხივს
    არ სჭირდება მოპოვება, ტრანსპორტირება, არ გააჩნია წონა, არც ხმაურობს და დედამიწისთვის მავნე ნარჩენებსაც არ
    წარმოქმნის, რასაც ვერ ვიტყვით ენერგიის მოპოვების სხვა გზებზე. სწორედ ამიტომაა ის <b>ეკომეგობრული</b>.</p>

  <img src="../../../../assets/blog/solar/img1.jpg" alt="">

  <p>პირველი მიზეზი, რატომაც მსოფლიო მასშტაბით ამ ტიპის ენერგიის მზის პანელებით ათვისება სულ უფრო და უფრო აქტუალური
    ხდება, ამოუწურვადობა და ეკოლოგიური კატასტროფების პრევენციაა, შემდგომში კი — მისი <b>ბიუჯეტურობა</b>.</p>
  <p>თუ გავითვალისწინებთ NASA­-ს კვლევის მონაცემებს, მზით სავსე თვეებში, კერძოდ, მაისიდან ოქტომბრამ­დე, საქართველოში,
    წყლის გასაცხელებლად საჭირო ენერგიას 100%-ით გამოიმუშავებ, მარტში, აპრილსა და ოქტომბერში მიიღებ საჭირო სითბოს
    ნახევარს, ნოემბრიდან თებერვლამდე კი – 20­-30%­-ს. გამოდის, რომ ჩვენს ქვეყნაში არ არსებობს ისეთი პერიოდი, რომლის
    დროსაც მზის პანელებით გარკვეული რაოდენობის ენერგიას მაინც არ დააგროვებ.</p>
  <p>მართლაც, თუ ზემოთმოყვანილ კვლევას და იმ ფაქტს გავითვალისწინებთ, რომ საქართველოში წელიწადის 250-280 დღე მზიანია,
    თამამად შეიძლება ვთქვათ, რომ <b>მზის ენერგიის ათვისება ბიზნესსექტორისთვის უდიდესი შესაძლებლობაა.</b></p>

  <img src="../../../../assets/blog/solar/img2.jpg" alt="">

  <p>ბიზნესი, რომელიც მზის პანელებით სარგებლობს ან მათ აწარმოებს, ქვეყნისთვის ქმნის შესაძლებლობას ავითვისოთ ენერგიის
    ამოუწურავი რესურსი, გარდავქმნათ ის ელექტრო ან თბოენერგიად, დამატებით კი, ეკოლოგიაზეც ვიზრუნოთ. ბიზნესის მხრიდან
    სოლარული ფერმისთვის ერთჯერადად გამოყოფილი თანხა სამომავლოდ დიდი შემოსავლის გამომუშავებასა და დაზოგილი ფინანსების
    სხვა ინვესტიციებისკენ მიმართვის საშუალებაა, რაც პირდაპირ კავშირშია ქვეყნის ეკონომიკურ სტაბილურობასთან.</p>
  <p>ბიზნესჭრილში, მზის პანელები სხვადასხვა მიზნობრიობით შეგვიძლია გამოვიყენოთ, მაგალითად:</p>
  <p>
    <strong>სამრეწველო საწარმოებისთვის</strong><br><br>
    სამრეწველო საწარმოს ტერიტორიაზე მზის პანელების დაყენებამ გათბობის ყოველთვიური მომსახურების ხარჯები შეიძლება
    70­-80%-მდე შეგიმციროს. ერთჯერადად გაღებული ხარჯით ბიზნესს 25+ წლის განმავლობაში ელექტრო და თბოენერგიის ხარჯებს
    მნიშვნელოვნად შეუმცირებ. </p>
  <img src="../../../../assets/blog/solar/img3.jpg" alt="">

  <p><strong>სასტუმროებისთვის</strong><br><br>

    გამოცდილება აჩვენებს, რომ მზის პანელების დაყენებისთვის ერთ-ერთი ყველაზე ხელსაყრელი ობიექტი — სასტუმროებია. მართლაც,
    სასტუმრო იმ ბიზნესებს მიეკუთვნება, სადაც დიდი რაოდენობით ელექტრო და თბოენერგია იხარჯება.</p>
  <p><strong>კვების ობიექტებისთვის</strong><br><br>

    რესტორნებისა და კაფე-ბარების მეპატრონეებისთვის ნაცნობია იმ ხარჯების ოდენობა, რაც ცხელი წყლით მომარაგებას, გათბობასა
    და განათებას სჭირდება. გარდა იმისა, რომ მზის პანელები ამ ხარჯს ამცირებს, მათი დახმარებით, უკვე აღარ იქნები
    დამოკიდებული გაზისა თუ ელექტროენერგიის ტარიფის ზრდაზე.</p>
  <img src="../../../../assets/blog/solar/img4.jpg" alt="">

  <p><strong>მეურნეობებისთვის</strong><br><br>

    სოფლის მეურნეობის სექტორისთვის ეკოლოგიურად სუფთა ენერგეტიკული წყარო კიდევ უფრო მიმზიდველად ჟღერს. ფერმებსა თუ სხვა
    სამეურნეო ტერიტორიებზე მზის პანელების დამონტაჟებას სოფლის მეურნეობაში ჩართული სულ უფრო და უფრო მეტი პირი
    მიმართავს.</p>
  <p><strong>მზის ფერმებისთვის</strong><br><br>

    ერთია მზის ენერგიის გამოყენება ბიზნესისთვის, მეორე კი — პანელების ბიზნესიდეად ქცევა. მას შემდეგ, რაც კანონი შეიცვალა
    და ელექტროსადგურის დადგმული სიმძლავრე 100 კილოვატიდან 500 კილოვატამდე გაიზარდა, ადგილობრივი ბაზრისთვის მზის
    ენერგიაში ინვესტიცია უფრო მიმზიდველი გახდა. ამას ადასტურებს ის ფაქტიც, რომ 2020 წლიდან საქართველოში
    ელექტროსადგურების რაოდენობა 40%-ით გაიზარდა.</p>
  <img src="../../../../assets/blog/solar/img5.jpg" alt="">


</div>
<div class="container-fluid m-0 p-0">
  <div class="solar-accent">
    <div class="container blog-item">
      <div class="row">
        <div class="col-12">


          <p style="font-size: 28px;font-weight: bolder;padding-top: 40px;">
            მზის პანელების ლიზინგით შეძენის შემთხვევაში სოფლის მეურნეობის პროექტებს სუბსიდირებას სოფლის განვითარების
            სააგენტო/Rda უწევს, ხოლო საწარმოებს, სასტუმროებსა თუ სხვა პროექტებს “აწარმოე საქართველო”. აღნიშნული
            სუბსიდირების შემთხვევაში, ლიზინგის ყოველთვიური გადასახადი მნიშვნელოვნად მცირდება, რომ არაფერი ვთქვათ,
            სამომავლოდ შემცირებულ ელექტრო და თბოენერგიის ხარჯებზე.

          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container blog-item ">
  <p>დამატებით, 2020 წელს ლუქსემბურგის ფონდს, “Green For Growth Fund” (GGF)-სა და თიბისი ლიზინგს შორის <b>16,4 მილიონი
    ლარის</b> მოცულობის სასესხო ხელშეკრულება გაფორმდა, რომლის მიზანი სწორედ ენერგოეფექტური პროექტების დაფინანსების
    ხელშეწყობაა, მათ შორის მზის პანელებიც მოიაზრება.</p>
  <p>ეს დაფინანსება საშუალებას მისცემს ბიზნესებს განახორციელონ ენერგოეფექტური ინვესტიციები და წვლილი შეიტანონ ქვეყნის
    ეკონომიკის მწვანე აღდგენაში.</p>
  <p>აღსანიშნავია, რომ საქართველოს ახალი ენერგოპოლიტიკის თანახმად, 2030 წლისთვის, ქვეყნის ენერგიის 35% განახლებადი
    ენერგიის წყაროებიდან იქნება მიღებული, რაც დროთა განმავლობაში ქვეყანას ეკოლოგიურ და ეკონომიკურ სტაბილურობას
    მოუტანს.</p>

  <br/>
  <div class="blog-author"><span>ავტორი: ნინჩო ბალანჩივაძე</span></div>

</div>
