<app-shell></app-shell>

<div class="tbcl-cookie-body">
  <div class="tbcl-cookie-wrapper" *ngIf="showPopup">
    <i class="fas fa-times fa-color-white" (click)="enableGoogleAnalytics()"></i>
    <p class="tbcl-cookie-text">
      ჩვენ ვიყენებთ <b>Cookie</b> ფაილებს თქვენი გამოცდილების გაუმჯობესების და უკეთესი ნავიგაციის მიზნით. გთხოვთ,
      გაეცანით <a routerLink="/cookie-policy">პოლიტიკას</a> და დააფიქსირეთ დადებითი ან უარყოფითი პასუხი Cookie ფაილების
      გამოყენებაზე.

      <i (click)="enableGoogleAnalytics(true)" style="padding: 0 5px;">კი</i>
      <i (click)="disableGoogleAnalyitcs()">არა</i>
    </p>
  </div>
</div>
