import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CustomCarouselComponent } from './custom-carousel.component';

@NgModule({
  declarations: [CustomCarouselComponent],
  imports: [CommonModule, CarouselModule],
  exports: [CustomCarouselComponent]
})
export class CustomCarouselModule {}
