<div class="container-fluid m-0 p-0">
  <div class="blog-header-container">
    <h1>ბლოგი</h1>
  </div>
</div>
<div class="container blog-item ">
  <h1>როგორ შეგვიძლია ლიზინგის გამოყენება აგროსფეროში? - მომენტები აგროექსპორტის ფორუმიდან</h1>
  <h5 style="font-family: NUSXURI;text-align: left"></h5>
  <h6>დეკემბერი 2020</h6>

  <p>
    მსოფლიოში არსებული ეპიდემიოლოგიური ვითარების გათვალისწინებით, ღონისძიებების ჩატარება დაუშვებელია, სოფლის მეურნეობისა
    და კვების მრეწველობის დარგების განვითარება კი, აქ,ახლა ისეთი მნიშვნელოვანია, როგორც არასდროს. სწორედ ამიტომ, წელს,
    ექსპოჯორჯიამ სოფლის მეურნეობისა და კვების მრეწველობის ექსპორტის პირველ მასშტაბურ ონლაინ ფორუმს უმასპინძლა.
  </p>

  <p>
    ფორუმი მთლიანად B2B ფორმატში, ონლაინ შეხვედრების კომპონენტით ჩატარდა და აერთიანებდა პანელურ დისკუსიებსა და
    ვორქშოპებს. ფორუმის ძირითადი მიზანი იყო აგრო და კვების მრეწველობის პროდუქტების მწარმოებლების ხელშეწყობა სარეალიზაციო
    არხების პოვნასა და გაფართოებაში, როგორც ადგილობრივ, ისე საერთაშორისო ბაზარზე.
  </p>

  <p>
    აგროექსპორტის საერთაშორისო ონლაინ გამოფენის ფარგლებში, პანელური დისკუსიის ერთ-ერთი თემა იყო — როგორ შეგვიძლია
    ლიზინგის გამოყენება აგროსფეროში, რომელზეც ჩვენმა წარმომადგენელმა ისაუბრა.
  </p>

  <p>
    <strong
      >გთავაზობთ ინტერვიუს თიბისი ლიზინგის წარმოებისა და სოფლის მეურნეობის სექტორის უფროსთან და პანელური დისკუსიის
      სპიკერთან, თეკლა რობაქიძესთან, რომელიც ამ თემის შესახებ კიდევ ერთხელ გვიამბობს.</strong
    >
  </p>
  <img src="../../../../assets/blog/bforum/img1.jpg" alt="" />
  <p>
    <strong>თეკლა, რას ვგულისხმობთ, როდესაც ვსაუბრობთ პროდუქტ ლიზინგზე სოფლის მეურნეობის სექტორისთვის?</strong
    ><br /><br />
    ლიზინგის საშუალებით აქტივების, ძირითადი საშუალებების შეძენა მარტივი და მოსახერხებელია ნებისმიერი ბიზნეს
    სექტორისთვის, მათ შორის სოფლის მეურნეობის სექტორისთვისაც.
  </p>
  <p>
    სოფლის მეურნეობის მიმართულებით ლიზინგით შეიძლება დაფინანსდეს ნებისმიერი ინვენტარი და ტექნიკა, რომელიც საჭიროა
    მოსავლის მოვლა-შენახვისთვის, აღებისა და შემდგომ მისი გადამუშავებისთვის. ვგულისხმობ, რომ მომხმარებლის მიერ შერჩეულ
    ნივთს, მაგალითად ტრაქტორს, თიბისი ლიზინგი იყიდის მომწოდებლისგან და სარგებლობაში გადასცემს ლიზინგის მიმღებს
    ყოველთვიური, განსაზღვრული გადასახადის სანაცვლოდ, გრაფიკის დასრულების შემდეგ კი, მიმღები თავის სახელზე გადაიფორმებს
    აღნიშნულ აქტივს, ამ შემთხვევაში ტრაქტორს.
  </p>
  <p>
    სოფლის მეურნეობის მიმართულებით აქტიურად ვაფინანსებთ ტრაქტორებს, კომბაინებს, მოსავლის ამკრეფ მანქანებს,სარწყავ
    სისტემებსა და მანქანა-დანადგარებს ნედლეულის გადამუშავებისთვის.
  </p>
  <img src="../../../../assets/blog/bforum/img2.jpg" alt="" />

  <p>
    <strong
      >რა უპირატესობებს ფლობს ლიზინგი სოფლის მეურნეობაში ჩართული იმ პირებისთვის, რომლებსაც დაფინანსების წყარო
      სჭირდებათ?</strong
    ><br /><br />

    ლიზინგის დამტკიცების პროცესი არის მარტივი და მოქნილი — დაფინანსების განხილვისას ვითვალისწინებთ, როგორც მომხმარებლის
    არსებულ შემოსავლებს, ასევე მის სამომავლო გათვლებს. მნიშვნელოვანი ფაქტორია, რომ ჩვენთვის თავად დასაფინანსებელი აქტივი
    წარმოადგენს ლიზინგის უზრუნველყოფას და არ ვითხოვთ დამატებით უძრავი ქონების წარმოდგენას.
  </p>
  <p>
    ამავდროულად, აქტიურად ვართ ჩართულები სოფლის მეურნეობის სააგენტოს მიმდინარე პროექტებში, რაც ნიშნავს იმას, რომ
    მომხმარებელს ჩვენი გავლით აქვს მათთან კომუნიკაცია და პროექტების სუბსიდირების პროგრამებში ჩართვაც ჩვენი ხელშეწყობით
    შეუძლია.
  </p>
  <p>
    გარდა ამისა, თუ მომხმარებელს სურვილი აქვს, ჩვენ მას ვეხმარებით მომწოდებლებთან ურთიერთობაში, რადგან საქართველოს
    ბაზარზე არსებულ სოფლის მეურნეობის ტექნიკის თითქმის ყველა იმპორტიორთან მწავალწლიანი ურთიერთობა გვაქვს. შესაბამისად,
    შეგვიძლია მომხმარებელი დავაკავშიროთ მათთან და დავეხმაროთ სასურველი აქტივის შერჩევაში.
  </p>
  <p>
    იმ შემთხვევაში, თუ პირს ნივთის შეძენა საზღვარგარეთიდან სურს, ვაფორმებთ კონტრაქტს უცხოელ მომწოდებლებთან. ბუნებრივია,
    კონტრაქტი იცავს მას აქტივის საზღვარგარეთ შეძენასა და ჩამოტანასთან დაკავშირებული რისკებისგან. ამავდროულად, განბაჟების
    პროცესს უზრუნველყოფს ჩვენი მხარე, სალიზინგო კომპანია — რაც დამატებითი კომფორტია ლიზინგის მიმღებისთვის.
  </p>
  <img src="../../../../assets/blog/bforum/img3.jpg" alt="" />
  <p>
    <strong
      >რას გულისხმობს უკულიზინგი და რამდენად ხშირად მიმართავენ მას სოფლის მეურნეობის სექტორში ჩართული
      ადამიანები?</strong
    ><br /><br />

    უკულიზინგი მომხმარებლის საკუთრებაში არსებული აქტივის სანაცვლოდ დაფინანსების გაცემაა. მაგალითად, თუ გაქვს საკუთარი
    კომბაინი, მისი უზრუნველყოფით შეძლებ დაფინანსების მიღებას. Უკულიზინგი ძალიან მოსახერხებელი პროდუქტია მათთვის, ვისაც
    სურს დამატებითი ფულადი სახსრები ბიზნესთან დაკავშირებული ნებისმიერი მოთხოვნის დასაფინანსებლად. Მარტივად რომ ვთქვათ,
    თუ გაქვს აქტივი, მაგრამ არ გაქვს თანხა ნედლეულის შესაძენად, შეგიძლია შენი აქტივი ფულად აქციო.
  </p>
  <p>
    <strong>ბაზრის მიმოხილვა და ლიზინგის ტენდენციის ზრდა</strong><br /><br />

    <mark class="mark-blue" style="background-color: #004083"
      >თიბისი ლიზინგის მიერ დაფინანსებული ფერმერების და გადამამუშავებელი ქარხნების რაოდენობა ყოველწლიურად იზრდება,
      იზრდება დაფინანსებული თანხების მოცულობაც.
    </mark>
    <br />
    <br />
    <mark class="mark-blue" style="background-color: #004083"
      >ბოლო წლებში სახნავ-სათესი და ნარგავებისთვის განკუთვნილი მიწების ათვისების წყალობით, გაჩნდა უფრო მეტი მოთხოვნა
      ტრაქტორების, მისაბმელებისა და კომბაინების დაფინანსებაზე.
    </mark>
    <br />
    <br />
    <mark class="mark-blue" style="background-color: #004083"
      >ასევე, ხშირია მოთხოვნა ვენახის მოვლისთვის საჭირო ტექნიკისა და ყურძნის გადამამუშავებელი ქარხნების აღჭურვილობაზე,
      სამაცივრე მეურნეობებზე, ჩირის საწარმოო და თხილის გადამამუშავებელ დანადგარებზე.
    </mark>
  </p>

  <img src="../../../../assets/blog/bforum/img4.jpg" alt="" />
  <p>
    <strong>რა უპირატესობებს სთავაზობს ლიზინგი სტარტაპებს?</strong><br /><br />

    ხშირ შემთხვევაში, სტარტაპებს დაფინანსების მოპოვება უჭირთ, რადგან საწყის ეტაპზე არ გააჩნიათ შემოსავლები - ჩვენთვის ეს
    პროცესი უფრო მარტივია, რადგან მომხმარებლის დაფინანსება სამომავლო შემოსავლებზე დაყრდნობით შეგვიძლია.
  </p>
  <p>
    ასევე , მომხმარებლებს ვთავაზობთ მათზე მორგებულ გრაფიკს, რაც გულისმხობს საშეღავათო პერიოდით სარგებლობას შემოსავლების
    დაგენერირებამდე, ან სეზონურ გადახდას ბიზნესის სპეციფიკიდან გამომდინარე.
  </p>
  <img src="../../../../assets/blog/bforum/img5.jpg" alt="" />
  <p>
    <strong
      >რა არის ის სამომავლო ამოცანები, რომელიც გააძლიერებს სექტორს და ასევე, ამ სფეროში ლიზინგის პოპულარიზაციას შეუწყობს
      ხელს?</strong
    ><br /><br />

    მსოფლიოში მიმდინარე პროცესებიდან გამომდინარე, წინა წლებთან შედარებით, სოფლის მეურნეობის სექტორი გაცილებით პოპულარული
    გახდა. მომრავლდა ამ მიმართულების წამახალისებელი პროგრამები და შესაბამისად, ამ სფეროთი სულ უფრო და უფრო მეტი ადამიანი
    ინტერესდება. თიბისი ლიზინგი თავის მხრივ აქტიურად მონაწილეობს სხვადასხვა ფორუმებში, თანამშრომლობს ტექნიკისა და
    დანადგარების იმპორტიორებსა თუ მწარმოებლებთან და მზადაა დააფინანსოს ნებისმიერი საინტერესო პროექტი, რომელიც დაეხმარება
    და განავითარებს ჩვენი ქვეყნის ეკონომიკას.
  </p>
  <p></p>

  <br />
  <div class="blog-author"><span>ავტორი: ნინჩო ბალანჩივაძე</span></div>
</div>
