import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from '@app/shared/api.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-verify-customer2',
  templateUrl: './verify-customer2.component.html',
  styleUrls: ['./verify-customer2.component.scss']
})
export class VerifyCustomer2Component implements OnInit {
  fg: UntypedFormGroup;
  fg2: UntypedFormGroup;
  submitted = false;
  formType: any;
  isIndividual = true;

  constructor(private fb: UntypedFormBuilder, private api: ApiService) {}

  ngOnInit() {
    this.formType = this.fb.group({
      person: null,
      company: null
    });

    this.fg = this.fb.group({
      sn: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9), Validators.maxLength(11)]]
    });

    this.fg2 = this.fb.group({
      sn: [
        '',
        [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9), Validators.maxLength(11)]
      ],
      responsibleFirstName: ['', [Validators.required]],
      responsibleLastName: ['', [Validators.required]],
      responsibleSn: [
        '',
        [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9), Validators.maxLength(11)]
      ]
    });
  }

  get f() {
    return this.fg.controls;
  }

  get f2() {
    return this.fg2.controls;
  }

  verify() {
    if (this.isIndividual) {
      this.api
        .verifyCustomerV2(this.f.sn.value, this.isIndividual, '', '', '')
        .subscribe(this.onSuccess(), this.onError());
    } else {
      this.api
        .verifyCustomerV2(
          this.f2.sn.value,
          this.isIndividual,
          this.f2.responsibleFirstName.value,
          this.f2.responsibleLastName.value,
          this.f2.responsibleSn.value
        )
        .subscribe(this.onSuccess(), this.onError());
    }
  }

  private onError() {
    return (error: any) => {
      this.showError();
    };
  }

  private onSuccess() {
    return (value: HttpResponse<any>) => {
      if (value.status === 200) {
        this.submitted = true;
      } else {
        this.showError();
      }
    };
  }

  private showError() {
    alert('წარმოიშვა გაუთვალისწინებელი შეცდომა.');
  }
}
