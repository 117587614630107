<div class="container-fluid m-0 p-0">
  <div class="blog-header-container">
    <h1>ბლოგი</h1>
  </div>
</div>
<div class="container blog-item custom">
  <h1>
    ლექსუს იზი - ლექსუსის მართვის ახალი გზა
  </h1>
  <h6>ივლისი 2023</h6>
  <br />

  <p>
    ავტომოყვარულებისთვის ცნობილი შეხედულებაა, რომ “მანქანა, რომელსაც მართავ, ბევრს ჰყვება შენზე“. ლექსუსმა ამ იდეის
    სრულყოფა იაპონური სტანდარტით - ლოგოტიპიდან დაიწყო და “გლუვი მოსახვევი“ გამოსახა, რაც მისი ავტომობილების
    აეროდინამიკის პრემიუმ ხარისხს უსვამს ხაზს.
  </p>

  <p>
    წელს თიბისი ლიზინგმა ლექსუსთან ერთად ახალი ფინანსური პროდუქტი - Lexus Easy და Lexus Easy Flex შექმნა, საოპერაციო
    ლიზინგისთვის დამახასიათებელი მოქნილი, მარტივი პირობებით. თუ ახალი ავტომობილის შერჩევასა და სწრაფი დაფინანსების
    მოძიებას ფიქრობ, ეს სტატია შენთვისაა.
  </p>

  <img src="../../../../assets/blog/lexus-easy/img1.jpg" alt="" />

  <p>
    <i class="subhead capital">
      <b>ძირითადი უპირატესობები: </b>
    </i>
  </p>

  <p>
    <i class="subhead capital light-blue">
      <b>სპეციალური ფასი </b>
    </i>
    <br />
    შეარჩიე სასურველი მოდელი ლექსუს საქართველოში და შეიძინე ექსკლუზიურ ფასად
  </p>

  <p>
    <i class="subhead capital light-blue">
      <b>0%-იანი თანამონაწილეობა </b>
    </i>
    <br />
    არ არის საჭირო გქონდეს ე.წ პირველად შესატანი თანხა
  </p>

  <p>
    <i class="subhead capital light-blue">
      <b>დამტკიცების დრო </b>
    </i>
    <br />
    მოითხოვე ლიზინგი, მიიღე დაფინანსება 1 საათში და გაიყვანე ავტომობილი იმავე დღეს
  </p>

  <p>
    <i class="subhead capital light-blue">
      <b>გეგმიური სერვისები </b>
    </i>
    <br />
    სურვილისამებრ ხდება საგარანტიო პერიოდის მანძილზე ჩასატარებელი გეგმიური სერვისების კონტრაქტით გათვალისწინება
  </p>

  <p>
    <i class="subhead capital light-blue">
      <b>TRADE-IN ფუნქცია </b>
    </i>
    <br />
    ჩააბარე ნებისმიერი მოდელი, მის ღირებულებას თანამონაწილეობის თანხად გავითვალისწინებთ და ახალი ლექსუსის გაყვანისას
    გამოგაკლდება.
  </p>

  <p>
    <i class="subhead capital light-blue">
      <b>"LEXUS CASCO"</b>
    </i>
    <br />
    ლექსუსის სპეციალური სადაზღვევო პროდუქტი, რომელიც თქვენს საჭიროებებზეა მორგებული: ინდივიდუალურად გამოთვლილი პრემია,
    უფლებამოსილი მძღოლების შეუზღუდავი რაოდენობა, ავტო-ასისტანსის 24-საათიან უფასო მომსახურებას და სხვა.
  </p>

  <img src="../../../../assets/blog/lexus-easy/img2.jpg" alt="" />

  <p>
    <i class="subhead capital">
      <b>გაეცანი Lexus Easy და Easy Flex-ის პირობებს: </b>
    </i>
  </p>

  <table class="flat-table">
    <thead>
      <tr>
        <th class="naming">ძირითადი უპირატესობები:</th>
        <th>LEXUS EASY</th>
        <th>LEXUS EASY FLEX</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="naming">სპეციალური ფასი ლექსუსის ავტომობილებზე</td>
        <td><b>✓</b></td>
        <td><b>✓</b></td>
      </tr>
      <tr>
        <td class="naming">წინასწარი შენატანი - 0%-დან</td>
        <td><b>✓</b></td>
        <td><b>–</b></td>
      </tr>
      <tr>
        <td class="naming">დაბალი ყოველთვიური გადასახადი</td>
        <td><b>✓</b></td>
        <td><b>✓</b></td>
      </tr>
      <tr>
        <td class="naming">დაბალი საპროცენტო განაკვეთი</td>
        <td><b>✓</b></td>
        <td><b>✓</b></td>
      </tr>
      <tr>
        <td class="naming">გარანტირებული ნარჩენი ღირებულება</td>
        <td><b>✓</b></td>
        <td><b>–</b></td>
      </tr>

      <tr>
        <td class="naming">საგარანტიო პირობებით დაცული ავტომობილი</td>
        <td><b>✓</b></td>
        <td><b>✓</b></td>
      </tr>
      <tr>
        <td class="naming">დამტკიცების დრო</td>
        <td>- 1 საათი</td>
        <td>- 1 საათი</td>
      </tr>
      <tr>
        <td class="naming">კონტრაქტის ხანგრძლივობა</td>
        <td>12-60 თვე</td>
        <td>12-72 თვე</td>
      </tr>
      <tr>
        <td class="naming">კონტრაქტში გათვალისწინებული გეგმიური სერვისები</td>
        <td>არჩევითი</td>
        <td>არჩევითი</td>
      </tr>
      <tr>
        <td class="naming">დამატებითი ფასდაკლება სერვისებზე</td>
        <td><b>✓</b></td>
        <td><b>✓</b></td>
      </tr>
      <tr>
        <td class="naming">ლექსუსის სადაზღვევო პროდუქტი - "Lexus Casco"</td>
        <td><b>✓</b></td>
        <td><b>✓</b></td>
      </tr>
      <tr>
        <td class="naming">დაიტოვოს ავტომობილი - იხდით მხოლოდ, ავტომობილის წინასწარ განსაზღვრულ, ნარჩენ ღირებულებას</td>
        <td><b>✓</b></td>
        <td><b>–</b></td>
      </tr>
      <tr>
        <td class="naming">დააბრუნოს ავტომობილი - ამისთვის საჭიროა უბრალოდ ავტომობილის დატოვება სერვის ცენტრში</td>
        <td><b>✓</b></td>
        <td><b>–</b></td>
      </tr>
      <tr>
        <td class="naming">განაახლოს ავტომობილი - ჩააბაროს ძველი და გაიყვანოს განახლებული მოდელი</td>
        <td>✓</td>
        <td><b>–</b></td>
      </tr>
    </tbody>
  </table>

  <img src="../../../../assets/blog/lexus-easy/img3.jpg" alt="" />
</div>

<div class="container-fluid m-0 p-0">
  <div class="full-color background-blue">
    <div class="container">
      <p class="bold-text">
        <span class="capitalized-text"
          >რაც მთავარია, პროდუქტი განსაკუთრებულ მოქნილობას განიჭებს. კონტრაქტის ვადის გასვლის შემდეგ შეგიძლია:</span
        >
        <br /><br />

        1. შეინარჩუნო ავტომობილი და განაგრძო მისი მართვა, რისთვისაც მხოლოდ წინასწარ განსაზღვრულ ნარჩენ ღირებულებას
        გადაიხდი. <br /><br />

        2. დააბრუნო ავტომობილი სერვის ცენტრში. <br /><br />

        3. განაახლო ავტომობილი, რისთვისაც ძველის დატოვება მოგიწევს სერვის-ცენტრში.
      </p>
    </div>
  </div>
</div>

<div class="container blog-item custom">
  <p>
    <i class="subhead capital">
      <b style="font-size:26px">ისარგებლე Lexus Easy-ით ან Easy Flex-ით და მართე ლექსუსი - მარტივად. </b>
    </i>
  </p>
</div>
