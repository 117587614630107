<div class="container-fluid m-0 p-0">
  <div class="blog-header-container">
    <h1>ბლოგი</h1>
  </div>
</div>
<div class="container blog-item custom">
  <h1>აქ. ახლა პირველად ქართულ ბაზარზე კომერციული უძრავი ქონების ლიზინგი</h1>
  <h6>დეკემბერი 2021</h6>
  <br />
  <p>
    კომუნიკაციის თითოეულ ეტაპზე აღვნიშნავთ, რომ ლიზინგი თავის არსით დაფინანსების ის გზაა, რომელიც ბიზნესსაქმიანობას
    ამარტივებს, აქტივის ფლობასთან დაკავშირებულ საკითხებს აგვარებს და გეხმარება მიაღწიო მთავარს - დამოუკიდებლობას, უფრო
    სწორად - ბიზნეს დამოუკიდებლობას. ზემოთ თქმულის სადემონსტრაციოდ ქართულ ბაზარზე ინოვაციური შეთავაზება დავნერგეთ და
    მიუხედავად იმისა, რომ მსოფლიოში თითქმის ვერცერთ ქვეყანას სალიზინგო პროდუქტით ვეღარ გააკვირვებ, გადავწყვიტეთ
    შეგვექმნა ისეთი უნიკალური პროდუქტი, რომლის ანალოგიც ფაქტობრივად არ არსებობს.
  </p>

  <p>
    კომერციული უძრავი ქონების ლიზინგის უპირატესობებით სარგებლობა შეუძლიათ როგორც ფიზიკურ, ასევე იურიდიულ პირებს. ორივე
    შემთხვევაში მთავარი მიზანი ბიზნეს რესურსის სწორად გადანაწილებაა.
  </p>

  <img src="../../../../assets/blog/estate-leasing/img1.jpg" alt="" />

  <p>
    <i class="subhead capital light-blue"><b>ფიზიკური და იურიდიული პირებისთვის</b></i>
  </p>

  <p>
    თუ საკუთარი ბიზნესის განვითარების არცერთ ეტაპზე არ გსურს რესურსი ჰაერში, სრულიად ფუჭად გაფანტო და იჯარა იხადო, ეს
    შეთავაზება - შენთვისაა. ლიზინგის შემთხვევაში ყოველთვიური გადასახადი საიჯარო ხელშეკრულებით განსაზღვრული შენატანის
    კონკურენტუნარიანი ოდენობისაა, თუმცა ჩვენ გაძლევთ ქონების ფლობის გარანტიას. სალიზინგო ხელშეკრულების დასრულების შემდეგ
    კომერციული ფართი შენს საკუთრებაში გადმოდის და ბიზნესის განვითარება ახლა უკვე საკუთარ კედლებში შეგიძლია განაგრძო.
  </p>

  <p>
    <i class="subhead capital blue"><b>დეველოპერებისთვის</b></i>
  </p>

  <p>
    დეველოპერული კომპანიების წარმომადგენლებს ეძლევათ უნიკალური შესაძლებლობა კომპანიის საკუთრებაში არსებული აქტივით
    მიიღონ დაფინანსება მის რეალიზაციამდე. ასევე, შესაძლებელია გაქირავებული ფართების რეალიზაცია ლიზინგის დახმარებით
    საიჯარო თანხაზე მორგებული შენატანით.
  </p>

  <img src="../../../../assets/blog/estate-leasing/img2.jpg" alt="" />

  <p>
    <i class="subhead capital dark-blue"><b>ჩვენ ვაფინანსებთ:</b></i>
  </p>

  <p class="list-item">კომერციული, საწარმოო და საოფისე ფართის შესყიდვას;</p>
  <p class="list-item">დასრულებულ მშენებლობებში არასაცხოვრებელ და დამხმარე ფართებს, ასევე ავტოსადგომებს;</p>
  <p class="list-item">
    როგორც ფიზიკური, ისე იურიდიული პირის საკუთრებაში არსებული კომერციული ფართების უკულიზინგს, ანუ შესაძლებელია
    საკუთრებაში არსებული უძრავი ქონების საშუალებით ფულადი რესურსის მოპოვება;
  </p>

  <p>
    <i class="subhead capital dark-blue"><b>რატომ?</b></i>
  </p>

  <p class="list-item">ფართის რეალიზაციაზე მორგებული გადახდის გრაფიკი</p>
  <p class="list-item">მოქნილი და სწრაფი მომსახურება</p>
  <p class="list-item">ლიზინგის ვადა განსაზღვრულია 120 თვემდე</p>
  <p class="list-item">ჩვენთან გაცემის საკომიციო 0%-ია</p>
  <p class="list-item">ფინანსდება შეფასების მაქსიმუმ 80%დაფარვის გრაფიკი შენზე მორგებული,</p>
  <p class="list-item">ანუიტეტი ან არასტანდარტული.</p>

  <img src="../../../../assets/blog/estate-leasing/img3.jpg" alt="" />

  <p>
    თუ სესხს ანუიტეტით იხდი და ყოველთვიური შენატანის შემცირება გსურს, ლიზინგით რეფინანსირება საუკეთესო არჩევანია. ჩვენ
    ძირის ნაწილის, ლიზინგის ვადის ბოლოს გადახდას გთავაზობთ.
  </p>
  <p>
    აქვე, რამდენიმე პრაქტიკული მაგალითით მოგიყვებით, როგორ შეგიძლია ისარგებლო ახალი პროდუქტით.
  </p>

  <p>
    <i class="subhead capital purple"><b>1. იჯარის ჩანაცვლება:</b></i>
  </p>

  <p>
    უძრავი ქონების ღირებულებაა 100,000 აშშ დოლარი, ყოველთვიური იჯარა 1,000 აშშ დოლარი.
  </p>

  <p>
    ამ ფართის ლიზინგით დაფინანსების შემთხვევაში: თანამონაწილეობა 20%, დაფინანსება 80%, ნარჩენი ღირებულება (RV) 75%, ვადა
    120 თვე. ყოველთვიური შენატანი ლიზინგში: 846,33 აშშ დოლარი (მათ შორის დღგ 129 აშშ დოლარი)
  </p>

  <p>
    <i class="subhead capital green"><b>2. სესხის შენატანის შემცირება:</b></i>
  </p>

  <p>
    უძრავი ქონების ღირებულებაა 100,000 აშშ დოლარი, შეძენა დაფინანსებულია ბანკში და სესხის ყოველთვიური გადასახდელია 1,160
    აშშ დოლარო, ვადა 120 თვე.
  </p>

  <p>
    სესხის ლიზინგით რეფინანსირების შემთხვევაში:
  </p>

  <p>
    თანამონაწილეობა 20%, დაფინანსება 80%, ნარჩენი ღირებულება (RV) 75%, ვადა 120 თვე.
  </p>

  <p>
    ყოველთვიური შენატანი ლიზინგში: 846,33 აშშ დოლარი (მათ შორის დღგ 129 აშშ დოლარი)
  </p>

  <img src="../../../../assets/blog/estate-leasing/img4.jpg" alt="" />

  <p>
    <i class="subhead capital light-blue"><b>3. საკუთრებაში არსებული ფართით დაფინანსების მიღება (არადეველოპერი)</b></i>
  </p>

  <p>
    თუ იურიდიული ან ფიზიკური პირი ფლობს უძრავ ქონებას, რომლის ღირებულება შეადგენს 100,000 აშშ დოლარს. ნებისმიერი
    საჭიროებისთვის მას შეუძლია ლიზინგის საშუალებით მიიღოს დაფინანსება 80,000 აშშ დოლარის ოდენობით, მაქსიმუმ 120 თვის
    ვადით.
  </p>

  <p>
    <i class="subhead capital dark-blue"><b>4. საკუთრებაში არსებული ფართით დაფინანსების მიღება (დეველოპერი)</b></i>
  </p>

  <p>
    თუ დეველოპერიულ კომპანიას აქვს სარეალიზაციო არასაცხოვრებელი ფართი, მას შეუძლია ფართის რეალიზაციამდე მიიღოს
    დაფინანსება. თუ უძრავი ქონების ღირებულება შეადგენს 100,000 აშშ დოლარს, დეველოპერს აღნიშნულ ფართში შეუძლია ლიზინგის
    საშლებით, მიიღის დაფინანსება 75,000 აშშ დოლარის ოდენობით. დაფარვის გარფიკი კი იქნება მორგებული ამავე ფართის
    რეალიზაციაზე (ძირი თანხის ვადის ბოლოს გადახდის პირობით), ვადა არაუმეტეს 24 თვე.
  </p>

  <p>
    დეტალური ინფორმაციის მისაღებად დაგვიკავშირდი და გაიმარტივე ბიზნესსაქმიანობა, რადგან უძრავი ქონების შეძენა ასეთი
    ხელმისაწვდომი არასოდეს ყოფილა.
  </p>

  <p style="color: #999999">ავტორი: ლალიკო თუმანიშვილი</p>
</div>
