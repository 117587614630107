import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-toyota-easy',
  templateUrl: './blog-toyota-easy.component.html',
  styleUrls: ['../../blog.component.scss']
})
export class BlogToyotaEasyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
