<div class="container-fluid m-0 p-0">
  <div class="blog-header-container">
    <h1>ბლოგი</h1>
  </div>
</div>
<div class="container blog-item ">

  <h1>რა არის ლიზინგი?</h1>
  <h6>ივლისი 2018</h6>
  <br>
  <h1 style="text-align: center">„სიმდიდრე სარგებლობაშია და არა საკუთრებაში“
     - არისტოტელე.</h1>
  <img src="../../../../assets/blog/leasing/img1.jpg" alt="">

  <p>

    ანტიკური დრო, რომელიც ხშირად შორეულ წარსულად გვეჩვენება, ხანდახან ძალიან ახლობელი აღმოჩნდება ხოლმე. როცა ვუყურებთ
    ეგვიპტის უნიკალურ არქიტექტურულ ნაგებობებს, ვკითხულობთ ბერძნული სამოქალაქო წესრიგის შესახებ, ვხედავთ რომაული
    ქალაქების დაგეგმარებას, ვხდებით, რომ საზოგადოებრივი ცხოვრების ხერხემალი შეიძლება დიდად არც შეცვლილა და თითქმის იმავე
    „წესებს“ ეფუძნება. ადამიანები მაშინაც ფიქრობდნენ კარგ ცხოვრებაზე, ზრუნავდნენ საკუთარ თავზე, ყიდულობდნენ და ყიდდნენ.
    შესაბამისად, არც ის უნდა იყოს გასაკვირი, რომ ძველი წელთაღრიცხვით მეთვრამეტე საუკუნეში, ჯერ კიდევ ბაბილონის სამეფოში
    არსებობდა ლიზინგი.
  </p>
  <p>
    ხამურაბის დროინდელ ბაბილონში ლიზინგი ასეთი ფორმით მუშაობდა: თუ ჯარისკაცი გადაწყვეტდა, რომ არ სურდა მიწის დამუშავება,
    რომელიც სამეფოსგან ჯარში სამსახურის სანაცვლოდ მიიღო, მაშინ ის მიწას აბარებდა ეგრედწოდებულ „სალიზინგო კომპანიას“,
    რომელიც, თავის მხრივ, გარკვეული სისტემატიური შემოსავლის სანაცვლოდ დროებით გასცემდა მიწას.
  </p>
  <p>
    მაინც რა არის ლიზინგი? ვისთვისაც ამ სიტყვის შინაარსი უცნობია, ბაბილონური გამოცდილებიდან ადვილად გამოიტანს დასკვნას,
    რომ ლიზინგი მეტ ნაკლებად ჰგავს ქირას. უბრალოდ ამ შემთხვევაში გამქირავებლისა და დამქირავებლის ნაცვლად აქ სხვა
    პერსონაჟები - კომპანია და მისი მომხმარებლები არიან, რაც ოპერაციული თვალსაზრისით სრულიად ცვლის სურათს და ურთიერთობაში
    ახალი პრინციპები შემოაქვს. დამქირავებელს აღარ უწევს ცალკეულ გამქირავებლებლის ძებნა და შემდეგ მათთან
    შეხვედრა-მოლაპარაკებები. მას შეუძლია პირდაპირ მიმართოს სალიზინგო კომპანიას, რომელიც უკვე ფლობს მისთვის სასურველ
    უძრავ ქონებას, მოწყობილობასა თუ ტრანსპორტს, და გარკვეული ვადით გამოიყენოს პროდუქტი.


  </p>
  <img src="../../../../assets/blog/leasing/img2.jpg" alt="">
  <p>ქირისგან განსხვავებით:
    სალიზინგო პერიოდის ხანგრძლივობა უფრო დიდია
    პირობების შესახებ შეთანხმება, რომელიც ლიზინგის დასაწყისში დაიდება, დოკუმენტირებულია და სალიზინგო პერიოდის არცერთ
    ეტაპზე არ იცვლება
    სალიზინგო პერიოდის ბოლოს, მომხმარებელს შეუძლია შეისყიდოს და საკუთრებაში დაიტოვოს ქონება.
    მართალია ლიზინგი ხამურაბის დროსაც არსებობდა, მაგრამ ბოლო პერიოდის განმავლობაში მისი როლი ეკონომიკაში ერთიორად
    გაიზარდა და ლიზინგმა განვითარებული ქვეყნების ეკონომიკურ ზრდაში მნიშვნელოვანი როლი დაიკავა. რა შეიძლება იყოს ამის
    მიზეზი? თუ სტატისტიკას გადავხედავთ, ვნახავთ რომ მსოფლიოში განსაკუთრებული პოპულარობით სარგებლობს 1. სხვადასხვა
    მოწყობილობებისა და აღჭურვილობების ლიზინგი 2. ავტო ლიზინგი.
  </p>
  <img src="../../../../assets/blog/leasing/img3.jpg" alt="">
  <p>
    წარმოიდგინე, რომ შენ ხარ კლინიკის მფლობელი, რომელსაც მაღალი სტანდარტის გამოკვლევები აქვს. რადიოლოგიაში კი
    განსაკუთრებით მნიშვნელოვანია, გქონდეს სამედიცინო მოწყობილობები, რომლებიც ყველაზე ზუსტ სურათს აჩვენებს. მეორე მხრივ,
    ასეთი ტექნიკა საკმაოდ ძვირი ღირს და თანაც მწარმოებელი კომპანიების მხრიდან ხშირად განახლებადია. შენ კი ნამდვილად იცი,
    რომ სტანდარტის დასაკმაყოფილებლად აუცილებლად ახალი ტექნიკა გჭირდება. როგორ შეიძლება მოიქცე? ასეთ დროს კომპანიების
    უმეტესობა ლიზინგს მიმართავს, რაც ალბათ ყველაზე ლოგიკური და ხელსაყრელი გამოსავალია - იყენებ მოწყობილობას მისი ყიდვის
    გარეშე.</p>
  <p>
    მსგავსი შემთხვევების გარდა, არსებობს მთელი რიგი კომპანიებისა, რომლებიც კონკრეტულ ტექნიკას მხოლოდ გარკვეული პერიოდის
    განმავლობაში იყენებენ და სრულიადაც არ სჭირდებათ ამ ტექნიკის ფლობა. მაგალითად, ეს შეიძლება იყოს სამშენებლო კომპანია,
    რომელსაც კონკრეტული მექანიზმი მშენებლობის მხოლოდ გარკვეულ ეტაპზე სჭირდება და არა მუდმივად. გამოსავალი აქაც ხშირად
    ლიზინგია.</p>
  <img src="../../../../assets/blog/leasing/img4.jpg" alt="">
  <p>ბოლო დროს განსაკუთრებულად ტენდენციური გახდა ავტო ლიზინგი. მის პოპულარობას კი, როგორც წესი, ერთი მარტივი ჰიპოთეზით
    ხსნიან. ავტომანქანა არის პროდუქტი, რომელსაც მცირე დროის (რამდენიმე წლის) განმავლობაში მკვეთრად უმცირდება ღირებულება.
    მარტივად რომ ავხსნათ, შეუძლებელია, მოხმარების შემდეგ მანქანა იგივე ფასად გაყიდო, რა ფასადაც იყიდე. მეტიც, ამ ორ ფასს
    შორის სხვაობა ხშირად არც თუ ისე პატარაა. თანაც, ერთი ადამიანის მიერ მანქანის გამოცვლის მაჩვენებელი საკმაოდ მაღალია.
    შესაბამისად, ბუნებრივია, საზოგადოების ნაწილი ყიდვას ლიზინგს ამჯობინებს. ავტომობილის ლიზინგი კი კარგი მაგალითია
    იმისთვის, რომ ლიზინგის კიდევ ერთი მახასიათებელი ავხსნათ - დაზღვევა. თუ მანქანის ყიდვის შემდეგ, მის დაზღვევაზე შენ
    უნდა იზრუნო, ლიზინგის დროს ის უკვე დაზღვეულია კომპანიის მხრიდან და დაზიანების შემთხვევაში შენ აღარ მოგიწევს ფულის
    დახარჯვა მის შესაკეთებლად. ეს ხშირ შემთხვევაში გადამწყვეტი ფაქტორია, როცა არჩევანი ლიზინგზე დგება.
  </p>
  <img src="../../../../assets/blog/leasing/img5.jpg" alt="">
  <p>
    საბოლოო ჯამში, ადამიანების უფრო და უფრო დიდი ნაწილი არისტოტელეს ზემოხსენებულ იდეას საკუთარ ქცევაში თარგმნის და
    ფიქრობს, რომ „სიმდიდრე სარგებლობაშია და არა საკუთრებაში“.
    <br>
    <br>
    <strong>ავტორი: მარიამ კოპალეიშვილი</strong>
  </p>


</div>
