<div class="verify-handle-wrapper">
  <div *ngIf="status === 'pending'; else statusTemplate">
    <app-loader [isLoading]="true"></app-loader>
  </div>
  <ng-template #statusTemplate>
    <div *ngIf="status === 'success'" class="verify-container">
      <p class="verify-text" translate>ვერიფიკაცია წარმატებით განხორციელდა</p>
      <div class="verify-icon">
        <i class="fas fa-check-circle"></i>
      </div>
    </div>
    <div *ngIf="status === 'error'" class="verify-container">
      <p class="verify-text" translate>ვერიფიკაციის პროცესში დაფიქსირდა შეცდომა</p>
      <div class="verify-icon  error">
        <i class="fas fa-times-circle"></i>
      </div>
    </div>
  </ng-template>
</div>
