import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { extract } from '@app/core';
import { OffersPageComponent } from './offers-page/offers-page.component';
import { VerifyCustomerComponent } from '@app/offers/verify-customer/verify-customer.component';
import { VerifyCustomer2Component } from '@app/offers/verify-customer2/verify-customer2.component';
import { SsoVerifyHandleComponent } from '@app/offers/sso-verify-handle/sso-verify-handle.component';
import { SsoVerifyComponent } from '@app/offers/sso-verify/sso-verify.component';

const routes: Routes = [
  { path: 'offers', component: OffersPageComponent, data: { title: extract('Offers') } },
  { path: 'verify', component: VerifyCustomerComponent, data: { title: extract('Verify') } },
  { path: 'verify2', component: VerifyCustomer2Component, data: { title: extract('Verify') } },
  { path: 'verify3', component: SsoVerifyComponent, data: { title: extract('Start Verification') } },
  { path: 'verify/callback', component: SsoVerifyHandleComponent, data: { title: extract('Handle') } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class OffersRoutingModule {}
