<div class="container-fluid m-0 p-0">
  <div class="blog-header-container">
    <h1>ბლოგი</h1>
  </div>
</div>

<div class="container blog-item custom">
  <h1>Coffee Lab ანუ ყავა ხილია?</h1>
  <h6>დეკემბერი 2023</h6>
  <br />

  <img src="../../../../assets/blog/coffee-lab/img1.jpg" alt="" />

  <p>
    დილის პირველი ჭიქა გამოფხიზლებისთვის, მეორე ჭიქა - ენერგიისთვის, სამხარზე, ან სამხრის შემდეგ, ყავა როგორც მეგობართან
    გემრიელი საუბრის განუყოფელი ნაწილი, ან სასმელი, რომლის თანხლებითაც მნიშვნელოვანი გადაწყვეტილების მიღებისთვის
    ვხვდებით, ყავა, როგორც ურთიერთობის, დაკავშირების, ახალი ენერგიების ელექსირი. ამ მატონიზირებელ სასმელზე უსასრულოდ
    შემიძლია წერა, მაგრამ ჩემზე უფრო ფილოსოფიურად ამას Coffee Lab-ში აკეთებენ:
  </p>

  <p class="quote-sign light-blue">“</p>

  <p>
    <b style="font-size: 26px; font-family: 'TBCX-BOLD'">
      ყავა ხილია, რომელიც იზრდება ხეზე და აქვს ტკბილი, წვნიანი, წითელი ნაყოფები, რომელთაც შეიძლება ჰქონდეს კენკრის,
      ატმის, ციტრუსის ან ნებისმიერი სხვა ხილის გამოკვეთილი საგემოვნო თვისებები.
    </b>
  </p>
</div>

<div class="container-fluid m-0 p-0">
  <div class="full-color background-light-blue p-0">
    <div class="container p-0">
      <img class="p-0" src="../../../../assets/blog/coffee-lab/img2.jpg" alt="" />
    </div>
  </div>
</div>

<div class="container blog-item custom">
  <p>
    როგორ შეიძლებოდა ხალხს, რომელსაც ასე სწამდა პროდუქტის, რაიმე ჩვეულებრივი, არაფრით გამორჩეული შეექმნა? სწორედ ამიტომ,
    თბილისში, უფრო სწორად, საქართველოში ერთ-ერთი პირველი Specialty ანუ “განსაკუთრებული“ ყავის სახელოსნო-კაფე დააარსეს.
    მათი ფილოსოფია მარტივია - უბრალოდ ყველასგან გამორჩეული გემო უნდა შესთავაზონ მომხმარებელს, რისთვისაც მსოფლიოს
    სხვადასხვა ქვეყნიდან ჩამოაქვთ განსაკუთრებული გემოვნური თვისებების მქონე მწვანე, მოუხალავი მარცვლები და თანამედროვე
    ტექნოლოგიების გამოყენებით ამუშავებენ. საბოლოო პროდუქტი მომჟავო-მოტკბო, ანუ ხილოვანი არომატისაა.
  </p>

  <p>
    ტრადიციული ყავისგან განსხვავებული, გამორჩეული გემოს მიღება, მომზადებისა და დაგემოვნების ალტერნატიული კულტურის
    დანერგვა – სწორედ ამ მიზნების მისაღწევად შემოიკრიბა 2016 წელს თანამოაზრეების გუნდი გიორგი აივაზიანმა და შექმნა
    „ყავის ლაბორატორია“ – პირველი განსაკუთრებული თვისებების მქონე ყავის კომპანია საქართველოში.
  </p>

  <p class="quote-sign light-blue">“</p>

  <p>
    <b style="font-size: 26px; font-family: 'TBCX-BOLD'">
      ყავის მომზადება ჩვენთვის დიდი თავგადასავალია. „ყავის ლაბორატორიაში“ სპეციალური ტექნოლოგიის გამოყენებით იხალება
      იმპორტირებული მწვანე ყავის მარცვალი და მზადდება ინდივიდუალური გემოთი და არომატით გამორჩეული ყავა, რომელიც ჩვენ ასე
      ძალიან გვიყვარს.
    </b>
  </p>
</div>

<div class="container-fluid m-0 p-0">
  <div class="full-color background-light-blue p-0">
    <div class="container p-0">
      <img class="p-0" src="../../../../assets/blog/coffee-lab/img3.jpg" alt="" />
    </div>
  </div>
</div>

<div class="container blog-item custom">
  <p>
    საქმიანობის განსავითარებლად, სარესტორნო ბიზნესში აპრობირებული მეთოდი, ლიზინგი გამოიყენეს. ტექნიკა-დანადგარების
    შესაძენად, ასევე გარკვეული ცვეთის შემდეგ ჩასანაცვლებლად მარტივი გზა, სწორედ ლიზინგია.
  </p>

  <p>
    დღეს Coffee Lab არა მხოლოდ რამდენიმე ფილიალს ფლობს თბილისში, არამედ საუკეთესო გემოვნური თვისებების ყავით ამარაგებს
    სხვა წამყვან კაფე-რესტორნებს.
  </p>

  <p class="quote-sign light-blue">“</p>

  <p>
    <b style="font-size: 26px; font-family: 'TBCX-BOLD'">
      ჩვენი სურვილია, ყავის დაგემოვნება ვაქციოთ დაუვიწყარ გამოცდილებად ჩვენს ბარში, პარტნიორ რესტორნებში, კაფეებსა თუ
      თქვენს სახლშიც კი.
    </b>
  </p>
</div>
